import React from 'react';
import { Table, Icon } from 'semantic-ui-react';
import 'semantic-ui-css/components/table.min.css';
import { Link, Redirect } from 'react-router-dom';
import MyList from './list';

interface ListProps {

}

interface ListState {
    data: any[],
    errorMsg: string
}

class ProviderUserList extends MyList {
    entity = "provider_user"

    refresh() {
        var token = localStorage.getItem('adminToken')
        if (token && token !== 'undefined') {
            fetch(process.env.REACT_APP_CHEF_API + "mongol/provider-users", {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': token,
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                if (res.status === 401) {
                    this.setState({ redirect: this.loginUrl})
                } else {
                    res.json().then(res => {
                        console.log(res);
                        if (!res || !res.data) {
                            return;
                        }
                        let data: any[] = res.data;
                        data.forEach(b => b.confirmingDelete = false);
                        this.setState({ data: data });
                    })
                }
            })
            .catch(err => console.error(err));
        }
        else {
            this.setState({ redirect: this.loginUrl });
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }
        return (
            <section className="full-width">
                <h2>Нийлүүлэгч байгууллага хариуцсан ажилтан</h2>

                {this.state.errorMsg ? <div className="error-msg">
                    <Icon name="warning circle"/>&nbsp;
                    {this.state.errorMsg}
                </div> : null}

                <Table striped width={16}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>№</Table.HeaderCell>
                            <Table.HeaderCell>Байгууллага</Table.HeaderCell>
                            <Table.HeaderCell>Нэр</Table.HeaderCell>
                            <Table.HeaderCell>Имэйл</Table.HeaderCell>
                            <Table.HeaderCell>Утас</Table.HeaderCell>
                            <Table.HeaderCell>Идэвхитэй</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        { this.state?.data?.map((row: any, index: number) => {
                                return <Table.Row key={row.id}>
                                    <Table.Cell>{ String(index+1) }</Table.Cell>
                                    <Table.Cell>
                                        <strong>
                                            <Link className="underline hover-blue" to={"/mongol/provider/" + row.providerId}>{ row.providerName }</Link>
                                        </strong>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <strong>
                                            <Link className="underline hover-blue" to={"/mongol/provider-user/" + row.id}>
                                                { row.ownName }
                                            </Link>
                                        </strong>
                                    </Table.Cell>
                                    <Table.Cell>{ row.email }</Table.Cell>
                                    <Table.Cell>{ row.phone }</Table.Cell>
                                    <Table.Cell> { row.active ? <Icon name="checkmark"/> : null } </Table.Cell>
                                </Table.Row>
                            })
                        }
                    </Table.Body>
                </Table>
            </section>
        );
    }
}
            
export default ProviderUserList;