import React, { Component } from 'react';
import { connect } from 'react-redux';
import './banner.scss';
import AliceCarousel from 'react-alice-carousel';
import { Link } from 'react-router-dom';

interface BannerProps {
  bannerItems: any[]
}

interface BannerState {
  ready: boolean
}

const mapStateToProps = (state: any, props: any) => {
  return {
    bannerItems: state.banners
  };
} 

class Banner extends Component<BannerProps, BannerState> {

  constructor(props: any) {
    super(props)
    this.state = { ready: false }
  }

  public Carousel: any;

  componentDidMount() {
    this.setState({ ready: true })
  }

  responsive = {
    500: { items: 1 }
  }

  render() {
    return (
      <div className="banner-container">
        { this.state?.ready ?
          <AliceCarousel
            ref={(el) => (this.Carousel = el)}
            items={this.props?.bannerItems?.map(b => b.url ? 
              <Link className="banner-url" to={b.url}>
                <img src={window?.innerWidth <= 1000 ? window?.innerWidth <= 600 ? b.imageXsUrl : b.imageSmUrl : b.imageLgUrl}/>
              </Link>
              :
              <div className="banner-url"><img src={b.imageLgUrl}/></div>
              )
            }
            mouseTrackingEnabled={true}
            touchTrackingEnabled={true}
            buttonsDisabled={true}
            dotsDisabled={false}
            infinite={this.props?.bannerItems?.length > 1}
            autoHeight={true}
            autoPlay={true}
            stopAutoPlayOnHover={true}
            duration={1500}
            autoPlayInterval={3000}
            responsive={this.responsive}>
          </AliceCarousel>
          :
          null
        }
      </div>
    );
  }
}

export default connect(mapStateToProps)(Banner);