import React, { useEffect, useState } from 'react';
import './category-item.scss';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

function CategoryItem(props: any) {

  useEffect(() => {

  }, [props.item])

  const [ overlayActive, setOverlay ] = useState(false)

  return (
    <div className="category-item">
      <Link to={"/category/" + props.item.slug}>
        <div className="category-background" style={{ backgroundImage: `url(${props.item.imageXsUrl})` }} 
          onMouseOver={() => setOverlay(true)}>
            { overlayActive ? 
              <div className="category-overlay flex-div align-center" onMouseOut={() => setOverlay(false)}>
                <div className="overlay-background"></div>
                <Button className="overlay-link" onMouseEnter={() => setOverlay(true)} onMouseLeave={() => setOverlay(true)}>
                  { props.item.name }
                </Button>
              </div>
              : null
            }
        </div>
        <div className="category-name">
          {props.item.name}
        </div>
      </Link>

      <div className="category-content">
        <div className="left">
          <Link className="category-link" to={"/category/" + props.item.slug}>
            <span className="shop-now">{props.item.subCategories && props.item.subCategories.length > 0 ? "Категориуд" : "Бүтээгдэхүүнүүд"}</span>
          </Link>
          <p className="category-description">{props.item.description}</p>
        </div>
        <div className="right">
          {props.item.subCategories?.map((sub: any) => {
            return <Link className="shop-now" key={sub.id} to={"/category/" + props.item.slug + "/" + sub.slug}>{sub.name}</Link>
          })}
        </div>
      </div>
    </div>
  );
}

export default CategoryItem;