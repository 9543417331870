import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { Container, Dimmer, Icon, Loader } from 'semantic-ui-react';
import store from '../../store';
import CheckoutHeader from '../checkout/checkout-header';
import { formatDateTime } from '../../utils/shared.utils';
import './payment.scss';
import OrderInvoice from '../client-order/order.invoice';
import { ClientModel } from '../../entities/client.model';

interface PaymentComponentProps extends RouteComponentProps {
    match: any,
    settings: any,
    client: ClientModel,
}

const mapStateToProps = (state: any) => {
    return {
        settings: state.settings,
        client: state.client
    }
}

function PaymentPage(props: PaymentComponentProps) {

    const history = useHistory()
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    const [orderToken, setOrderToken] = useState("")
    const [status, setStatus] = useState("")
    const [totalAmount, setTotalAmount] = useState(0)
    const [reserveUntil, setReserveUntil] = useState("")

    useEffect(() => {
        if (props?.match?.params?.token !== undefined && props?.match?.params?.token !== null) {
            fetchOrder(props.match.params.token)
            window.scrollTo(0,0)
        }
    }, [props.match])

    const fetchOrder = (orderToken: number) => {
        var clientToken = localStorage.getItem('clientToken')
        if (clientToken && clientToken !== 'undefined') {
            setErrorMsg("")
            setLoading(true)

            fetch(`${process.env.REACT_APP_CHEF_API}client/order/${orderToken}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': clientToken,
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                setLoading(false)
                if (res.ok) {
                    res.json().then((resJson: any) => {
                        if (resJson.status === 'CONFIRMED') {
                            history.push('/client/order/success/' + resJson.token)
                        }
                        setErrorMsg("")
                        setOrderToken(resJson.token)
                        setStatus(resJson.status)
                        setTotalAmount(resJson.totalAmount)
                        if (resJson.reserveUntil) {
                            setReserveUntil(formatDateTime(new Date(resJson.reserveUntil)))
                        }
                    })
                }
                else {
                    res.json().then((resJson: any) => {
                        if (resJson && resJson.error) {
                            setErrorMsg(resJson.error);
                        }
                        else {
                            setErrorMsg("Серверт алдаа гарлаа")
                        }
                    })
                }
            }).catch(err => {
                setLoading(false)
                setErrorMsg("Сүлжээний алдаа")
                console.error(err);
            })
        }
        else {
            console.log("Will redirect to: " + window.location.pathname)
            store.dispatch({ type: "redirectUrl", data: window.location.pathname })
            history.push("/login")
        }
    }

    const onHeaderClick = () => {
    }

    return (
        <Container>
            <Dimmer active={loading}>
                <Loader size='medium'></Loader>
            </Dimmer>
            <div className="page payment-page">

                { status === 'PLACED' ? <React.Fragment>

                <CheckoutHeader activeIndex={3} onClick={onHeaderClick}></CheckoutHeader>

                <div className="payment-details">
                    <div className="width-100 flex-div">
                        <h2>Төлбөр төлөх заавар</h2>
                    </div>

                    <div className="error-msg">{errorMsg}</div>

                    <div className="transaction-details">
                        <div className="payment-row">
                            <div className="payment-col-2">ХААН БАНК</div>
                        </div>
                        <div className="payment-row">
                            <div className="payment-col-1">Дансны нэр:</div>
                            <div className="payment-col-2">{props.settings.khanbankAccountName}</div>
                        </div>
                        <div className="payment-row">
                            <div className="payment-col-1">Дансны дугаар:</div>
                            <div className="payment-col-2">{props.settings.khanbankAccountNumber}</div>
                        </div>
                        <div className="payment-row">
                            <div className="payment-col-1">Гүйлгээний утга:</div>
                            <div className="payment-col-2">{orderToken}</div>
                        </div>
                        <div className="payment-row">
                            <div className="payment-col-1">Захиалгын дүн:</div>
                            <div className="payment-col-2">
                                <NumberFormat displayType={'text'} value={totalAmount} suffix="₮" thousandSeparator={true} />
                            </div>
                        </div>
                        
                        <div className="transaction-info">
                            <Icon name="info circle" /> Та гүйлгээний утга дээр зөвхөн "{orderToken}" гэж Англи үсгээр бичихийг анхаарна уу.
                        </div>

                        <div className="transaction-info">
                            <Icon name="info circle" />Энэ захиалга <strong>{reserveUntil}</strong> хүртэл танд хадгалагдана.
                        </div>
                    </div>

                    <div className="info">
                        <p>
                            Таны гүйлгээ амжилттай хийгдсэнээс "30" минутын дотор таны имэйл хаяг руу {props.settings.supportEmail} хаягаас захиалга амжилттай хийгдсэн тухай имэйл илгээнэ.
                        </p>
                            <p>
                                Мөн <Link className="client-orders" to="/client/orders">Миний захиалгууд</Link> хэсгийг ашиглан та захиалгын төлвийг шалгах боломжтой.
                        </p>
                    </div>

                    <OrderInvoice token={orderToken} client={props.client}/>
                </div>
            
                </React.Fragment>
                
                :
                
                status === 'ARCHIVED' ?
                
                <div className="flex-div">
                    <h2><Icon name="warning circle"/>&nbsp;Захиалга цуцлагдсан байна</h2>
                </div> : null
                }
            </div>
        </Container>
    )
}

export default connect(mapStateToProps)(PaymentPage);