import React from 'react';
import styled from 'styled-components';
import './dashboard.styles.scss';

class AdminDashboardComponent extends React.Component {
    render() {
        return (
            <div className="page admin-page">
                <h3>Та зүүн дээд булангийн менюг ашиглана уу</h3>
            </div>
        );
    }
}

export default AdminDashboardComponent;