import React, { Component } from 'react';
import './search-page.scss';
import { connect } from 'react-redux';
import { RouteProps } from 'react-router-dom';
import { Container } from 'react-grid-system';
import Products from '../products/products';
import store from '../../store';

interface SearchProps {
  products: any,
  match: any,
  history: any
}

interface SearchState {
}

const mapStateToProps = (state: any) => {
  return {
    products: state.products?.filter((p: any) => p.active && p.brandActive && p.categoryActive)
  }
}

class SearchPage extends Component<SearchProps & RouteProps, SearchState> {

  componentDidMount() {
    setTimeout(() => {
      window.scrollTo(0, 0)
      store.dispatch({ type: 'REFRESH_PRODUCTS' })
    });
  }

  render() {
    return (
      <div className="page search-page">
        <Container fluid={true} className="search-page-container" id="page-container">
          <Products products={this.props.products}></Products>
        </Container>
      </div>
    );
  }
}

export default connect(mapStateToProps)(SearchPage);