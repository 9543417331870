import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => {
    return {
        settings: state.settings,
    }
}

function ContactPage(props: any) {
    return (
        <div className="page contact-page">
            <div className="width-100 margin-top-50 text-center">
                <h2>Холбогдох:</h2>
                <br/>
                <p>
                    <a href="https://m.me/ChefProMN" className="btn-chat" target="_blank" rel="noopener noreferrer">
                      <span className="text xs-hidden">&nbsp;Facebook-р холбогдох</span>
                    </a>
                </p>
                <br/>
                <p>
                    <a href={`tel:${props.settings.supportPhone}`}>
                    <i className="icon icon-phone"></i>
                    +976 {props.settings.supportPhone || '99190290'}
                    </a>
                </p>
                <br/>
                <p>
                    <i className="icon icon-envelop">&nbsp;</i>
                    <a href={`mailto:${props.settings.supportEmail}`}>{props.settings.supportEmail}</a>
                </p>
            </div>
        </div>
    );
}

export default connect(mapStateToProps)(ContactPage);