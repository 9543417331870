class OrderDetailsModel {
  id: any
  token: string = ""
  clientId: any
  payLater: any
  status: string = ""
  totalAmount: number = 0
  totalDeliveryCost: number = 0
  deliveries: OrderDelivery[] = []
  town: string = ""
  district: string = ""
  unit: string = ""
  street: string = ""
  company: CompanyModel = new CompanyModel()
}

class OrderDelivery {
  id: any
  orderId: any
  date: any
  cost: Number = 0
  items: any[] = []
}

class CompanyModel {
  name: string = ""
  register: string = ""
  town: string = ""
  district: string = ""
  unit: string = ""
  street: string = ""
}

export default OrderDetailsModel