import React, { Component } from 'react';
import './sidebar-categories.scss';
import { Link } from 'react-router-dom';
import store from '../../store';

interface SidebarCategoriesProp {
}

interface SidebarCategoriesState {
  categories: any[];
}

class SidebarCategories extends Component<SidebarCategoriesProp, SidebarCategoriesState> {

  constructor(props: any) {
    super(props);
    this.state = store.getState();
  }

  toggleCategory = (category: any) => {
    if (!category.show) {
      category.show = true;
    } else {
      category.show = false;
    }
    this.setState(this.state);
  }

  closeSidebar = () => {
    store.dispatch({
      type: 'sidebarOpen',
      value: false
    });
    document.body.classList.remove("no-scroll");
  }

  render() {
    return (
      <div className="sidebar-categories">
        <div className="black-layer" onClick={() => this.closeSidebar()}></div>
        <div className="sidebar-background"></div>

        <div className="sidebar-container">
          <div className="close-section" onClick={() => this.closeSidebar()}>
            <i className="icon icon-cross"></i>
            <span className="close">Хаах</span>
          </div>

          <div className="sidebar-content">
            <div className="logo-section">
              <div className="logo section">
                <Link to="/" className="logo-text" onClick={() => this.closeSidebar()}>
                  <div className="line-1">CHEF</div>
                  <div className="line-2">Professional</div>
                </Link>
              </div>
            </div>

            <div className="sidebar-category-items">
              {this.state.categories?.filter(c => c.active).map((category) => {
                return <div className="sidebar-category-item" key={'sidebar-category-item-' + category.id}>
                  <div className="category">
                    <div className="category-name" onClick={() => { this.toggleCategory(category) }}>
                      <div>{category.name}</div>
                    </div>
                  </div>

                  {category.show ? <Link className="shop-now category-link" to={'/category/' + category.slug} onClick={() => this.closeSidebar()}>
                      <div className="sub-category">
                        <strong>Бүтээгдэхүүнүүд</strong>
                        </div>
                    </Link> : null
                  }

                  {category.show && category.subCategories.map((sub: any) => {
                    return <Link to={'/category/' + category.slug + "/" + sub.slug} key={'sidebar-sub-category-' + sub.id} onClick={() => this.closeSidebar()}>
                      <div className="sub-category">
                        {sub.name}
                      </div>
                    </Link>
                  })}
                </div>
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SidebarCategories