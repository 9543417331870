import React, { Component } from 'react';
import "./terms.scss";
import { Container, Row, Col } from 'react-grid-system';

class Terms extends Component {
    render() {
        return (
            <div className="page terms-page">
                <Container fluid={false}>
                    <Row>
                        <Col>
                            1. Ерөнхий заалт
                        </Col>
                        <p className="row">
                        Хэрэглэгч вэбсайтын талаар санал гомдол, шинэ санаа, шинэ бараа борлуулах хүсэлт, шүүмж зэргийг вэбийн журмын дагуу илэрхийлэх, илгээх эрхтэй.
                        </p>
                    </Row>
                    <Row>
                        <Col>
                            2. Үйл ажиллагаа
                        </Col>
                        <p className="row">
                            Үйлчилгээний нийт төлбөр нь бараа бүтээдэхүүний үнэ болон хүргэлтийн хаягт тохирсон төлбөрийн нийлбэр дүн байна.
                            Хэрэглэгч вэбсайтын талаар санал гомдол, шинэ санаа, шинэ бараа борлуулах хүсэлт, шүүмж зэргийг вэбийн журмын дагуу илэрхийлэх, илгээх эрхтэй.
                        </p>
                    </Row>
                    <Row>
                        <Col>
                            3. Хэрэглэгч
                        </Col>
                        <p className="row">
                        {process.env.REACT_APP_DOMAIN}-ээр үйлчлүүлэхдээ хэрэглэгч бүртгүүлсэн байна. Дэлгэрэнгүй мэдээлэл авах, худалдан авалт хийхийг хүссэн ямар ч хэрэглэгч вэб бүртгэлд имэйл хаяг, утасны дугаар болон бусад шаардлагатай мэдээллийг илгээх, заасан хүснэгтэд бөглөнө.

Хэрэглэгчийн мэдээллийн бүрэн бүтэн болон үнэн зөв байдлыг байгууллага цаг тухай бүрт шалгаж байх ба эдгээр мэдээлэл нууц байж, нууцлалыг байгууллага бүрэн хамгаална.

Хэрэглэгчийн мэдээллийн үнэн зөв, бодит байдалд хэрэглэгч бүрэн хариуцлага хүлээнэ.

Нэг хэрэглэгч нэг л имэйл хаягаар бүртгүүлэх эрхтэй. Ашиглагдсан имэйл хаяг дахин өөр бүртгэлд ашиглагдахгүй.

Шинээр бүртгүүлмэгц тухайн хэрэглэгчийн имэйл хаягт дэлгэрэнгүй мэдээлэл илгээгдэнэ.
                        </p>
                    </Row>
                    <Row>
                        <Col>
                            3. Бусад
                        </Col>
                        <p className="row">
                        Худалдан авагчийн мэдээлэл болон төлбөрийн талаар ямар нэг асуудал эсвэл тодруулга гарвал вэбсайтын оператор хэрэглэгчийн утас эсвэл имэйл рүү холбогдож лавлагаа хийж болно.
    
    Худалдан авагч худалдан авсан бараа нь төсөөлж байснаас өөр, сэтгэл ханамжгүй байвал барааг зөвхөн 24 цагийн дотор буцаах эрхтэй.

    Хэрэглэгчээс гарсан гомдол, маргаантай асуудлыг вэбсайтын үйлчилгээний нөхцөл, нууцлалын баталгаа болон Монгол улсын хууль дүрмийн дагуу шийдвэрлэнэ.

    Хэрэглэгч санал хүсэлт, асуулт, лавлагааг имэйл болон чат ашиглан мөн 99190290 утсаар холбогдон хийж болно.
                        </p>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Terms;