import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { Icon, Table } from 'semantic-ui-react';
import OrderItemsComponent from './order.items';

interface DeliveryCompProps {
    delivery: any
}

function OrderDeliveryComponent(props: DeliveryCompProps) {

    const [showItems, setShowItems] = useState(true)

    return (<React.Fragment>
        <Table.Row className="delivery-row background-white">
            <Table.Cell className="cursor" colSpan={9} onClick={() => setShowItems(!showItems)}>
                <div className="order-delivery-top cursor">
                    <span className="delivery-date">
                        <Icon name="calendar outline"/>{props.delivery?.date}
                    </span>
                    &nbsp;
                    <span className="delivery-cost"> - Хүргэлтийн үнэ: - <NumberFormat displayType="text" value={props.delivery?.cost} thousandSeparator={true} suffix="₮" /></span>
                </div>
            </Table.Cell>
        </Table.Row>
        { showItems ? <OrderItemsComponent items={props?.delivery?.items} /> : null }
        
    </React.Fragment>
    );
}

export default OrderDeliveryComponent;