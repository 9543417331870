import React, { Component } from 'react';
import { connect } from 'react-redux';
import store from '../../store';

const mapStateToProps = (state: any) => {
  return {
    client: state.client
  }
}

interface UserComponentProps {
  client: any
  children: any
}

class UserComponent extends Component<UserComponentProps> {

  public triedSavedProducts = 0;

  componentDidMount() {
    var clientToken = localStorage.getItem('clientToken')
    if (clientToken && clientToken !== 'undefined') {
      this.getClient(clientToken)
    }
  }

  getClient(token: string) {
    fetch(process.env.REACT_APP_CHEF_API + 'client', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    })
    .then((res: any) => {
        if (res.ok) {
            res.json().then((resJson: any) => {
                store.dispatch({
                    type: 'authenticateClient',
                    client: resJson.client
                })
                this.getSavedProducts(token)
            })
        }
    })
    .catch((err: any) => {
        console.error(err)
    })
  }

  getSavedProducts(token: string) {
    fetch(process.env.REACT_APP_CHEF_API + 'client/saved-products', {
      method: 'GET',
      headers: {
          'Accept': 'application/json',
          'Authorization': token,
          'Content-Type': 'application/json'
      }
    })
    .then((res: any) => {
        if (res.ok) {
            res.json().then((resJson: any) => {
              store.dispatch({
                type: 'savedProducts',
                data: resJson.data
              })
            })
        }
    })
    .catch((err: any) => {
      console.error(err)
      if (this.triedSavedProducts < 4) {
        this.triedSavedProducts++;
        this.getSavedProducts(token)
      }
    })
  }

  render() {
    return (
      <React.Fragment>
          {this.props.children}
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(UserComponent);