import React from 'react';
import NumberFormat from 'react-number-format';
import { Table } from 'semantic-ui-react';

interface CompanyProps {
  items: any[]
}

function OrderItemsComponent(props: CompanyProps) {

  return (<React.Fragment>
    {props?.items?.map((order: any, index: number) => {
      return <Table.Row key={`order-${order.id}-${index}`} className="background-white">
        <Table.Cell colSpan={9}>
          <div className="flex-only align-center">
            {/* <img src={order.product.imageXsUrl} height="50" width="50" /> */}
            {/* &nbsp; */}
            <strong>{order.product.name}</strong>
            <span>
              &nbsp;
              <NumberFormat displayType="text" value={order.price} suffix="₮" thousandSeparator={true} /> x {order.count}
              &nbsp;=&nbsp;
              <NumberFormat displayType="text" value={order.price * order.count} suffix="₮" thousandSeparator={true} />
            </span>
          </div>
        </Table.Cell>
      </Table.Row>
    })}
  </React.Fragment>
  );
}

export default OrderItemsComponent;