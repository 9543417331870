export class ImageModel {
    showOriginal: boolean = false
    file: any
    name: string = ""
    uploaded: boolean = false

    constructor(file: any, name: string, uploaded: boolean, showOriginal: boolean) {
        this.file = file;
        this.name = name;
        this.uploaded = uploaded;
        this.showOriginal = showOriginal;
    }

    static empty() {
        return new ImageModel(undefined, "", false, false);
    }
}