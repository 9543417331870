class S3Manager {
    static async uploadImage(key: string, file: any) {
        var token = localStorage.getItem('adminToken')
        if (token && token !== 'undefined') {
            return await fetch(process.env.REACT_APP_CHEF_API + 'mongol/upload-url', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    key: key
                })
            })
            .then((res: any) => {
                if (res.ok) {
                    return res.json().then(async (resJson: any) => {
                        let imgBlob = new Blob([file], { type: 'image/jpeg' });
                        await fetch(resJson.uploadUrl, {
                            method: 'PUT',
                            headers: {
                                ContentType: 'image/jpeg',
                            },
                            body: imgBlob
                        }).then((res: any) => {
                            console.log("upload status", res)
                        })
                        .catch(err => {
                            console.error(err)
                        })
                    });
                }
            })
            .catch(err => {
                console.error(err)
            })
        }
    }

    static deleteImage(photoKey: string) {
        var token = localStorage.getItem('adminToken')
        if (token && token !== 'undefined') {
            fetch(process.env.REACT_APP_CHEF_API + 'mongol/delete-bucket-item', {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    key: photoKey
                })
            })
            .then((res: any) => {
                console.log("delete bucket item response", res)
            })
            .catch(err => {
                console.error(err)
            })
        }
    }
}

export default S3Manager;