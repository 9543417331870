import React from 'react';
import { Form, Container, Icon, Dimmer, Loader } from 'semantic-ui-react';
import { ProviderModel } from '../../../entities/provider.model';
import MyForm from './form';
import { ImageModel } from '../../../entities/image.model';
import ImageOnS3 from '../../../components/imageOnS3/imageOnS3.component';
import MyFormState from './MyFormState';
import { Redirect } from 'react-router-dom';

interface FormProps {
    match: any,
    history: any
}

class ProviderForm extends MyForm<FormProps, MyFormState> {
    entity = "provider";

    constructor(props: any) {
        super(props);
        this.state = {
            model: new ProviderModel(),
            successMsg: "",
            errorMsg: "",
            loading: false,
            editing: false,
            editingSlug: false,
            slugValid: true,
            imageObj: ImageModel.empty(),
            imageObjs: { lg: ImageModel.empty(), sm: ImageModel.empty(), xs: ImageModel.empty(),
                image1: ImageModel.empty(), image2: ImageModel.empty(), image3: ImageModel.empty(),
                image4: ImageModel.empty(), image5: ImageModel.empty(), image6: ImageModel.empty()
            },
            redirect: "",
        }
    }

    validate(): boolean {
        this.setState({ successMsg: "" });
        if (!this.state.model.name) {
            this.setState({ errorMsg: "Байгууллагын нэрийг оруулна уу." });
            return false;
        }
        if (!this.state.model.register) {
            this.setState({ errorMsg: "Байгууллагын регистр оруулна уу." });
            return false;
        }
        if (!this.state.model.email) {
            this.setState({ errorMsg: "Байгууллагын имэйл оруулна уу." });
            return false;
        }
        if (!this.validateEmail(this.state.model.email)) {
            this.setState({ errorMsg: "Байгууллагын имэйл буруу форматтай байна." });
            return false;
        }
        if (!this.state.model.phone) {
            this.setState({ errorMsg: "Байгууллагын утасны дугаар оруулна уу." });
            return false;
        }
        if (!this.validatePhone(this.state.model.phone)) {
            this.setState({ errorMsg: "Байгууллагын утасны дугаар буруу форматтай байна." });
            return false;
        }
        if (!this.state.editing) {
            if (!this.state.model.contactName) {
                this.setState({ errorMsg: "Холбогдох хүний нэр оруулна уу" });
                return false;
            }
            if (!this.state.model.contactRole) {
                this.setState({ errorMsg: "Холбогдох хүний албан тушаал оруулна уу" });
                return false;
            }
            if (!this.state.model.contactEmail) {
                this.setState({ errorMsg: "Холбогдох хүний имэйл оруулна уу" });
                return false;
            }
            if (!this.state.model.contactPhone) {
                this.setState({ errorMsg: "Холбогдох хүний утасны дугаар оруулна уу" });
                return false;
            }
            if (!this.validatePhone(this.state.model.contactPhone)) {
                this.setState({ errorMsg: "Холбогдох хүний утасны дугаар буруу форматтай байна." });
                return false;
            }
        }
        if (!this.state.model.town) {
            this.setState({ errorMsg: "Хот оруулна уу" });
            return false;
        }
        if (!this.state.model.district) {
            this.setState({ errorMsg: "Дүүрэг оруулна уу" });
            return false;
        }
        if (!this.state.model.unit) {
            this.setState({ errorMsg: "Хороо оруулна уу" });
            return false;
        }
        if (!this.state.model.street) {
            this.setState({ errorMsg: "Дэлгэрэнгүй хаяг оруулна уу" });
            return false;
        }
        if (this.state.model.id !== undefined) {
            if (this.state.model.name === this.originalModel.name
                && this.state.model.register === this.originalModel.register
                && this.state.model.info === this.originalModel.info
                && this.state.model.email === this.originalModel.email
                && this.state.model.phone === this.originalModel.phone
                && this.state.model.contactName === this.originalModel.contactName
                && this.state.model.contactPhone === this.originalModel.contactPhone
                && this.state.model.contactEmail === this.originalModel.contactEmail
                && this.state.model.contactRole === this.originalModel.contactRole
                && (!this.state.imageObj.file && this.state.model.imageUrl === this.originalModel.imageUrl)
                && this.state.model.approved === this.originalModel.approved
                && this.state.model.town === this.originalModel.town
                && this.state.model.district === this.originalModel.district
                && this.state.model.unit === this.originalModel.unit
                && this.state.model.street === this.originalModel.street
                && this.state.model.zipcode === this.originalModel.zipcode
                && this.state.model.active === this.originalModel.active
                ) {
                this.setState({ errorMsg: "Өөрчлөлт байхгүй байна" });
                return false;
            }
        }
        this.setState({ errorMsg: "" });
        return true;
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }
        return (
            <Container>
                <Dimmer active={this.state.loading} inverted>
                    <Loader size='medium'></Loader>
                </Dimmer>
                <h2>{!this.state.editing ? 'Шинэ' : ''} Нийлүүлэгч</h2>
                <Form widths='equal'>
                    <Form.Group>
                        { this.state.successMsg ? <div className="success-msg">{this.state.successMsg}</div> : null }
                        { this.state.errorMsg ? <div className="error-msg">
                            <Icon name="warning circle"/>
                        {this.state.errorMsg}</div> : null }
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field label='Байгууллагын нэр' required control='input' value={this.state.model.name} onChange={this.onNameChange} />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field label='Байгууллагын регистр' required control='input' value={this.state.model.register} onChange={this.onRegisterChange} />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field label='Байгууллагын имэйл' control='input' value={this.state.model.email} onChange={this.onEmailChange} />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field label='Байгууллагын утасны дугаар' required control='input' value={this.state.model.phone} onChange={this.onPhoneChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.TextArea label='Товч танилцуулга' width="16" value={this.state.model.info} onChange={this.onInfoChange}/>
                    </Form.Group>

                    { this.state.editing ? null : <React.Fragment>
                    <Form.Group>
                        <p>
                            <Icon name="info circle" color="blue"/>
                            Харилцах хүний имэйл хаягт тус компаний бүтээгдэхүүн агуулахыг удирдах аккаунт үүсч, нууц үг үүсгэх линк бүхий имэйл илгээгдэнэ.
                        </p>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field required label='Харилцах хүний нэр' control='input' value={this.state.model.contactName} onChange={this.onContactNameChange} />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field required label='Харилцах хүний албан тушаал' control='input' value={this.state.model.contactRole} onChange={this.onContactRoleChange} />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field required label='Имэйл' control='input' type="email" value={this.state.model.contactEmail} onChange={this.onContactEmailChange} />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field required label='Утасны дугаар' control='input' value={this.state.model.contactPhone} onChange={this.onContactPhoneChange} />
                    </Form.Group>
                    </React.Fragment> }
                    <Form.Group>
                        <h3>Байгууллагын хаяг</h3>
                    </Form.Group>
                    <Form.Group>
                        <Form.Input label='Хот' required value={this.state.model.town} onChange={this.onTownChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Input label='Дүүрэг' required value={this.state.model.district} onChange={this.onDistrictChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Input label='Хороо' required value={this.state.model.unit} onChange={this.onAddressUnitChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.TextArea label='Дэлгэрэнгүй хаяг' required value={this.state.model.street} onChange={this.onStreetChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Input label='Зип код' value={this.state.model.zipcode} onChange={this.onZipCodeChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Checkbox checked={this.state.model.approved} onChange={this.onApprovedChange} label='Зөвшөөрсөн' />
                    </Form.Group>
                    <Form.Group>
                        <Form.Checkbox checked={this.state.model.active} onChange={this.onActiveChange} label='Идэвхитэй' />
                    </Form.Group>
                    <Form.Group className="flex-space-between">
                        <div className="field">
                            <label>Лого (1x1)</label>
                        </div>
                        { this.state.imageObj.uploaded ?
                        <div className={ this.state.imageObj.showOriginal ? "flex-space-between" : "hidden-div"}>
                            <ImageOnS3 imgKey={this.state.model.imageUrl} width='50px' height='50px'/>
                            <Icon className="cursor margin-10" name="trash" size='large' onClick={() => this.toggleImage('imageUrl')} />
                        </div> : null }
                        <Icon className={this.state.imageObj.uploaded && !this.state.imageObj.showOriginal ? "" : "hidden-div"} name='redo' size='large' onClick={() => this.toggleImage('imageUrl')}/>
                        <Form.Input control='input' type="file" accept="image/png, image/jpeg" onChange={this.handleImageChange} />
                    </Form.Group>
                    <Form.Button disabled={this.state.loading} onClick={() => this.save()}>Хадгалах</Form.Button>
                </Form>
            </Container>
        );
    }
}

export default ProviderForm;