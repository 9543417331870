export class ProviderUserModel {
    id: any
    parentName: string = ''
    ownName: string = ''
    email: string = ''
    phone: string = ''
    town: string = 'Улаанбаатар'
    district: string = ''
    unit: string = ''
    street: string = ''
    zipcode: string = ''
}