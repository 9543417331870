import React, { Component } from 'react';
import './featured.scss';
import { connect } from 'react-redux';
import { Col } from 'react-grid-system';
import { Link } from 'react-router-dom';

interface MyProps {
  featured: []
}

const mapStateToProps = (state: any) => {
  return {
    featured: state.featured
  }
}

class Featured extends Component<MyProps> {
  render() {
    return (
      <div className="featured-container">
        {this.props?.featured?.slice(0, 3).map((item: any, i) => {
          return <Col key={'featured-' + i} xs={4} sm={4} md={4}>
            <Link to={item.url}>
              <div className="featured-item" style={{ backgroundImage: `url(${item.imageXsUrl})` }}>
                <div className="featured-title">{item.info}</div>
              </div>
            </Link>
          </Col>
        })}
      </div>
    );
  }
}

export default connect(mapStateToProps)(Featured);