import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import NumberFormat from 'react-number-format';
import { parseStatus } from '../../utils/shared.utils';
import './client.orders.scss';

interface OrdersProps {
  orders: any[]
  showStaff: boolean
}

function OrderDetailsComponent(props: OrdersProps) {

  useEffect(() => {
    setOrders(props.orders)
  }, [props.orders])

  const [orders, setOrders] = useState<any[]>([])

  const toggle = (index: number) => {
    let ordersCopy = [...orders];
    ordersCopy[index].showItems = !ordersCopy[index].showItems;
    setOrders(ordersCopy);
  }

  return (
    <React.Fragment>
      {orders?.map((order: any, index: number) => {
        return <div className="client-order" key={"order-" + index}>
          <div className="order-top">
            <div className="order-col order-id font-color-2">
              {/* <div className="col-top">№</div> */}
              <div className="cursor" onClick={() => toggle(index)}>
                <strong>{order.token}</strong>
              </div>
            </div>
            <div className="order-col order-amount cursor" onClick={() => toggle(index)}>
              {/* <div className="col-top">Нийт дүн</div> */}
              <div>
                <NumberFormat displayType={'text'} value={order.totalAmount} suffix="₮" thousandSeparator={true} />
              </div>
            </div>
            <div className="order-col cursor" onClick={() => toggle(index)}>
              {/* <div className="col-top">Төлөв</div> */}
              <div className={`order-status ${String(order.status || '').toLowerCase()}`}>{parseStatus(order.status)}</div>
            </div>
            <div className="order-toggle cursor" onClick={() => toggle(index)}>
              { order.showItems ? <Icon name="minus" color="orange"/> : <Icon name="plus" color="orange"/> }
            </div>
          </div>

          { order.showItems ? <React.Fragment>
            <div className="order-items">
              { order.status === 'PLACED' ? 
              <div className="pay-link">
                <Link className="underline" to={"/client/payment/" + order.token}>Төлбөр төлөх заавар</Link>
              </div> : null }

              { props?.showStaff && order?.createdBy ? <div className="order-info">
                <strong className="col-top">Захиалсан ажилтан: </strong>
                <span>{ order.createdBy }</span>
              </div> : null }

              <div className="order-info">
                <strong className="col-top">Захиалсан огноо: </strong>
                <span>{ order.createdAtStr }</span>
              </div>

              <div className="order-address">
                <strong className="col-top">Хаяг: </strong>
                <span>{order.town}, {order.district}, {order.unit}, {order.street}</span>
              </div>

              {order.deliveries?.map((delivery: any, dindex: number) => {
                return <div className="order-delivery" key={'delivery-'+delivery.id}>
                  <div className="order-delivery-top cursor">
                    <span className="delivery-date">
                      <Icon name="calendar outline"/>{delivery?.date}
                    </span>
                        &nbsp;
                        <span className="delivery-cost"> - Хүргэлтийн үнэ: - <NumberFormat displayType="text" value={delivery?.cost} thousandSeparator={true} suffix="₮" /></span>
                  </div>
                  <table>
                    <tbody>
                        {delivery?.items?.map((order: any, index: number) => {
                            return <tr key={`order-${order.id}-${index}`}>
                                <td>
                                    <img src={order.product.imageXsUrl} height="50" width="50" />
                                </td>
                                <td><Link to={`/product/${order.product.slug}`}>{order.product.name}</Link></td>
                                <td>
                                    <strong>&nbsp;ш/үнэ:&nbsp;</strong>
                                    <NumberFormat displayType="text" value={order.price} suffix="₮" thousandSeparator={true} /> x {order.count}
                                    &nbsp;=&nbsp;
                                    <NumberFormat displayType="text" value={order.price * order.count} suffix="₮" thousandSeparator={true} />
                                </td>
                            </tr>
                        })}
                    </tbody>
                  </table>
                </div>
              })
              } 
            </div>
            <div className="order-summary">
              <div>Барааны нийт үнэ: <NumberFormat displayType="text" value={order.totalAmount - order.totalDeliveryCost} suffix="₮" thousandSeparator={true} /></div>
              <div>Хүргэлтийн нийт үнэ: <NumberFormat displayType="text" value={order.totalDeliveryCost} suffix="₮" thousandSeparator={true} /></div>
              <div className="strong-text">Нийт үнэ: <NumberFormat displayType="text" value={order.totalAmount} suffix="₮" thousandSeparator={true} /></div>
            </div>
          </React.Fragment> : null }
        </div>
      })
      }
    </React.Fragment>
  );
}

export default OrderDetailsComponent;