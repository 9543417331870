export class AddressModel {
    town: string = 'Улаанбаатар'
    district: string = ''
    unit: string = ''
    street: string = ''

    static createAddressModel(obj: any) {
        let model = new AddressModel();
        model.town = obj.town;
        model.district = obj.district;
        model.unit = obj.unit;
        model.street = obj.street;

        return model;
    }

    isValid(): boolean {
        return (this.town && this.district && this.unit && this.street) ? true : false;
    }

    toString() {
        return `${this.town} ${this.district} ${this.unit} ${this.street}`
    }
}