export class CategoryModel {
    id: any
    parentId: any
    name: string = ''
    shortName: string = ''
    slug: string = ''
    info: string = ''
    imageLgUrl: string = ''
    imageSmUrl: string = ''
    imageXsUrl: string = ''
    active: boolean = true
    isFinal: boolean = false
    types: any[] = []
    usages: any[] = []
}