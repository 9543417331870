import React from 'react';
import styled from 'styled-components';
import { Button, Table, Icon } from 'semantic-ui-react';
import 'semantic-ui-css/components/table.min.css';
import { Link, Redirect } from 'react-router-dom';
import MyList from './list';

const FullWidth = styled.section`
    width: 100%;
    padding: 10px;
`;

interface ListProps {

}

interface ListState {
    data: any[],
    errorMsg: string
}

class ProviderList extends MyList {

    entity = "provider";

    refresh() {
        var token = localStorage.getItem('adminToken')
        if (token && token !== 'undefined') {
            fetch(process.env.REACT_APP_CHEF_API + "mongol/providers", {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': token,
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                if (res.status === 401) {
                    this.setState({ redirect: this.loginUrl})
                } else {
                    res.json().then(res => {
                        console.log(res);
                        if (!res || !res.data) {
                            return;
                        }
                        let data: any[] = res.data;
                        data.forEach(b => b.confirmingDelete = false);
                        this.setState({ data: data });
                    })
                }
            })
            .catch(err => console.error(err));
        }
        else {
            this.setState({ redirect: this.loginUrl });
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }
        return (
            <FullWidth>
                <h2>Нийлүүлэгч байгууллагууд</h2>
                {/* Нийт: {this.state?.data?.listCategories?.items.length} */}
                <Link to="/mongol/providers/new">
                    <Button basic color='orange'>Шинээр үүсгэх</Button>
                </Link>

                {this.state.errorMsg ? <div className="error-msg">
                    <Icon name="warning circle"/>&nbsp;
                    {this.state.errorMsg}
                </div> : null}

                <Table striped width={16}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>№</Table.HeaderCell>
                            <Table.HeaderCell>Байгууллагын нэр</Table.HeaderCell>
                            {/* <Table.HeaderCell>Регистр</Table.HeaderCell> */}
                            {/* <Table.HeaderCell>Имэйл</Table.HeaderCell> */}
                            <Table.HeaderCell>Утас</Table.HeaderCell>
                            <Table.HeaderCell>Ажилтан</Table.HeaderCell>
                            <Table.HeaderCell>Зөвшөөрсөн</Table.HeaderCell>
                            <Table.HeaderCell>Идэвхитэй</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        { this.state?.data?.map((row: any, index: number) => {
                                return <Table.Row key={row.id}>
                                    <Table.Cell>{ String(index+1) }</Table.Cell>
                                    <Table.Cell>
                                        <strong>
                                            <Link className="underline hover-blue" to={"/mongol/provider/" + row.id}>{ row.name }</Link>
                                        </strong>
                                    </Table.Cell>
                                    {/* <Table.Cell>{row.register}</Table.Cell> */}
                                    {/* <Table.Cell>{row.email}</Table.Cell> */}
                                    <Table.Cell>{row.phone}</Table.Cell>
                                    <Table.Cell>
                                        <Link className="underline" to={"/mongol/provider-user/" + row.userId}>{row.ownName}</Link>
                                    </Table.Cell>
                                    <Table.Cell> { row.approved ? <Icon name="checkmark"/> : null } </Table.Cell>
                                    <Table.Cell> { row.active ? <Icon name="checkmark"/> : null } </Table.Cell>
                                    <Table.Cell>
                                        { row.confirmingDelete ? 
                                            <React.Fragment>
                                                <Button primary size='mini' onClick={() => this.delete(index)}>Устга</Button>
                                                <Button size='mini' onClick={() => this.toggleDelete(index)}>Болих</Button>
                                            </React.Fragment> :
                                            <Icon name="trash" className="cursor chef-icon-hover padding-5" onClick={() => this.toggleDelete(index)}/>
                                        }
                                    </Table.Cell>
                                </Table.Row>
                            })
                        }
                    </Table.Body>
                </Table>
            </FullWidth>
        );
    }
}
            
export default ProviderList;