import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Dimmer, Form, Icon, Loader, Table } from 'semantic-ui-react';
import { formatDate } from '../../../utils/shared.utils';

class DayOffModel {
  id: any
  date: any
  confirmingDelete: boolean = false
}

function DayOffComponent(props: any) {

  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [errorMsg, setErrorMsg] = useState("")
  const [items, setItems] = useState<any[]>([])
  const [fetchedData, setFetchedData] = useState(false)

  // new data
  const [dayOff, setDayOff] = useState("")

  useEffect(() => {
      getData();
  }, [])

  const getData = () => {
    if (fetchedData) {
      return;
    }
    var token = localStorage.getItem('adminToken')
    if (token && token !== 'undefined') {
      setFetchedData(true)
      fetch(process.env.REACT_APP_CHEF_API + 'list/day_off', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': token,
          'Content-Type': 'application/json'
        }
      }).then((res: any) => {
        setLoading(false)
        if (res.ok) {
          res.json().then((resJson: any) => {
            if (resJson.data) {
              let temp: any[] = [];
              for (let item of resJson.data) {
                item.confirmingDelete = false;
                item.date = formatDate(new Date(item.date))
                temp.push(item)
              }
              setItems(temp)
            }
          })
        }
      }).catch(err => {
        setLoading(false)
        setErrorMsg("Сүлжээний алдаа")
        console.error(err)
      })
    }
    else {
      window.alert("Та дахин нэвтэрнэ үү")
      history.push("/mongol/login")
    }
  }

  const submit = () => {
    if (!dayOff) {
      return;
    }
    var token = localStorage.getItem('adminToken')
    if (token && token !== 'undefined') {
      if (items?.filter(e => e.date === dayOff).length > 0) {
        setErrorMsg(`${dayOff} аль хэдийн нэмэгдсэн байна`)
        return;
      }
      setLoading(true)
      fetch(process.env.REACT_APP_CHEF_API + 'post/day_off', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ date: dayOff })
      }).then((res: any) => {
        setLoading(false)
        if (res.ok) {
          res.json().then((resJson: any) => {
            let temp: any[] = Object.assign([], items);
            temp.push(Object.assign({ confirmingDelete: false, }, resJson.data ))
            setItems(temp)
          })
        }
        else {
          setErrorMsg("Серверт алдаа гарлаа")
        }
      }).catch(err => {
        setLoading(false)
        setErrorMsg("Сүлжээний алдаа")
        console.error(err)
      })
    }
    else {
      window.alert("Та дахин нэвтэрнэ үү")
      history.push("/mongol/login")
    }
  }

  const toggleDelete = (index: number) => {
    let temp: any[] = Object.assign([], items);
    temp[index].confirmingDelete = !temp[index].confirmingDelete;
    setItems(temp)
  }

  const deleteItem = (index: number) => {
    if (!items[index]) {
      return;
    }
    var token = localStorage.getItem('adminToken')
    if (token && token !== 'undefined') {
      let toDelete = items[index];
      fetch(`${process.env.REACT_APP_CHEF_API}delete/day_off/${toDelete.id}`, {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Authorization': token,
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          if (res.ok) {
            let temp: any[] = Object.assign([], items);
            temp.splice(index, 1)
            setItems(temp)
          }
          else {
            setErrorMsg("Төлбөр устгахад алдаа гарлаа")
          }
        });
    }
  }

  return (
    <div className="page page-order">
      <Dimmer active={loading} inverted>
        <Loader size='medium'></Loader>
      </Dimmer>
      <div className="width-100">
        <h2>Хүргэлтгүй өдрүүд</h2>
        <div className="">
          {errorMsg ? <div className="text-center error-msg width-100">{errorMsg}</div> : null}
          
          <Form>
            <Form.Input type="date" required label="Шинээр нэмэх" value={dayOff} onChange={(e) => setDayOff(e.target.value)} />
            <Form.Button color="olive" onClick={() => submit()}>Нэмэх</Form.Button>
          </Form>

          <hr/>

          <Table width={16}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>№</Table.HeaderCell>
                <Table.HeaderCell>Өдөр</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>Тогтмол</Table.Cell>
                <Table.Cell>Ням гариг</Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
              {
                items.map((item: DayOffModel, pindex: number) => {
                  return <Table.Row key={"dayoff-" + pindex}>
                    <Table.Cell>{pindex + 1}</Table.Cell>
                    <Table.Cell>{item.date}</Table.Cell>
                    <Table.Cell><span>{item.confirmingDelete}</span>
                      {item.confirmingDelete ? <React.Fragment>
                        <Button primary size='mini' onClick={() => deleteItem(pindex)}>Устга</Button>
                        <Button size='mini' onClick={() => toggleDelete(pindex)}>Болих</Button>
                      </React.Fragment> :
                        <Icon name="trash" className="cursor chef-icon-hover padding-5" onClick={() => toggleDelete(pindex)} />
                      }
                    </Table.Cell>
                  </Table.Row>
                })
              }
            </Table.Body>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default DayOffComponent;