import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import './provider.layout.scss';
import { Menu, Button } from 'semantic-ui-react';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/dimmer.min.css';
import 'semantic-ui-css/components/form.min.css';
import 'semantic-ui-css/components/input.min.css';
import 'semantic-ui-css/components/item.min.css';
import 'semantic-ui-css/components/list.min.css';
import 'semantic-ui-css/components/loader.min.css';
import 'semantic-ui-css/components/menu.min.css';
import 'semantic-ui-css/components/placeholder.min.css';
import 'semantic-ui-css/components/search.min.css';

import 'semantic-ui-css/components/embed.min.css';
import 'semantic-ui-css/components/message.min.css';
import 'semantic-ui-css/components/nag.min.css';
import 'semantic-ui-css/components/embed.min.css';
import { connect } from 'react-redux';

interface LayoutProps {
    settings: any
    children: any
}
interface LayoutState {
    sidebarOpen: boolean;
    redirect: string
}

const mapStateToProps = (state: any) => {
    return {
        settings: state.settings,
    }
}

const year = new Date().getFullYear();

class ProviderLayout extends React.Component<LayoutProps, LayoutState> {

    constructor(props: any) {
        super(props);
        this.state = {
            sidebarOpen: true,
            redirect: ""
        };
    }

    componentDidMount() {
        this.setState({ sidebarOpen: false });
    }

    logout() {
        localStorage.removeItem('providerToken')
        this.setState({ redirect: "/provider-login" })
    }



    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }
        return (
            <div className="provider-layout">
                <div className="upper-container">
                    <div className="upper-section">

                        <div className="left section">
                            <div className="left-content">
                                <Link to="/provider/warehouse" className="logo-text">
                                    <div className="logo-text">
                                        <div className="line1">CHEF</div>
                                        <div className="line2">Professional</div>
                                        <div className="line3">Warehouse</div>
                                    </div>
                                </Link>
                            </div>
                        </div>

                        <div className="right section">
                            <Menu size='tiny'>
                                <Link to="/provider/warehouse">
                                    <Menu.Item name='Агуулах бүртгэл'/>
                                </Link>

                                <Link to="/provider/account">
                                    <Menu.Item name='Аккаунт'/>
                                </Link>

                                <Menu.Menu position='right' size='tiny'>
                                    <Menu.Item>
                                        <Button color="orange" onClick={() => this.logout()}>Системээс гарах</Button>
                                    </Menu.Item>
                                </Menu.Menu>
                            </Menu>
                        </div>

                    </div>
                </div>


                {this.props.children}

                <div className="provider-footer">
                    <div className="contact-section">
                        <div className="contact-left">Холбогдох:</div>
                        <div>
                            <p>
                                <a href={`tel:${this.props.settings.supportPhone}`}><i className="icon icon-phone"></i>&nbsp;+976 {this.props.settings.supportPhone}</a>
                            </p>
                            <p>
                                <i className="icon icon-envelop">&nbsp;</i>
                                <a href={`mailto:${this.props.settings.supportEmail}`}>{this.props.settings.supportEmail}</a>
                            </p>
                        </div>
                    </div>
                    <div className="copyright text-center">{this.props.settings.PRO_CONFIG_NAME} © {year}</div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(ProviderLayout)