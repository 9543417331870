import React from 'react';
import './top-product.scss';
import { Link } from 'react-router-dom';
import { ProductModel } from '../../entities/admin.product.model';
import NumberFormat from 'react-number-format';

interface ProductProps {
    product: ProductModel
}

class TopProductItem extends React.Component<ProductProps> {

    render() {
        return (
            <div className="top-product-item">
                <Link to={"/product/" + this.props.product.slug}>
                    <div className="product-image" style={{ backgroundImage: `url(${this.props.product.imageSmUrl})` }}></div>
                </Link>
                <Link to={"/product/" + this.props.product.slug}>
                    <div className="product-title">{this.props.product.shortName || this.props.product.name}</div>
                </Link>
                <div className="price-line">
                    <span className="price">
                        <NumberFormat displayType={'text'} value={ this.props.product.saleActive ? this.props.product.salePrice : this.props.product.price } suffix="₮" thousandSeparator={true}/>
                    </span>
                    <span className="price-per">{this.props.product.size && this.props.product.unit ? " / " + this.props.product.size + " " + this.props.product.unit : ""}</span>
                </div>
            </div>
        );
    }
}

export default TopProductItem;