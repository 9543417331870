import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { ClientModel } from '../../entities/client.model';

const mapStateToProps = (state: any) => {
  return {
    client: state.client
  }
}

interface ClientState {
  redirect: string
}

interface ClientProps {
  client: ClientModel
  children: any
}

const loginUrl = "/login";

class ClientGuard extends Component<ClientProps, ClientState> {
  constructor(props: any) {
    super(props)
    this.state = { redirect: "" }
  }

  componentDidMount() {
    var token = localStorage.getItem('clientToken')
    if (token && token !== 'undefined') {
    }
    else {
      this.setState({ redirect: loginUrl })
    }
  }

  componentDidUpdate() {
    if (!this.props.client || !this.props.client.id) {
      this.setState({ redirect: loginUrl })
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect}/>
    }
    return (
      <React.Fragment>
          {this.props.children}
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(ClientGuard);