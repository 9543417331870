export class ContentModel {
    id: any
    name: string = ''
    type: number = 2
    info: string = ''
    imageLgUrl: string = ''
    imageSmUrl: string = ''
    imageXsUrl: string = ''
    url: string = ''
    sequence: any
    active: boolean = true
}