import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Dimmer, Container, Loader, Table, Form, Button } from 'semantic-ui-react';
import { parseDeliveryStatus, formatDate } from '../../../utils/shared.utils';

function ReportDeliveries(props: any) {

  const history = useHistory()
  const [deliveries, setDeliveries] = useState<any[]>([])
  // const [page, setPage] = useState({ page: 1, size: 10, totalPages: 1 })
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const [fetched, setFetched] = useState(false)

  const today = new Date();
  const weekAfter = new Date();
  weekAfter.setDate(weekAfter.getDate() + 7);

  const [submitted, setSubmitted] = useState(false)
  const [startDate, setStartDate] = useState(formatDate(today))
  const [endDate, setEndDate] = useState(formatDate(weekAfter))

  useEffect(() => {
    setTimeout(() => {
      if (fetched) {
        return
      }
      fetchReport();
      setFetched(true) 
    });
  }, []);

  const fetchReport = () => {
    
    var token = localStorage.getItem('adminToken')
    if (token && token !== 'undefined') {
      if (!startDate) {
        setErrorMsg("Эхний өдөр оруулна уу")
        return;
      }
      if (!endDate) {
        setErrorMsg("Сүүлийн өдөр оруулна уу")
        return;
      }

      // fetch report data
      setLoading(true)
      setSubmitted(true)
      fetch(process.env.REACT_APP_CHEF_API + 'mongol/deliveries', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ startDate: startDate, endDate: endDate })
      }).then((res: any) => {
        setLoading(false)
        if (res.ok) {
          res.json().then((resJson: any) => {
            let data: any[] = resJson.data;
            for (let delivery of data) {
              let date = new Date(delivery.date)
              delivery.date = `${date.getMonth() + 1} сарын ${date.getUTCDate()}`
              delivery.showItems = true;
              delivery.orders.forEach((o: any) => o.showItems = true);
            }
            setDeliveries(data)
            // setPage(resJson.page)
          })
        }
      }).catch(err => {
        setLoading(false)
        setErrorMsg("Сүлжээний алдаа")
        console.error(err)
      })
    }
    else {
      window.alert("Та дахин нэвтэрнэ үү")
      history.push("/mongol/login")
    }
  }

  const toggleDelivery = (index: number) => {
    let copy = [... deliveries];
    copy[index].showItems = !copy[index].showItems;
    setDeliveries(copy);
  }

  const toggleOrder = (delIndex: number, orderIndex: number) => {
    let copy = [... deliveries];
    copy[delIndex].orders[orderIndex] = !copy[delIndex].orders[orderIndex];
    setDeliveries(copy);
  }

  const goToDetails = (order: any) => {
    history.push("/mongol/report/delivery/" + order.id)
  }

  // const onPageChange = (event: any, data: any) => {
  //     setPage(Object.assign(page, { page: data.activePage }))
  //     fetchOrders(data.activePage)
  //     window.scrollTo(0, 100)
  // }

  return (
    <div className="page margin-top-50-sm">
      <Container>
        <div className="flex-div width-100">
          <Dimmer active={loading} inverted>
            <Loader size='medium'></Loader>
          </Dimmer>
          <section className="client-orders" id="my-form">

            <div className="width-100 text-center"><h2>Хүргэлтийн тайлан</h2></div>

            <div className="report-filter flex-div margin-top-50">
              <Form.Input label="Эхний өдөр: " type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)}/>
              &nbsp;&nbsp;&nbsp;
              <Form.Input label="Сүүлийн өдөр: " type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)}/>
              &nbsp;&nbsp;&nbsp;
              <Button onClick={() => fetchReport()}>Хайх</Button>
            </div>

            <div className="error-msg width-100 text-center">{ errorMsg }</div>
            
            {deliveries?.map((del: any, delIndex: number) => {
              return <React.Fragment key={"delivery-" + delIndex}>
                <h3><strong>{del.date}</strong></h3>
                <Table width={16}>
                <Table.Body>
                  {/* <Table.Row style={{'backgroundColor': '#f9fafb'}}>
                    <Table.Cell colSpan={2} className="cursor" onClick={() => toggleDelivery(delIndex)}>
                        <strong>{del.date}</strong>
                    </Table.Cell>
                    <Table.Cell colSpan={6}></Table.Cell>
                    <Table.Cell className="cursor" onClick={() => toggleDelivery(delIndex)}>
                      { del.showItems ? <Icon name="minus"/> : <Icon name="plus"/> }
                    </Table.Cell>
                  </Table.Row> */}

                  <Table.Row>
                    <Table.Cell className="cursor" colSpan={3}><strong>Захиалга</strong></Table.Cell>
                    <Table.Cell colSpan={2}><strong>Хэрэглэгч</strong></Table.Cell>
                    <Table.Cell colSpan={2}><strong>Байгууллага</strong></Table.Cell>
                    <Table.Cell colSpan={2}><strong>Хаяг</strong></Table.Cell>
                    {/* <Table.Cell></Table.Cell> */}
                  </Table.Row>

                  {del.orders?.map((order: any, orderIndex: number) => {
                    return <React.Fragment key={del.date + "-" + orderIndex}>
                    <Table.Row>
                      <Table.Cell className="cursor">
                        <Link className="underline" to ={"/mongol/report/order/" + order.id}>
                          <strong>#{order.id}</strong>
                        </Link>
                      </Table.Cell>
                      <Table.Cell>
                        <strong>{order.token}</strong>
                      </Table.Cell>
                      <Table.Cell className={`cursor order-status ${String(order.status || '').toLowerCase()}`}>
                        {parseDeliveryStatus(order.status)}
                      </Table.Cell>
                      <Table.Cell colSpan={2}>
                        <Link className="underline" to={"/mongol/client/" + order.clientId}>{ order.ownName }</Link>
                      </Table.Cell>
                      <Table.Cell colSpan={2}>
                        <Link className="underline" to={"/mongol/company/" + order.companyId}>{ order.companyName }</Link>
                      </Table.Cell>
                      <Table.Cell colSpan={2}>
                        <span>{order.town}, {order.district}, {order.unit}, {order.street}</span>
                      </Table.Cell>
                      {/* <Table.Cell className="cursor"> */}
                        {/* { order.showItems ? <Icon name="minus"/> : <Icon name="plus"/> } */}
                      {/* </Table.Cell> */}
                    </Table.Row>
                    { order.showItems ? <React.Fragment>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell colSpan={5}><strong>Бүтээгдэхүүн</strong></Table.Cell>
                        <Table.Cell><strong>Тоо</strong></Table.Cell>
                        {/* <Table.Cell></Table.Cell> */}
                      </Table.Row>
                      {order.items?.map((item: any, itemIndex: number) => {
                        return <React.Fragment key={"order-item-" + item.id}>
                          <Table.Row>
                            <Table.Cell className="cursor">
                              {/* <strong>{itemIndex+1}.</strong> */}
                            </Table.Cell>
                            {/* <Table.Cell className={`cursor order-status ${String(order.status || '').toLowerCase()}`}>
                              {item.status}
                            </Table.Cell> */}
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell colSpan={5}>{ item.productName }</Table.Cell>
                            <Table.Cell> x { item.count }</Table.Cell>
                            {/* <Table.Cell></Table.Cell> */}
                          </Table.Row>
                        </React.Fragment>
                      })
                      }
                    </React.Fragment> : null
                    }
                    </React.Fragment>
                  })}
                </Table.Body>
                </Table>
                </React.Fragment>
                })
              }

            {/* <div className="flex-div margin-top-50">
              <Pagination defaultActivePage={1} value={page.page} totalPages={page.totalPages} onPageChange={onPageChange}></Pagination>
            </div> */}
          </section>
        </div>
      </Container>
    </div>
  );
}

export default ReportDeliveries;