import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Icon } from 'semantic-ui-react';

const Section = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const MyForm = styled.div`
    max-width: 600px;
    margin-top: 40px;
    color: #fff!important;
    width: 100%;
    max-width: 350px;
`;

const FormGroup = styled.div`
    margin-top: 20px;
    width: 100%;
`;

const ForgotLine = styled.div`
    padding: 10px;
    margin-top: 10px;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
`;

interface LoginProps {

}

interface LoginState {
    redirect: string,
    errorMsg: string,
    email: string,
    password: string,
    loading: boolean
}

class AdminLoginComponent extends React.Component<LoginProps, LoginState> {
    constructor(props: any) {
        super(props);
        this.state = {
            redirect: "",
            errorMsg: "",
            email: "",
            password: "",
            loading: false
        }
        this.submit = this.submit.bind(this);
        this.onUsernameChange = this.onUsernameChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
    }

    onUsernameChange(e?: any) {
        this.setState({ email: e.target.value });
    }

    onPasswordChange(e?: any) {
        this.setState({ password: e.target.value });
    }

    submit = () => {
        if (!this.state.email) {
            this.setState({ errorMsg: "Та хэрэглэгчийн нэр оруулна уу" })
            this.scrollToForm()
            return;
        }

        if (!this.state.password) {
            this.setState({ errorMsg: "Та нууц үг оруулна уу" })
            this.scrollToForm()
            return;
        }

        this.setState({ errorMsg: "", loading: true })

        fetch(process.env.REACT_APP_CHEF_API + 'mongol-login', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: this.state.email, password: this.state.password })
        })
        .then((res: any) => {
            if (!res.ok) {
                res.json().then((resJson: any) => {
                    this.setState({ errorMsg: resJson.errorMsg, loading: false}, this.scrollToForm)
                })
            } else {
                res.json().then((resJson: any) => {
                    localStorage.setItem('adminToken', resJson.token)
                    this.setState({ redirect: "/mongol/dashboard" })
                })
            }
            this.setState({ loading: false })
        })
        .catch((err: any) => {
            console.error(err)
            this.setState({ errorMsg: "Сүлжээнд алдаа гарлаа", loading: false })
        })
    }

    scrollToForm = () => {
        let element = document.getElementById("login-form");
        if (element) {
            element.scrollIntoView();
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <div className="page login-page margin-top-50">
                <Section>
                    <div className="logo-text">
                        <div className="line1">CHEF</div>
                        <div className="line2">Professional</div>
                        <div className="line3">Mongol</div>
                    </div>
                </Section>

                <div className="flex-div">
                    <MyForm id="login-form">
                        { this.state.errorMsg ? 
                            <FormGroup>
                                <Icon name="warning sign" color='red'/>
                            {this.state.errorMsg}
                            </FormGroup> : null
                        }
                        <FormGroup>
                            <div className="width-100 ui left icon input">
                                <i className="user icon"></i>
                                <input type="email" className="width-100" name="email" value={this.state.email} onChange={this.onUsernameChange} placeholder="Имэйл" />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <div className="width-100 ui left icon input">
                                <i className="lock icon"></i>
                                <input className="width-100" type="password" name="password" value={this.state.password} onChange={this.onPasswordChange} placeholder="Нууц үг" />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Button className="ui fluid large red submit button" disabled={this.state.loading} onClick={this.submit}>Нэвтрэх</Button>
                        </FormGroup>
                        <FormGroup>
                            <Link to="/mongol-forgot-password">
                                <ForgotLine>Шинэ нууц үг үүсгэх</ForgotLine>
                            </Link>
                        </FormGroup>
                    </MyForm>
                </div>
            </div>
        );
    }
}

export default AdminLoginComponent;