import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import store from '../store';
import { ProviderUserModel } from '../entities/provider.user.model';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => {
  return {
    provider: state.provider
  }
}

interface ProviderState {
  redirect: string
}

interface ProviderProps {
  provider: ProviderUserModel
  children: any
}

const loginUrl = "/provider-login";

class ProviderComponent extends Component<ProviderProps, ProviderState> {
  constructor(props: any) {
    super(props)
    this.state = { redirect: "" }
  }

  public triedSettings = 1;

  componentDidMount() {
    var token = localStorage.getItem('providerToken')
    if (token && token !== 'undefined') {
      this.getProviderUser(token);
      this.fetchSettings();
    }
    else {
      this.setState({ redirect: loginUrl })
    }
  }

  componentDidUpdate() {
    if (!this.props.provider || !this.props.provider.id) {
      console.log("provider logout")
      this.setState({ redirect: loginUrl })
    }
  }

  async getProviderUser(token: string) {
    fetch(process.env.REACT_APP_CHEF_API + 'provider', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    })
    .then((res: any) => {
        if (res.ok) {
            res.json().then((resJson: any) => {
                store.dispatch({
                    type: 'authenticateProvider',
                    provider: resJson.user
                })
            })
        }
        else {
          this.setState({ redirect: loginUrl })
        }
    })
    .catch((err: any) => {
        console.error(err)
    })
  }

  async fetchSettings() {
    fetch(process.env.REACT_APP_CHEF_API + "settings")
    .then((response: any) => {
      response.json().then((res: any) => {
        if (!res || !res.data) {
          return;
        }
        store.dispatch({
          type: "settings",
          data: res.data
        })
      })
    })
    .catch((err: any) => {
      console.error(err)
      if (this.triedSettings < 4) {
        this.triedSettings++;
        this.fetchSettings()
      }
    })
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect}/>
    }
    return (
      <React.Fragment>
          {this.props.children}
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(ProviderComponent);