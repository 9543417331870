import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import './style/global.scss';
import './style/icomoon/style.css';
import 'semantic-ui-css/components/icon.css'
import 'semantic-ui-css/components/button.css'
import 'semantic-ui-css/components/label.css'
import MainLayout from './layouts/main/layout';
import Home from './components/home/home';
import FooterLayout from './layouts/footer/footer';
import CategoryPage from './components/category-page/category-page';
import Terms from './components/terms/terms';
import BrandsPage from './components/brands/brands';
import BrandPage from './components/brand-page/brand-page';
import SearchPage from './components/search-page/search-page';
import ProductPage from './components/product-page/product-page';
import NoContent from './components/no-content/no-content';
import AdminPage from './admin/admin.component';
import ClientLoginComponent from './components/authentication/login';
import ClientSignupComponent from './components/authentication/signup';
import AdminLayout from './admin/components/layout/admin.layout';
import AdminDashboardComponent from './admin/components/dashboard/dashboard.component';
import CategoryForm from './admin/components/forms/category.form';
import BrandForm from './admin/components/forms/brand.form';
import ContentForm from './admin/components/forms/content.form';
import ProviderForm from './admin/components/forms/provider.form';
import CategoryList from './admin/components/list/category.list';
import BrandList from './admin/components/list/brand.list';
import ProviderList from './admin/components/list/provider.list';
import ContentList from './admin/components/list/content.list';
import BannerList from './admin/components/list/banner.list';
import ReportOrders from './admin/components/reports/order.report';
import ProductForm from './admin/components/forms/product.form';
import AdminProductList from './admin/components/list/product.list';
import ClientForgotPasswordComponent from './components/authentication/forgot.password';
import ClientChangePasswordComponent from './components/authentication/change.password';
import ClientAccountPage from './components/client/account'
import CompanyDetailsPage from './components/client/company.details'
import CompanyUsersPage from './components/client/company.users'
import SavedProductsPage from './components/client/saved-products'
import AdminChangePasswordComponent from './admin/components/authentication/change.password';
import AdminForgotPasswordComponent from './admin/components/authentication/forgot.password';
import AdminLoginComponent from './admin/components/authentication/login';
import ProviderForgotPasswordComponent from './provider/components/authentication/forgot.password';
import ProviderChangePasswordComponent from './provider/components/authentication/change.password';
import ClientList from './admin/components/list/client.list';
import ClientForm from './admin/components/forms/client.form';
import ProviderUserList from './admin/components/list/provider.user.list';
import ProviderUserForm from './admin/components/forms/provider.user.form';
import ProviderLoginComponent from './provider/components/authentication/login';
import ProviderComponent from './provider/provider.component';
import ProviderProductsComponent from './provider/components/products';
import WarehouseComponent from './provider/components/warehouse';
import ClientGuard from './components/authentication/client.guard';
import ShopComponent from './components/shop/shop.component';
import ClientComponent from './components/authentication/client.component';
import BannerForm from './admin/components/forms/banner.form';
import NewEmployee from './components/client/new-employee';
import CompanyRoleActionPage from './components/client/company.role.action'
import CompanyList from './admin/components/list/company.list';
import CompanyForm from './admin/components/forms/company.form';
import DeliveryPage from './components/checkout/delivery';
import SettingsList from './admin/components/list/settings.list';
import PaymentPage from './components/payment/payment.component';
import SettingsForm from './admin/components/forms/settings.form';
import ClientOrdersPage from './components/client/client.orders';
import ConfirmedOrderPage from './components/client-order/client.order.confirmed';
import OrderComponent from './admin/components/order/order.component';
import ClientOrderComponent from './components/client-order/client.order.component';
import ContactPage from './components/contact/contact';
import CompanyOrdersPage from './components/client/company.orders';
import ProviderLayout from './provider/layout/provider.layout';
import ProviderAccountPage from './provider/components/account';
import ReportDeliveries from './admin/components/reports/delivery.report';
import ReportPayments from './admin/components/reports/payment.report';
import WarehouseActionsComponent from './provider/components/warehouse.actions';
import DayOffComponent from './admin/components/delivery/dayoff.component';
import {Helmet} from 'react-helmet';

// const dotenv = require('dotenv');
// dotenv.config();

class App extends React.Component {

  render() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Router>
        <div className="App">
          <Helmet>
              <meta charSet="utf-8" />
              <title>{process.env.REACT_APP_LOGO_NAME1} {process.env.REACT_APP_LOGO_NAME2}</title>
              <meta name="description" content={process.env.REACT_APP_DESCRIPTION}/>
              <meta name="og:title" content={process.env.REACT_APP_LOGO_NAME1 + ' ' + process.env.REACT_APP_LOGO_NAME2}/>
              <meta name="og:description" content={process.env.REACT_APP_DESCRIPTION}/>
              <meta property="og:type" content="website"/>
          </Helmet>
          <div className="app-container">
            <Switch>
              {/* admin routes */}
              <Route path='/mongol'>
                <AdminPage>
                  <AdminLayout>
                    <Route path="/mongol" exact={true} component={AdminDashboardComponent}/>
                    <Route path="/mongol/dashboard" exact={true} component={AdminDashboardComponent}/>
                    <Route path="/mongol/categorys" exact={true} component={CategoryList}/>
                    <Route path="/mongol/categories" exact={true} component={CategoryList}/>
                    <Route path="/mongol/categories/new" exact={true} component={CategoryForm}/>
                    <Route path="/mongol/category/:id" exact={true} component={CategoryForm}/>
                    <Route path="/mongol/brands" exact={true} component={BrandList}/>
                    <Route path="/mongol/brands/new" exact={true} component={BrandForm}/>
                    <Route path="/mongol/brand/:id" exact={true} component={BrandForm}/>
                    <Route path="/mongol/contents" exact={true} component={ContentList}/>
                    <Route path="/mongol/contents/new" exact={true} component={ContentForm}/>
                    <Route path="/mongol/content/:id" exact={true} component={ContentForm}/>
                    <Route path="/mongol/banners" exact={true} component={BannerList}/>
                    <Route path="/mongol/banners/new" exact={true} component={BannerForm}/>
                    <Route path="/mongol/banner/:id" exact={true} component={BannerForm}/>
                    <Route path="/mongol/providers/" exact={true} component={ProviderList}/>
                    <Route path="/mongol/providers/new" exact={true} component={ProviderForm}/>
                    <Route path="/mongol/provider/:id" exact={true} component={ProviderForm}/>
                    <Route path="/mongol/products/" exact={true} component={AdminProductList}/>
                    <Route path="/mongol/products/new" exact={true} component={ProductForm}/>
                    <Route path="/mongol/product/:id" exact={true} component={ProductForm}/>
                    <Route path="/mongol/clients" exact={true} component={ClientList}/>
                    <Route path="/mongol/client/:id" exact={true} component={ClientForm}/>
                    <Route path="/mongol/companys" exact={true} component={ClientList}/>
                    <Route path="/mongol/companies" exact={true} component={CompanyList}/>
                    <Route path="/mongol/companies/new" exact={true} component={CompanyForm}/>
                    <Route path="/mongol/company/:id" exact={true} component={CompanyForm}/>
                    <Route path="/mongol/provider-users" exact={true} component={ProviderUserList}/>
                    <Route path="/mongol/provider-user/:id" exact={true} component={ProviderUserForm}/>
                    <Route path="/mongol/settings" exact={true} component={SettingsList}/>
                    <Route path="/mongol/settings/:id" exact={true} component={SettingsForm}/>
                    <Route path="/mongol/report/orders" exact={true} component={ReportOrders}/>
                    <Route path='/mongol/report/order/:orderId' exact={true} component={OrderComponent}/>
                    <Route path="/mongol/report/delivery" exact={true} component={ReportDeliveries}/>
                    <Route path="/mongol/report/payments" exact={true} component={ReportPayments}/>
                    <Route path="/mongol/dayoff" exact={true} component={DayOffComponent}/>
                  </AdminLayout>
                </AdminPage>
              </Route>
              <Route exact path="/mongol-login" component={AdminLoginComponent}/>
              <Route exact path="/mongol-forgot-password" component={AdminForgotPasswordComponent}/>
              <Route exact path="/mongol-change-password/:forgotKey" component={AdminChangePasswordComponent}/>
              <Route exact path="/company-role/:action/:hash" component={CompanyRoleActionPage}/>
              {/* provider routes */}
              <Route exact path="/provider-login" component={ProviderLoginComponent}/>
              <Route path='/provider-forgot-password' component={ProviderForgotPasswordComponent}/>
              <Route path='/provider-change-password/:forgotKey' exact={true} component={ProviderChangePasswordComponent}/>
              <Route path="/provider">
                <ProviderComponent>
                  <ProviderLayout>
                    <Route path="/provider" exact={true} component={ProviderProductsComponent}/>
                    <Route path="/provider/account" exact={true} component={ProviderAccountPage}/>
                    <Route path="/provider/warehouse/:id" exact={true} component={WarehouseComponent}/>
                    <Route path="/provider/warehouse-actions/:id" exact={true} component={WarehouseActionsComponent}/>
                    <Route path="/provider/warehouse" exact={true} component={ProviderProductsComponent}/>
                    <Route path="/provider/products" exact={true} component={ProviderProductsComponent}/>
                  </ProviderLayout>
                </ProviderComponent>
              </Route>
              {/* below routes have layout */}
              <Route path='/'>
                <ShopComponent>
                  <MainLayout></MainLayout>
              
                  {/* public routes */}
                  <ClientComponent>
                    <Route exact path='/' component={Home}/>
                    <Route exact path='/category/:slug' component={CategoryPage}/>
                    <Route exact path='/category/:parentSlug/:slug' component={CategoryPage}/>
                    <Route exact path='/brands' component={BrandsPage}/>
                    <Route exact path='/brand/:slug' component={BrandPage}/>
                    <Route exact path='/product/:slug' component={ProductPage}/>
                    <Route path='/search' component={SearchPage}/>
                    <Route path='/sale' component={SearchPage}/>
                    <Route path='/login' component={ClientLoginComponent}/>
                    <Route path='/signup' component={ClientSignupComponent}/>
                    <Route path='/forgot-password' component={ClientForgotPasswordComponent}/>
                    <Route path='/change-password/:forgotKey' exact={true} component={ClientChangePasswordComponent}/>
                    {/* client routes */}
                    <Route path="/client">
                      <ClientGuard>
                        <Route path='/client/checkout' exact={true} component={DeliveryPage}/>
                        <Route path='/client/payment/:token' exact={true} component={PaymentPage}/>
                        <Route path='/client/order/success/:token' exact={true} component={ConfirmedOrderPage}/>
                        <Route path='/client/order/:token' exact={true} component={ClientOrderComponent}/>
                        <Route path='/client/orders' exact={true} component={ClientOrdersPage}/>
                        <Route path='/client/saved-products' exact={true} component={SavedProductsPage}/>
                        {/* client account */}
                        <Route path='/client/account' exact={true} component={ClientAccountPage}/>
                        <Route path='/client/company' exact={true} component={CompanyDetailsPage}/>
                        <Route path='/client/company/new-employee' exact={true} component={NewEmployee}/>
                        <Route path='/client/company/users' exact={true} component={CompanyUsersPage}/>
                        <Route path='/client/company/orders' exact={true} component={CompanyOrdersPage}/>
                      </ClientGuard>
                    </Route>
                  </ClientComponent>
                  {/* default routes */}
                  <Route exact path='/terms' component={Terms}/>
                  <Route exact path='/contact' component={ContactPage}/>
                  <Route path='/no-content' component={NoContent}/>
                  <FooterLayout></FooterLayout>
                </ShopComponent>
              </Route>
              <Redirect to="/no-content"/>
            </Switch>
          </div>
        </div>
      </Router>
    </Suspense>
  );
  }
}

export default App;