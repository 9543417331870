import React from 'react';
import { Form, Container, Icon, Dimmer, Loader, Label, Button } from 'semantic-ui-react';
import MyForm from './form';
import ImageOnS3 from '../../../components/imageOnS3/imageOnS3.component';
import { ImageModel } from '../../../entities/image.model';
import { BrandModel } from '../../../entities/brand.model';
import MyFormState from './MyFormState';
import { Redirect } from 'react-router-dom';

interface FormProps {
    match: any,
    history: any
}

class BrandForm extends MyForm<FormProps, MyFormState> {
    entity = 'brand'

    constructor(props: any) {
        super(props);
        this.state = {
            model: new BrandModel(),
            successMsg: "",
            errorMsg: "",
            loading: true,
            editing: false,
            editingSlug: false,
            slugValid: true,
            imageObj: ImageModel.empty(),
            imageObjs: { lg: ImageModel.empty(), sm: ImageModel.empty(), xs: ImageModel.empty(),
                image1: ImageModel.empty(), image2: ImageModel.empty(), image3: ImageModel.empty(),
                image4: ImageModel.empty(), image5: ImageModel.empty(), image6: ImageModel.empty()
            },
            redirect: "",
        }
    }

    validate(): boolean {
        this.setState({ successMsg: ""});
        if (!this.state.model.name) {
            this.setState({ errorMsg: "Брэндийн нэрийг оруулна уу." });
            return false;
        }
        if (this.state.editingSlug || !this.state.slugValid || !this.state.model.slug) {
            this.setState({ errorMsg: "URL нэрийг шалгана уу." });
            return false;
        }
        if (!this.state.imageObjs.lg.file && !this.state.model.imageLgUrl) {
            this.setState({ errorMsg: "Том хэмжээтэй зураг оруулна уу" });
            return false;
        }
        if (!this.state.imageObjs.sm.file && !this.state.model.imageSmUrl) {
            this.setState({ errorMsg: "Дунд хэмжээтэй зураг оруулна уу" });
            return false;
        }
        if (!this.state.imageObjs.xs.file && !this.state.model.imageXsUrl) {
            this.setState({ errorMsg: "Жижиг хэмжээтэй зураг оруулна уу" });
            return false;
        }
        if (this.state.model.id !== undefined) {
            if (this.state.model.name === this.originalModel.name 
                && this.state.model.slug === this.originalModel.slug
                && this.state.model.info === this.originalModel.info
                && this.state.imageObjs.lg.name === this.originalModel.imageLgUrl
                && this.state.imageObjs.sm.name === this.originalModel.imageSmUrl
                && this.state.imageObjs.xs.name === this.originalModel.imageXsUrl
                && this.state.model.active === this.originalModel.active) {
                    this.setState({ errorMsg: "Өөрчлөлт байхгүй байна"});
                    return false;
                }
        }
        this.setState({ errorMsg: "" });
        return true;
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }
        return (
            <Container>
                <Dimmer active={this.state.loading} inverted>
                    <Loader size='medium'></Loader>
                </Dimmer>
                <h2>{!this.state.editing ? 'Шинэ' : ''} Брэнд</h2>
                <Form>
                    <Form.Group>
                        { this.state.successMsg ? <div className="success-msg">{this.state.successMsg}</div> : null }
                        { this.state.errorMsg ? <div className="error-msg">{this.state.errorMsg}</div> : null }
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Input required label='Брэндийн нэр' value={this.state.model.name || ""}
                            onBlur={this.onNameBlur}
                            onChange={this.onNameChange} />
                    </Form.Group>
                    <Form.Group>
                        <div className="required field">
                            <label className="required">URL нэр</label>
                            { this.state.editingSlug ?
                            <React.Fragment>
                                <div className="flex-start">
                                    <Form.Input required value={this.state.model.slug}
                                        onBlur={this.onSlugBlur}
                                        onChange={this.onSlugChange} />
                                    { this.state.slugValid ? null :
                                        <React.Fragment>
                                            <Icon name="warning circle" color='red' size='large'/>
                                            <span className="error-msg">URL нэр ашиглагдсан байна</span>
                                        </React.Fragment>
                                    }
                                    &nbsp;&nbsp;
                                    <Button className="cursor" color='orange'
                                        disabled={this.originalModel.slug === this.state.model.slug}
                                        onClick={this.postNewSlug}>Шалгах</Button>
                                    <Button className="cursor" onClick={this.cancelSlugChange}>Болих</Button>
                                </div>
                                <div>
                                    <br/>
                                    <Icon name="info circle" color="blue" />
                                    Зөвхөн англи үсэг болон - тэмдэг ашиглана
                                </div>
                            </React.Fragment>
                            :
                            <div>
                                <Label>{`${process.env.REACT_APP_DOMAIN}/brand/${this.state.model.slug}`}</Label>
                                { this.state.slugValid ?
                                    (this.state.model.slug && this.state.model.slug.length > 0) ?
                                        <Icon name="check circle" color='green' size='large'/> : null :
                                    <Icon name="warning circle" color='red' size='large'/>
                                }
                                &nbsp;&nbsp;
                                <Icon className="cursor" name="edit outline" size='large' onClick={this.toggleSlug}/>
                            </div>
                        }
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Form.TextArea label='Тайлбар' width="16" value={this.state.model.info} onChange={this.onInfoChange} />
                    </Form.Group>
                    <Form.Group className="flex-space-between">
                        <div className="field">
                            <label>Том хэмжээтэй зураг (1280 x 320 pixel)(4:1)</label>
                        </div>
                        <div className={this.state.imageObjs.lg.uploaded && this.state.imageObjs.lg.showOriginal ? "flex-space-between" : "hidden-div"}>
                            <ImageOnS3 imgKey={this.state.model.imageLgUrl} width='160px' height='40px'/>
                            <Icon className="cursor margin-10" name="trash" size='large' onClick={() => this.toggleImage('imageLgUrl')} />
                        </div>
                        <Icon className={this.state.imageObjs.lg.uploaded && !this.state.imageObjs.lg.showOriginal ? "" : "hidden-div"} name='redo' size='large' onClick={() => this.toggleImage('imageLgUrl')}/>
                        <Form.Input control='input' type="file" accept="image/png, image/jpeg" onChange={this.handleImageLgChange} />
                    </Form.Group>
                    <Form.Group className="flex-space-between">
                        <div className="field">
                            <label>Дунд хэмжээтэй зураг (800 x 200 pixel)(4:1)</label>
                        </div>
                        <div className={this.state.imageObjs.sm.uploaded && this.state.imageObjs.sm.showOriginal ? "flex-space-between" : "hidden-div"}>
                            <ImageOnS3 imgKey={this.state.model.imageSmUrl} width='160px' height='40px'/>
                            <Icon className="cursor margin-10" name="trash" size='large' onClick={() => this.toggleImage('imageSmUrl')} />
                        </div>
                        <Icon className={this.state.imageObjs.sm.uploaded && !this.state.imageObjs.sm.showOriginal ? "" : "hidden-div"} name='redo' size='large' onClick={() => this.toggleImage('imageSmUrl')}/>
                        <Form.Field control='input' type="file" accept="image/png, image/jpeg" onChange={this.handleImageSmChange} />
                    </Form.Group>
                    <Form.Group className="flex-space-between">
                        <div className="field">
                            <label>Жижиг хэмжээтэй зураг (240 x 240 pixel)(1:1)</label>
                        </div>
                        <div className={this.state.imageObjs.xs.uploaded && this.state.imageObjs.xs.showOriginal ? "flex-space-between" : "hidden-div"}>
                            <ImageOnS3 imgKey={this.state.model.imageXsUrl} width="40px" height="40px"/>
                            <Icon className="cursor margin-10" name="trash" size='large' onClick={() => this.toggleImage('imageXsUrl')} />
                        </div>
                        <Icon className={this.state.imageObjs.xs.uploaded && !this.state.imageObjs.xs.showOriginal ? "" : "hidden-div"} name='redo' size='large' onClick={() => this.toggleImage('imageXsUrl')}/>
                        <Form.Field control='input' type="file" accept="image/png, image/jpeg" onChange={this.handleImageXsChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Checkbox checked={this.state.model.active} onChange={this.onActiveChange} label='Идэвхитэй' />
                    </Form.Group>
                    <br/>
                    <Form.Button disabled={this.state.loading} onClick={() => this.save()}>Хадгалах</Form.Button>
                </Form>
            </Container>
        );
    }
}

export default BrandForm;