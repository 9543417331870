import React from 'react';
import { ImageModel } from '../../../entities/image.model';
import S3Manager from '../s3/s3manager';
import MyFormState from './MyFormState';
import { Redirect } from 'react-router-dom';

interface MyFormProps {
    match: any,
    history: any
}

// class MyForm extends React.Component<FormProps, FormState> {
class MyForm<GProps extends MyFormProps, GState extends MyFormState> extends React.Component<GProps, GState> {
    loginUrl = "/mongol-login"
    entity: string = "";
    navigationEntity: string = "";
    originalModel: any = {};// an exising object from the database in case of update action
    imagesUploaded: string[] = [];
    imagesToDelete: string[] = [];
    recordSaved = false;// if the current data form is saved successfully to DB
    imageNamePrefix: string = "";
    updatedSlugManually = false;

    constructor(props: any) {
        super(props);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleImageLgChange = this.handleImageLgChange.bind(this);
        this.handleImageSmChange = this.handleImageSmChange.bind(this);
        this.handleImageXsChange = this.handleImageXsChange.bind(this);
        this.handleImage1Change = this.handleImage1Change.bind(this);
        this.handleImage2Change = this.handleImage2Change.bind(this);
        this.handleImage3Change = this.handleImage3Change.bind(this);
        this.handleImage4Change = this.handleImage4Change.bind(this);
        this.handleImage5Change = this.handleImage5Change.bind(this);
        this.handleImage6Change = this.handleImage6Change.bind(this);

        this.onNameBlur = this.onNameBlur.bind(this);
        this.onSlugBlur = this.onSlugBlur.bind(this);
        this.onSlugChange = this.onSlugChange.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onShortNameChange = this.onShortNameChange.bind(this);
        this.onPriceChange = this.onPriceChange.bind(this);
        this.onSizeChange = this.onSizeChange.bind(this);
        this.onInfoChange = this.onInfoChange.bind(this);
        this.onActiveChange = this.onActiveChange.bind(this);
        this.onUrlChange = this.onUrlChange.bind(this);
        this.onTypeChange = this.onTypeChange.bind(this);

        this.onApprovedChange = this.onApprovedChange.bind(this);
        this.onRegisterChange = this.onRegisterChange.bind(this);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.onPhoneChange = this.onPhoneChange.bind(this);
        this.onContactNameChange = this.onContactNameChange.bind(this);
        this.onContactEmailChange = this.onContactEmailChange.bind(this);
        this.onContactPhoneChange = this.onContactPhoneChange.bind(this);
        this.onContactRoleChange = this.onContactRoleChange.bind(this);
        this.onTownChange = this.onTownChange.bind(this);
        this.onDistrictChange = this.onDistrictChange.bind(this);
        this.onAddressUnitChange = this.onAddressUnitChange.bind(this);
        this.onStreetChange = this.onStreetChange.bind(this);
        this.onZipCodeChange = this.onZipCodeChange.bind(this);
    }

    componentDidMount() {
        var token = this.getAdminToken()
        if (this.props.match.params.id) {
            this.setState({ loading: true });
            let url = `${process.env.REACT_APP_CHEF_API}get/${this.entity}/${this.props.match.params.id}`;
            fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': token,
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                if (res.status === 401) {
                    this.setState({ redirect: this.loginUrl})
                } else {
                    res.json().then(res => {
                        if (res.data && res.data.length > 0) {
                            let model = res.data[0];
                            if (model.active !== undefined) {
                                model.active = model.active === 1;
                            }
                            if (model.approved !== undefined) {
                                model.approved = model.approved === 1;
                            }
                            if (model.canPayLater !== undefined) {
                                model.canPayLater = model.canPayLater === 1;
                            }
                            if (model.isFinal !== undefined) {
                                model.isFinal = model.isFinal === 1;
                            }
                            // prepare image objs
                            if (model.imageLgUrl) {
                                this.state.imageObjs.lg = new ImageModel(undefined, model.imageLgUrl, true, true);
                            }
                            if (model.imageSmUrl) {
                                this.state.imageObjs.sm = new ImageModel(undefined, model.imageSmUrl, true, true);
                            }
                            if (model.imageXsUrl) {
                                this.state.imageObjs.xs = new ImageModel(undefined, model.imageXsUrl, true, true);
                            }
                            if (model.image1) {
                                this.state.imageObjs.image1 = new ImageModel(undefined, model.image1, true, true);
                            }
                            if (model.image2) {
                                this.state.imageObjs.image2 = new ImageModel(undefined, model.image2, true, true);
                            }
                            if (model.image3) {
                                this.state.imageObjs.image3 = new ImageModel(undefined, model.image3, true, true);
                            }
                            this.imageNamePrefix = this.convertText(String(model.name)).toLowerCase().replace(/[^a-z ]/g, "").trim();
                            this.imageNamePrefix = this.imageNamePrefix.split("  ").join(" ");
                            this.imageNamePrefix = this.imageNamePrefix.split(" ").join("-");
                            this.setState({ model: model, 
                                loading: false, 
                                editing: true,
                                imageObjs: this.state.imageObjs,
                                imageObj: new ImageModel(undefined, model.imageUrl, (model.imageUrl) ? true : false, model.imageUrl !== undefined) 
                            });
                            this.originalModel = Object.assign({}, model);
                        }
                    })
                }
            })
            .catch(err => console.error(err))
            .finally(() => {
                this.setState({ loading: false });
            });
        } else {
            this.setState({ loading: false });
        }
    }

    componentWillUnmount() {
        if (!this.recordSaved) {
            // remove images that are uploaded but not saved
            this.imagesUploaded.forEach(imgUrl => {
                S3Manager.deleteImage(imgUrl);
            });
            this.imagesUploaded = [];
            this.imagesToDelete = [];
        }
    }

    onSlugChange(e?: any) {
        let model: any = this.state.model;
        model.slug = e.target.value;
        this.setState({ model: model });
        this.updatedSlugManually = true;
    }

    onNameBlur() {
        if (this.state.model.hasOwnProperty('slug') && (!this.state.editing && !this.updatedSlugManually)) {
            if (this.state.model.slug) {
                this.checkSlug().then(res => {
                    if (res.status === 200) {
                        this.setState({ slugValid: true, editingSlug: false, errorMsg: "" })
                    } else if (res.status === 409) {
                        this.setState({ slugValid: false, errorMsg: "" })
                    } else {
                        this.setState({ errorMsg: "Сүлжээний алдаа", editingSlug: false })
                    }
                })
            }
            else {
                this.setState({ slugValid: false })
            }
        }
    }

    onSlugBlur() {
        let model: any = this.state.model;
        model.slug = model.slug.toLowerCase().replace(/[^a-z ]/g, "");
        if (model.slug) {
            this.setState({ model: model }, () => {
                this.checkSlug().then(res => {
                    if (res.status === 200) {
                        this.setState({ slugValid: true, editingSlug: false, errorMsg: "" })
                    } else if (res.status === 409) {
                        this.setState({ slugValid: false, errorMsg: "" })
                    } else {
                        this.setState({ errorMsg: "Сүлжээний алдаа", editingSlug: false })
                    }
                })
            })
        }
        else {
            this.setState({ slugValid: false })
        }
    }

    onNameChange(e?: any) {
        let model: any = this.state.model;
        model.name = e.target.value;
        if (this.state.model.id === undefined || this.state.model.id === null) {
            if (this.state.model.hasOwnProperty("slug") && (!this.updatedSlugManually || !this.state.model.slug)) {
                model.slug = this.convertText(model.name.toLowerCase()).replace(/[^a-z ]/g, "").trim()
                model.slug = model.slug.split("  ").join(" ")
                model.slug = model.slug.split(" ").join("-")
            }
        }
        this.imageNamePrefix = this.convertText(String(model.name)).toLowerCase().replace(/[^a-z ]/g, "").trim();
        this.imageNamePrefix = this.imageNamePrefix.split("  ").join(" ");
        this.imageNamePrefix = this.imageNamePrefix.split(" ").join("-");
        this.setState({ model: model });
    }

    onShortNameChange(e?: any) {
        let model: any = this.state.model;
        model.shortName = e.target.value;
        this.setState({ model: model });
    }

    onSizeChange(e?: any) {
        let model: any = this.state.model;
        model.size = Number(e.target.value);
        this.setState({ model: model });
    }

    onPriceChange(e?: any) {
        let model: any = this.state.model;
        model.price = Number(e.target.value);
        this.setState({ model: model });
    }

    onInfoChange(e?: any) {
        let model: any = this.state.model;
        model.info = e.target.value;
        this.setState({ model: model });
    }

    onActiveChange(e?: any) {
        let model: any = this.state.model;
        model.active = Boolean(e.target.checked);
        this.setState({ model: model });
    }

    onUrlChange(e?: any) {
        let model: any = this.state.model;
        model.url = e.target.value
        this.setState({ model: model });
    }

    onTypeChange(e: any, drop: any) {
        let model: any = this.state.model;
        model.type = drop.value;
        this.setState({ model: model });
    }

    onRegisterChange(e?: any, v?: any) {
        let model: any = this.state.model;
        model.register = e.target.value;
        this.setState({ model: model });
    }

    onEmailChange(e?: any, v?: any) {
        let model: any = this.state.model;
        model.email = e.target.value;
        this.setState({ model: model });
    }

    onPhoneChange(e?: any, v?: any) {
        let model: any = this.state.model;
        model.phone = e.target.value;
        this.setState({ model: model });
    }

    onContactNameChange(e?: any, v?: any) {
        let model: any = this.state.model;
        model.contactName = e.target.value;
        this.setState({ model: model });
    }

    onContactPhoneChange(e?: any, v?: any) {
        let model: any = this.state.model;
        model.contactPhone = e.target.value;
        this.setState({ model: model });
    }

    onContactEmailChange(e?: any, v?: any) {
        let model: any = this.state.model;
        model.contactEmail = e.target.value;
        this.setState({ model: model });
    }

    onContactRoleChange(e?: any, v?: any) {
        let model: any = this.state.model;
        model.contactRole = e.target.value;
        this.setState({ model: model });
    }

    onTownChange(e?: any) {
        let model: any = this.state.model;
        model.town = e.target.value;
        this.setState({ model: model });
    }

    onDistrictChange(e?: any) {
        let model: any = this.state.model;
        model.district = e.target.value;
        this.setState({ model: model });
    }

    onAddressUnitChange(e?: any) {
        let model: any = this.state.model;
        model.unit = e.target.value;
        this.setState({ model: model });
    }

    onStreetChange(e?: any) {
        let model: any = this.state.model;
        model.street = e.target.value;
        this.setState({ model: model });
    }

    onZipCodeChange(e?: any) {
        let model: any = this.state.model;
        model.zipcode = e.target.value;
        this.setState({ model: model });
    }

    onApprovedChange(e?: any) {
        let model: any = this.state.model;
        model.approved = Boolean(e.target.checked);
        this.setState({ model: model });
    }

    public handleImageChange(event: any) {
        const { target: { value, files } } = event;
        const fileForUpload = files[0];
        // add previously uploaded image to removing list
        if (this.state.imageObj.uploaded) {
            this.imagesToDelete.push(this.state.imageObj.name);
        }
        let imageObj = ImageModel.empty();
        if (fileForUpload) {
            let ext = fileForUpload.name.split('.').pop();

            imageObj.name = `${this.entity}/${this.imageNamePrefix|| ""}-lg-${new Date().toISOString()}.${ext}`;
            imageObj.file = fileForUpload;
        }
        this.setState({ imageObj: imageObj });
    }

    public handleImageLgChange(event: any) {
        const { target: { value, files } } = event;
        const fileForUpload = files[0];
        // add previously uploaded image to removing list
        if (this.state.imageObjs.lg.uploaded) {
            this.imagesToDelete.push(this.state.imageObjs.lg.name);
        }
        this.state.imageObjs.lg = ImageModel.empty();
        if (fileForUpload) {
            let ext = fileForUpload.name.split('.').pop();
            this.state.imageObjs.lg.name = `${this.entity}/${this.imageNamePrefix|| ""}-lg-${new Date().toISOString()}.${ext}`;
            this.state.imageObjs.lg.file = fileForUpload;
        }
        this.setState({ imageObjs: this.state.imageObjs });
    }

    public handleImageSmChange(event: any) {
        const { target: { value, files } } = event;
        const fileForUpload = files[0];
        // add previously uploaded image to removing list
        if (this.state.imageObjs.sm.uploaded) {
            this.imagesToDelete.push(this.state.imageObjs.sm.name);
        }
        this.state.imageObjs.sm = ImageModel.empty();
        if (fileForUpload) {
            let ext = fileForUpload.name.split('.').pop();
            this.state.imageObjs.sm.name = `${this.entity}/${this.imageNamePrefix|| ""}-sm-${new Date().toISOString()}.${ext}`;
            this.state.imageObjs.sm.file = fileForUpload;
        }
        this.setState({ imageObjs: this.state.imageObjs });
    }

    public handleImageXsChange(event: any) {
        const { target: { value, files } } = event;
        const fileForUpload = files[0];
        // add previously uploaded image to removing list
        if (this.state.imageObjs.xs.uploaded) {
            this.imagesToDelete.push(this.state.imageObjs.xs.name);
        }
        this.state.imageObjs.xs = ImageModel.empty();
        if (fileForUpload) {
            let ext = fileForUpload.name.split('.').pop();
            this.state.imageObjs.xs.name = `${this.entity}/${this.imageNamePrefix|| ""}-xs-${new Date().toISOString()}.${ext}`;
            this.state.imageObjs.xs.file = fileForUpload;
        }
        this.setState({ imageObjs: this.state.imageObjs });
    }

    public handleImage1Change(event: any) {
        const { target: { value, files } } = event;
        const fileForUpload = files[0];
        // add previously uploaded image to removing list
        if (this.state.imageObjs.image1.uploaded) {
            this.imagesToDelete.push(this.state.imageObjs.image1.name);
        }
        this.state.imageObjs.image1 = ImageModel.empty();
        if (fileForUpload) {
            let ext = fileForUpload.name.split('.').pop();
            this.state.imageObjs.image1.name = `${this.entity}/${this.imageNamePrefix|| ""}-image1-${new Date().toISOString()}.${ext}`;
            this.state.imageObjs.image1.file = fileForUpload;
        }
        this.setState({ imageObjs: this.state.imageObjs });
    }

    public handleImage2Change(event: any) {
        const { target: { value, files } } = event;
        const fileForUpload = files[0];
        // add previously uploaded image to removing list
        if (this.state.imageObjs.image2.uploaded) {
            this.imagesToDelete.push(this.state.imageObjs.image2.name);
        }
        this.state.imageObjs.image2 = ImageModel.empty();
        if (fileForUpload) {
            let ext = fileForUpload.name.split('.').pop();
            this.state.imageObjs.image2.name = `${this.entity}/${this.imageNamePrefix|| ""}-image2-${new Date().toISOString()}.${ext}`;
            this.state.imageObjs.image2.file = fileForUpload;
        }
        this.setState({ imageObjs: this.state.imageObjs });
    }

    public handleImage3Change(event: any) {
        const { target: { value, files } } = event;
        const fileForUpload = files[0];
        // add previously uploaded image to removing list
        if (this.state.imageObjs.image3.uploaded) {
            this.imagesToDelete.push(this.state.imageObjs.image3.name);
        }
        this.state.imageObjs.image3 = ImageModel.empty();
        if (fileForUpload) {
            let ext = fileForUpload.name.split('.').pop();
            this.state.imageObjs.image3.name = `${this.entity}/${this.imageNamePrefix|| ""}-image3-${new Date().toISOString()}.${ext}`;
            this.state.imageObjs.image3.file = fileForUpload;
        }
        this.setState({ imageObjs: this.state.imageObjs });
    }

    public handleImage4Change(event: any) {
        const { target: { value, files } } = event;
        const fileForUpload = files[0];
        // add previously uploaded image to removing list
        if (this.state.imageObjs.image4.uploaded) {
            this.imagesToDelete.push(this.state.imageObjs.image4.name);
        }
        this.state.imageObjs.image4 = ImageModel.empty();
        if (fileForUpload) {
            let ext = fileForUpload.name.split('.').pop();
            this.state.imageObjs.image4.name = `${this.entity}/${this.imageNamePrefix|| ""}-image4-${new Date().toISOString()}.${ext}`;
            this.state.imageObjs.image4.file = fileForUpload;
        }
        this.setState({ imageObjs: this.state.imageObjs });
    }

    public handleImage5Change(event: any) {
        const { target: { value, files } } = event;
        const fileForUpload = files[0];
        // add previously uploaded image to removing list
        if (this.state.imageObjs.image5.uploaded) {
            this.imagesToDelete.push(this.state.imageObjs.image5.name);
        }
        this.state.imageObjs.image5 = ImageModel.empty();
        if (fileForUpload) {
            let ext = fileForUpload.name.split('.').pop();
            this.state.imageObjs.image5.name = `${this.entity}/${this.imageNamePrefix|| ""}-image5-${new Date().toISOString()}.${ext}`;
            this.state.imageObjs.image5.file = fileForUpload;
        }
        this.setState({ imageObjs: this.state.imageObjs });
    }

    public handleImage6Change(event: any) {
        const { target: { value, files } } = event;
        const fileForUpload = files[0];
        // add previously uploaded image to removing list
        if (this.state.imageObjs.image6.uploaded) {
            this.imagesToDelete.push(this.state.imageObjs.image6.name);
        }
        this.state.imageObjs.image6 = ImageModel.empty();
        if (fileForUpload) {
            let ext = fileForUpload.name.split('.').pop();
            this.state.imageObjs.image6.name = `${this.entity}/${this.imageNamePrefix|| ""}-image6-${new Date().toISOString()}.${ext}`;
            this.state.imageObjs.image6.file = fileForUpload;
        }
        this.setState({ imageObjs: this.state.imageObjs });
    }

    // When an existing/uploaded image is hidden
    toggleImage(key: string) {
        let state: any = this.state;
        switch (key) {
            case "imageUrl":
                state.imageObj.showOriginal = !state.imageObjs.lg.showOriginal;
                break;
            case "imageLgUrl":
                state.imageObjs.lg.showOriginal = !state.imageObjs.lg.showOriginal;
                break;
            case "imageSmUrl":
                state.imageObjs.sm.showOriginal = !state.imageObjs.sm.showOriginal;
                break;
            case "imageXsUrl":
                state.imageObjs.xs.showOriginal = !state.imageObjs.xs.showOriginal;
                break;
            case "image1":
                state.imageObjs.image1.showOriginal = !state.imageObjs.image1.showOriginal;
                break;
            case "image2":
                state.imageObjs.image2.showOriginal = !state.imageObjs.image2.showOriginal;
                break;
            case "image3":
                state.imageObjs.image3.showOriginal = !state.imageObjs.image3.showOriginal;
                break;
            case "image4":
                state.imageObjs.image4.showOriginal = !state.imageObjs.image4.showOriginal;
                break;
            case "image5":
                state.imageObjs.image5.showOriginal = !state.imageObjs.image5.showOriginal;
                break;
            case "image6":
                state.imageObjs.image6.showOriginal = !state.imageObjs.image6.showOriginal;
                break;
        }
        this.setState({ imageObj: state.imageObj, imageObjs: state.imageObjs });
    }

    public getAdminToken = (): string => {
        var token = localStorage.getItem('adminToken')
        if (!token || token === 'undefined') {
            this.setState({ redirect: this.loginUrl })
        }
        return token || "";
    }

    validate(): boolean {
        return false;
    }

    validateEmail(email: string) {
        if (!email) {
            return true;
        }
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    
    validatePhone(phone: string) {
        if (!phone) {
            return false;
        }
        var re = /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/;
        return re.test(String(phone).toLowerCase());
    }

    async save() {
        var token = this.getAdminToken()

        if (!this.validate()) {
            window.scrollTo(0, 0);
            return;
        }
        this.setState({ loading: true });

        // Check slug uniqueness
        if (this.state.model.slug) {
            this.checkSlug().then(res => {
                if (res.status === 200) {
                    this.setState({ slugValid: true, errorMsg: "" })
                } else if (res.status === 409) {
                    this.setState({ slugValid: false, errorMsg: "URL нэр ашиглагдсан байна", loading: false })
                    return;
                } else {
                    this.setState({ slugValid: false, errorMsg: "Сүлжээний алдаа", loading: false })
                    return;
                }
            })
        }
        else {
            this.setState({ slugValid: false });
        }
        
        // images
        for (let imageObj of [this.state.imageObj, this.state.imageObjs.lg, this.state.imageObjs.sm, this.state.imageObjs.xs, 
                this.state.imageObjs.image1, this.state.imageObjs.image2, this.state.imageObjs.image3, this.state.imageObjs.image4, 
                this.state.imageObjs.image5, this.state.imageObjs.image6]) {
            if (imageObj.file && !imageObj.uploaded) {
                // upload image
                try {
                    await S3Manager.uploadImage(imageObj.name, imageObj.file);
                    imageObj.uploaded = true;
                    this.imagesUploaded.push(imageObj.name);
                }
                catch (err) {
                    console.error(err);
                    this.setState({ loading: false });
                    window.alert("Зураг хуулахад алдаа гарлаа. Refresh хийнэ үү.")
                    return;
                }
            }
        }
        this.setState({ imageObj: this.state.imageObj, imageObjs: this.state.imageObjs });

        // POST to API
        let payload: any = Object.assign({}, this.state.model);
        if (payload.active !== undefined) {
            payload.active = payload.active === true ? 1 : 0;
        }
        if (payload.approved !== undefined) {
            payload.approved = payload.approved === true ? 1 : 0;
        }
        if (payload.canPayLater !== undefined) {
            payload.canPayLater = payload.canPayLater === true ? 1 : 0;
        }
        if (payload.saleActive !== undefined) {
            payload.saleActive = payload.saleActive === true ? 1 : 0;
        }
        // image attributes
        if (this.state.imageObjs.lg.uploaded) {
            payload.imageLgUrl = this.state.imageObjs.lg.name;
        }
        if (this.state.imageObjs.sm.uploaded) {
            payload.imageSmUrl = this.state.imageObjs.sm.name;
        }
        if (this.state.imageObjs.xs.uploaded) {
            payload.imageXsUrl = this.state.imageObjs.xs.name;
        }
        if (this.state.imageObj.uploaded) {
            payload.imageUrl = this.state.imageObj.name;
        }
        if (this.state.imageObjs.image1.uploaded) {
            payload.image1 = this.state.imageObjs.image1.name;
        }
        if (this.state.imageObjs.image2.uploaded) {
            payload.image2 = this.state.imageObjs.image2.name;
        }
        if (this.state.imageObjs.image3.uploaded) {
            payload.image3 = this.state.imageObjs.image3.name;
        }
        if (this.state.imageObjs.image4.uploaded) {
            payload.image4 = this.state.imageObjs.image4.name;
        }
        if (this.state.imageObjs.image5.uploaded) {
            payload.image5 = this.state.imageObjs.image5.name;
        }
        if (this.state.imageObjs.image6.uploaded) {
            payload.image6 = this.state.imageObjs.image6.name;
        }

        console.log("Posting", payload);

        fetch(process.env.REACT_APP_CHEF_API + this.entity, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
        .then(res => {
            if (res.status === 200) {
                this.recordSaved = true;
                // remove images that are used no more
                this.deleteOldImagesAndNavigateToList();
            }
            else if (res.status === 401) {
                this.setState({ redirect: this.loginUrl})
            }
            else {
                this.setState({ loading: false });
                res.json().then(res => {
                    console.log(res)
                    if (res.error) {
                        this.setState({ loading: false, errorMsg: res.error })
                        this.recordSaved = false;
                        window.scrollTo(0,0);
                        return;
                    }
                    
                })
            }
        })
        .catch(err => {
            this.setState({ loading: false });
            console.error(err);
        });
    }

    async deleteOldImagesAndNavigateToList() {
        let promiseArray = [];
        for (let imgUrl of this.imagesToDelete) {
            promiseArray.push(S3Manager.deleteImage(imgUrl))
        }
        await Promise.all(promiseArray);
        this.imagesToDelete = [];
        window.location.pathname = `/mongol/${this.navigationEntity ? this.navigationEntity : this.entity}s`
    }

    public toggleSlug = () => {
        this.setState({ editingSlug: true })
    }

    public cancelSlugChange = () => {
        if (this.state.editing && this.state.model.id !== undefined && this.state.model.id !== null) {
            let model: any = this.state.model;
            model.slug = this.originalModel.slug;
            this.setState({ slugValid: true, model: model, editingSlug: false }) 
        }
        else {
            this.setState({ editingSlug: false }) 
        }
    }

    public postNewSlug = () => {
        if (this.state.model.slug) {
            if (this.state.model.slug === this.originalModel.slug) {
                this.setState({ editingSlug: false })
            }
            else {
                // check uniqueness of slug
                this.checkSlug().then(res => {
                    if (res.status === 200) {
                        this.setState({ slugValid: true, editingSlug: false, errorMsg: "" })
                    } else if (res.status === 409) {
                        this.setState({ slugValid: false, errorMsg: "" })
                    } else {
                        this.setState({ errorMsg: "Сүлжээний алдаа", editingSlug: false })
                    }
                })
            }
        }
        else {
            this.setState({ slugValid: false });
        }
    }

    public checkSlug = (): Promise<any> => {
        var token = this.getAdminToken()
        return fetch(process.env.REACT_APP_CHEF_API + "check-slug", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                entity: this.entity,
                id: this.state.model.id,
                slug: this.state.model.slug
            })
        });
    }

    convertText(text: string): string {
        let converted = "";
        if (text) {
            for (let c of text.toLowerCase()) {
                switch(c) {
                    case 'а':
                        converted += "a";break;
                    case 'б':
                        converted += "b";break;
                    case 'в':
                        converted += "v";break;
                    case 'г':
                        converted += "g";break;
                    case 'д':
                        converted += "d";break;
                    case 'е':
                        converted += "ye";break;
                    case 'ё':
                        converted += "yo";break;
                    case 'ж':
                        converted += "j";break;
                    case 'з':
                        converted += "z";break;
                    case 'и':
                        converted += "i";break;
                    case 'й':
                        converted += "i";break;
                    case 'к':
                        converted += "k";break;
                    case 'л':
                        converted += "l";break;
                    case 'м':
                        converted += "m";break;
                    case 'н':
                        converted += "n";break;
                    case 'о':
                        converted += "o";break;
                    case 'ө':
                        converted += "o";break;
                    case 'р':
                        converted += "r";break;
                    case 'с':
                        converted += "s";break;
                    case 'т':
                        converted += "t";break;
                    case 'у':
                        converted += "u";break;
                    case 'ү':
                        converted += "u";break;
                    case 'п':
                        converted += "p";break;
                    case 'х':
                        converted += "kh";break;
                    case 'ц':
                        converted += "ts";break;
                    case 'ч':
                        converted += "ch";break;
                    case 'ш':
                        converted += "sh";break;
                    case 'щ':
                        converted += "sh";break;
                    case 'ь':
                        converted += "i";break;
                    case 'ъ':
                        converted += "";break;
                    case 'э':
                        converted += "e";break;
                    case 'ю':
                        converted += "yu";break;
                    case 'я':
                        converted += "ya";break;
                    default:
                        converted += c;
                }
            }
        }
        return converted;
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }
        return (
            <div></div>
        );
    }
}

export default MyForm;