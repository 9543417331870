import React, { Component } from 'react';
import './top-categories.scss';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col } from 'react-grid-system';

const mapStateToProps = (state: any) => {
  return {
    categories: state.categories.filter((c: any) => c.active)
  }
}

interface CategoriesProp {
  categories: any[];
}

interface CategoriesState {
  selected: any;
}

class TopCategories extends Component<CategoriesProp, CategoriesState> {
  public subCategories: any[] = [];
  public subCategories2: any[] = [];
  public topProducts: any[] = [];
  public showCategoryId: any;

  constructor(props: any) {
    super(props);
    this.state = {
      selected: null
    }
  }

  mouseEnter = (category: any) => {
    this.showCategoryId = category.id;

    setTimeout(() => {
      if (this.showCategoryId === category.id) {
        this.subCategories = category.subCategories.slice(0, 6);
        this.subCategories2 = category.subCategories.slice(6, category.subCategories.length);
        this.topProducts = category.topProducts ? category.topProducts : [];
        this.setState({
          selected: category
        });
      }
    }, 300);
  }

  mouseLeave = () => {
    this.showCategoryId = null;
    this.setState({
      selected: null
    });
  }

  render() {
    return (
      <div className="page">
        <div onMouseLeave={() => this.mouseLeave()}>
          <div className="top-categories">
            {this.props.categories.map((category) => {
              return <Link to={"/category/" + category.slug} className="top-category-item" key={'top-category-' + category.slug}
                onMouseEnter={() => this.mouseEnter(category)}>
                <span className="category-name">{category.name}</span>
              </Link>
            })}
            {
              this.state.selected ?
                <div className="top-sub-categories">
                  <div className="top-sub-categories-inner">
                    <div>
                      <Link className="category-title" to={"/category/" + this.state.selected.slug}>
                        <h3>{this.state.selected.name}</h3>
                        <span className="shop-now">Бүтээгдэхүүнүүд</span>
                      </Link>
                    </div>
                    <div className="sub-categories-container">
                      {this.subCategories?.map((sub: any) => {
                        return <Col sm={2} md={2} className="sub-category-item" key={'top-sub-category-' + sub.slug}>
                          <Link className="sub-link" to={`/category/${this.state.selected.slug}/${sub.slug}`}>
                            <div className="sub-image" style={{ backgroundImage: `url(${sub.imageXsUrl})` }}></div>
                            <div className="shop-now">{sub.name}</div>
                          </Link>
                        </Col>
                      })}

                      {this.subCategories.length === 0 ? this.topProducts.map((product: any) => {
                        return <Col sm={2} md={2} className="sub-category-item" key={'top-sub-category-' + product.slug}>
                          <Link className="sub-link" to={`/product/${product.slug}`}>
                            <div className="sub-image" style={{ backgroundImage: `url(${product.imageXsUrl})` }}></div>
                            <div className="shop-now">{product.name}</div>
                          </Link>
                        </Col>
                      }) : null}
                      
                    </div>
                  </div>
                </div> : null
            }
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(TopCategories)