import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dimmer, Loader, Pagination } from 'semantic-ui-react';
import OrderModel from '../../admin/components/order/order.model';
import './client.orders.scss';
import store from '../../store';
import OrderDetailsComponent from './orders-details.component';
import { formatDateTime, formatMongolianName } from '../../utils/shared.utils';

const bucket = process.env.REACT_APP_S3_BUCKET
const region = process.env.REACT_APP_S3_REGION

function CompanyOrdersPage(props: any) {

  const history = useHistory()
  const [orders, setOrders] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [errorMsg, setErrorMsg] = useState("")
  const [fetchedOrders, setFetchedOrders] = useState(false)
  const [page, setPage] = useState({ page: 1, size: 5, totalPages: 1 })

  useEffect(() => {
    if (fetchedOrders) {
      return
    }
    setFetchedOrders(true)
    getCompanyOrders(1);
    window.scrollTo(0, 0)
  }, []);

  const getCompanyOrders = (pageNum: number) => {
    
    var token = localStorage.getItem('clientToken')
    if (token && token !== 'undefined') {
      setLoading(true)
      fetch(process.env.REACT_APP_CHEF_API + 'client/company/orders', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ page: { page: pageNum, size: page.size } })
      }).then((res: any) => {
        setLoading(false)
        if (res.ok) {
          res.json().then((resJson: any) => {
            let orders: OrderModel[] = resJson.data;
            for (let order of orders) {
              order.showItems = true;
              order.createdAt = new Date(order.createdAt)
              order.createdAtStr = formatDateTime(order.createdAt)
              order.createdBy = formatMongolianName(order.parentName, order.ownName)
              for (let delivery of order.deliveries) {
                let date = new Date(delivery.date)
                delivery.date = `${date.getMonth() + 1} сарын ${date.getUTCDate()}`
                for (let item of delivery.items) {
                  item.product.imageXsUrl = `https://${bucket}.s3.${region}.amazonaws.com/${item.product.imageXsUrl}`
                }
              }
            }
            setOrders(orders)
            setPage(resJson.page)
          })
        }
      }).catch(err => {
        setLoading(false)
        setErrorMsg("Сүлжээний алдаа")
        console.error(err)
      })
    }
    else {
      console.log("Will redirect to: " + window.location.pathname)
      store.dispatch({ type: "redirectUrl", data: window.location.pathname })
      history.push("/login")
    }
  }

  const onPageChange = (event: any, data: any) => {
    setPage(Object.assign(page, { page: data.activePage }))
    getCompanyOrders(data.activePage)
    window.scrollTo(0, 100)
  }

  return (
    <div className="page page-client-orders margin-top-50-sm">
      <div className="flex-div width-100">
        <Dimmer active={loading}>
          <Loader size='medium'></Loader>
        </Dimmer>
        <section className="client-orders" id="my-form">

          <div className="width-100 text-center"><h2>Байгууллагын захиалгууд</h2></div>

          <div className="errorMsg">{errorMsg}</div>

          { orders?.length > 0 ? <React.Fragment>

          <OrderDetailsComponent orders={orders} showStaff={true}></OrderDetailsComponent>

          <div className="flex-div margin-top-50">
            <Pagination defaultActivePage={1} value={page.page} totalPages={page.totalPages} onPageChange={onPageChange}></Pagination>
          </div>

          </React.Fragment>
          :
          <div>Танд захиалга байхгүй байна.</div>
          }
        </section>
      </div>
    </div>
  );
}

export default CompanyOrdersPage;