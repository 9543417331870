import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteProps } from 'react-router-dom';
import { Container } from 'react-grid-system';
import Products from '../products/products';
import store from '../../store';

interface SearchProps {
  products: any,
  match: any,
  history: any
}

interface SearchState {
}

const mapStateToProps = (state: any) => {
  let products: any[] = [];
  if (state.client && state.client.savedProducts && state.products) {
    let savedProductsSet = new Set<Number>();
    for (let saved of state.client.savedProducts) {
      savedProductsSet.add(saved.productId);
    }
    for (let product of state.products) {
      if (!product.active || !product.brandActive || !product.categoryActive) {
        continue;
      }
      if (savedProductsSet.has(product.id)) {
        products.push(product);
      }
    }
  }
  return {
    products: products
  }
}

class SavedProductsPage extends Component<SearchProps & RouteProps, SearchState> {

  public triedSavedProducts = 0;

  componentDidMount() {
    var token = localStorage.getItem('clientToken')
    if (token && token !== 'undefined') {
      this.getSavedProducts(token)
    }
    setTimeout(() => {
      store.dispatch({ type: 'REFRESH_PRODUCTS' })
    });
  }

  getSavedProducts(token: string) {
    fetch(process.env.REACT_APP_CHEF_API + 'client/saved-products', {
      method: 'GET',
      headers: {
          'Accept': 'application/json',
          'Authorization': token,
          'Content-Type': 'application/json'
      }
    })
    .then((res: any) => {
        if (res.ok) {
            res.json().then((resJson: any) => {
              store.dispatch({
                type: 'savedProducts',
                data: resJson.data
              })
            })
        }
    })
    .catch((err: any) => {
      console.error(err)
      if (this.triedSavedProducts < 4) {
        this.triedSavedProducts++;
        this.getSavedProducts(token)
      }
    })
  }

  render() {
    return (
      <div className="page search-page">
        <Container fluid={true} className="saved-products-page-container" id="page-container">
          <h2>Хадгалсан бараа</h2>
          <Products products={this.props.products}></Products>
        </Container>
      </div>
    );
  }
}

export default connect(mapStateToProps)(SavedProductsPage);