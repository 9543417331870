class OrderModel {
    id: any
    token: string = ""
    clientId: any
    payLater: any
    status: string = ""
    totalAmount: number = 0
    totalDeliveryCost: number = 0
    items: any[] = []
    deliveries: OrderDelivery[] = []
    payments: any[] = []
    town: string = ""
    district: string = ""
    unit: string = ""
    street: string = ""
    reserveUntil: Date = new Date()
    reserveDate: string = ""
    showItems: boolean = false
    createdAt: Date = new Date()
    createdAtStr = ""
    createdBy = ""
    ownName = ""
    parentName = ""
    companyId: any
    companyName = ""
    email = ""
    phone = ""
  }
  
  class OrderDelivery {
    id: any
    orderId: any
    date: any
    cost: Number = 0
    items: any[] = []
}

export default OrderModel