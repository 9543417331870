import React from 'react';
import { Form, Container, Dimmer, Loader } from 'semantic-ui-react';
import MyFormState from './MyFormState';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import MyForm from './form';
import { ImageModel } from '../../../entities/image.model';
import { ClientModel } from '../../../entities/client.model';

const MyLabel = styled.div`
    width: 140px;
    font-weight: 600;
`;

interface FormProps {
    match: any,
    history: any
}

class ClientForm extends MyForm<FormProps, MyFormState> {
    entity = 'client'

    constructor(props: any) {
        super(props);
        this.state = {
            model: new ClientModel,
            successMsg: "",
            errorMsg: "",
            loading: true,
            editing: false,
            editingSlug: false,
            slugValid: true,
            imageObj: ImageModel.empty(),
            imageObjs: { lg: ImageModel.empty(), sm: ImageModel.empty(), xs: ImageModel.empty(),
                image1: ImageModel.empty(), image2: ImageModel.empty(), image3: ImageModel.empty(),
                image4: ImageModel.empty(), image5: ImageModel.empty(), image6: ImageModel.empty()
            },
            redirect: "",
        }
    }

    validate(): boolean {
        this.setState({ successMsg: "" });
        
        if (this.state.model.id !== undefined) {
            if (this.state.model.active === this.originalModel.active ) {
                this.setState({ errorMsg: "Өөрчлөлт байхгүй байна"});
                return false;
            }
        }
        this.setState({ errorMsg: "" });
        return true;
    }

    async save() {
        if (!this.validate()) {
            window.scrollTo(0, 0);
            return;
        }

        var token = this.getAdminToken()
        this.setState({ loading: true });

        // POST to API
        let payload = {
            clientId: this.state.model.id,
            active: this.state.model.active ? 1 : 0
        }

        fetch(process.env.REACT_APP_CHEF_API + 'mongol/client', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
        .then(res => {
            if (res.status === 200) {
                this.originalModel.active = this.state.model.active;
                this.setState({ loading: false, successMsg: "Амжилттай хадгаллаа." })
            }
            else if (res.status === 401) {
                this.setState({ redirect: this.loginUrl})
            }
            else {
                res.json().then(res => {
                    if (res.error) {
                        this.setState({ loading: false, errorMsg: res.error })
                        this.recordSaved = false;
                        window.scrollTo(0,0);
                        return;
                    }
                    
                })
            }
        })
        .catch(err => {
            this.setState({ loading: false });
            console.error(err);
        });
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }
        return (
            <Container>
                <Dimmer active={this.state.loading} inverted>
                    <Loader size='medium'></Loader>
                </Dimmer>
                <Form widths='equal'>
                    <Form.Group>
                        { this.state.successMsg ? <div className="success-msg">{this.state.successMsg}</div> : null }
                        { this.state.errorMsg ? <div className="error-msg">{this.state.errorMsg}</div> : null }
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <MyLabel>Овог</MyLabel>
                        <div>{this.state.model.parentName}</div>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <MyLabel>Нэр</MyLabel>
                        <div>{this.state.model.ownName}</div>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <MyLabel>Имэйл</MyLabel>
                        <div>{this.state.model.email}</div>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <MyLabel>Утасны дугаар</MyLabel>
                        <div>{this.state.model.phone}</div>
                    </Form.Group>
                    <Form.Group>
                        <input type="checkbox" id="company-active" onChange={this.onActiveChange} checked={this.state.model.active} />
                        &nbsp;
                        <label htmlFor="company-active">{ this.state.model.active ? "Идэвхитэй" : "Идэвхигүй" }</label>
                    </Form.Group>
                    <Form.Group>
                        <MyLabel>Хаяг:</MyLabel>
                    </Form.Group>
                    <Form.Group>
                        <MyLabel>Хот</MyLabel>
                        <div>{this.state.model.town}</div>
                    </Form.Group>
                    <Form.Group>
                        <MyLabel>Дүүрэг</MyLabel>
                        <div>{this.state.model.district}</div>
                    </Form.Group>
                    <Form.Group>
                        <MyLabel>Хороо</MyLabel>
                        <div>{this.state.model.unit}</div>
                    </Form.Group>
                    <Form.Group>
                        <MyLabel>Дэлгэрэнгүй</MyLabel>
                        <div>{this.state.model.street}</div>
                    </Form.Group>
                    {/* <Form.Group>
                        <MyLabel>Зипкод</MyLabel>
                        <div>{this.state.model.town}</div>
                    </Form.Group> */}

                    <br/>
                    <Form.Button disabled={this.state.loading} onClick={() => this.save()}>Хадгалах</Form.Button>
                </Form>
            </Container>
        );
    }
}

export default ClientForm;