import React from 'react';
import { Button, Table, Icon } from 'semantic-ui-react';
import 'semantic-ui-css/components/table.min.css';
import { Link, Redirect } from 'react-router-dom';
import MyList from './list';

interface ListProps {

}

interface ListState {
    data: any[],
    errorMsg: string
}

class BrandList extends MyList {
    entity = "brand"

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }
        return (
            <section className="full-width">
                <h2>Брэндийн жагсаалт</h2>
                <Link to="/mongol/brands/new">
                    <Button basic color='orange'>Шинээр үүсгэх</Button>
                </Link>

                <Table striped width={16}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>№</Table.HeaderCell>
                            <Table.HeaderCell>Брэндийн нэр</Table.HeaderCell>
                            <Table.HeaderCell>Идэвхитэй</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        { this.state?.data?.map((row: any, index: number) => {
                                return <Table.Row key={row.id}>
                                    <Table.Cell>{ String(index+1) }</Table.Cell>
                                    <Table.Cell>
                                        <strong>
                                            <Link className="underline hover-blue" to={"/mongol/brand/" + row.id}>{ row.name }</Link>
                                        </strong>
                                    </Table.Cell>
                                    <Table.Cell> { row.active ? <Icon name="checkmark"/> : null } </Table.Cell>
                                    <Table.Cell>
                                        { row.confirmingDelete ? 
                                            <React.Fragment>
                                                <Button primary size='mini' onClick={() => this.delete(index)}>Устга</Button>
                                                <Button size='mini' onClick={() => this.toggleDelete(index)}>Болих</Button>
                                            </React.Fragment> :
                                            <Icon name="trash" className="cursor chef-icon-hover padding-5" onClick={() => this.toggleDelete(index)}/>
                                        }
                                    </Table.Cell>
                                </Table.Row>
                            })
                        }
                    </Table.Body>
                </Table>
            </section>
        );
    }
}
            
export default BrandList;