import React, { useEffect, useState } from 'react'
import { Link, useHistory } from "react-router-dom";

function SearchInputComponent(props: any) {
    const history = useHistory()
    const [ value, setValue ] = useState("")

    useEffect(() => {
        if (window?.location?.search) {
            let arr = String(window?.location?.search).split("?")
            if (arr.length === 0) return
            let str = arr[1].toLowerCase()
            if (str && str.indexOf("value=") !== -1) {
                arr = str.split("value=")
                str = arr[1]
                if (str) {
                arr = str.split("&")
                    str = arr[0]
                    if (str) {
                        // found sale query param
                        if (str) {
                            setValue(decodeURI(str))
                        }
                    }
                }
            }
        }
    }, [])

    const onInputChange = (e: any) => {
        setValue(e.target?.value)
    }

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            history.push(value ? `/search?value=${value}` : "/search")
        }
    }

    return (
        <div className="search-input">
            <input type="text" autoComplete="true" value={value} onChange={(e) => onInputChange(e)} onKeyDown={handleKeyDown}/>
            <Link to={value ? `/search?value=${value}` : "/search"} className="icon icon-search cursor"></Link>
        </div>
    )
}

export default SearchInputComponent