import React, { Component } from 'react';
import './category-item.scss';
import { Link } from 'react-router-dom';

interface CategoryProps {
  item: any,
  parentCategory: any
}

class SubCategoryItem extends Component<CategoryProps> {

  render() {
    return (
      <div className="category-item">
        <Link to={ `/category/${this.props.parentCategory.slug}/${this.props.item.slug}` }>
          <div className="category-background" style={{ backgroundImage: `url(${this.props.item.imageXsUrl})` }}></div>
          <div className="category-name">
            {this.props.item.name}
          </div>
        </Link>

        <div className="category-content">
          <div className="left">
            {
              this.props.item.subCategories ?
                <Link className="category-link" to={ `/category/${this.props.parentCategory.slug}/${this.props.item.slug}` }>
                  <span className="shop-now">{this.props.item.subCategories && this.props.item.subCategories.length > 0 ? "Бүтээгдэхүүнүүд" : ""}</span>
                </Link> :
                <Link className="category-link" to={ `/category/${this.props.parentCategory.slug}/${this.props.item.slug}` }>
                  <span className="shop-now">Бүтээгдэхүүнүүд</span>
                </Link>
            }
            <p className="category-description">{this.props.item.description}</p>
          </div>
          <div className="right">
            {this.props.item.subCategories?.map((sub: any) => {
              return <Link className="shop-now" key={sub.id} to={ `/category/${this.props.parentCategory.slug}/${sub.slug}` }>{sub.name}</Link>
            })}

            {this.props.item.subCategories?.length === 0 && this.props.item.topProducts ? this.props.item.topProducts.map((sub: any) => {
              return <Link className="shop-now" key={sub.id} to={ `/category/${this.props.parentCategory.slug}/${sub.slug}` }>{sub.name}</Link>
            }) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default SubCategoryItem;