import React from 'react';
import MyList from './list';
import { Button, Table, Icon, Pagination, Form, Loader, Dimmer } from 'semantic-ui-react';
import { Link, Redirect } from 'react-router-dom';

class AdminProductList extends MyList {
    entity = "product";

    public previousNameValue = "";

    constructor(props: any) {
        super(props);
        this.state = { loading: true, data: [], errorMsg: "", redirect: "", 
        page: { page: 1, size: 15, totalPages: 1, totalRows: 0}, 
        list1: [], value1: null, value2: null };
        this.onPageChange = this.onPageChange.bind(this);
    }

    componentDidMount() {
        this.refresh();
        this.getProviders();
    }

    getProviders() {
        var token = localStorage.getItem('adminToken')
        if (token && token !== 'undefined') {
            fetch(process.env.REACT_APP_CHEF_API + "list/provider", {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': token,
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                if (res.status === 401) {
                    this.setState({ redirect: this.loginUrl})
                } else {
                    res.json().then(res => {
                        console.log(res);
                        if (!res || !res.data) {
                            return;
                        }
                        let data: any[] = [];
                        res.data.forEach((c: any) => {
                            if (c.active) {
                                data.push({
                                    key: c.id,
                                    value: c.id,
                                    text: c.name
                                });
                            }
                        });
                        this.setState({ list1: data });
                    })
                }
            })
            .catch(err => console.error(err));
        }
        else {
            this.setState({ redirect: this.loginUrl });
        }
    }

    refresh() {
        var token = localStorage.getItem('adminToken')
        if (token && token !== 'undefined') {
            
            if (this.previousNameValue !== this.state.value2) {
                this.setState({ loading: true, page: Object.assign(this.state.page, { page: 1 }) });
            }
            else {
                this.setState({ loading: true })
            }
            fetch(process.env.REACT_APP_CHEF_API + "mongol/products", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    providerId: this.state.value1,
                    name: this.state.value2,
                    page: { page: this.previousNameValue === this.state.value2 ? this.state.page.page : 1, size: this.state.page.size }
                })
            })
            .then(res => {
                this.previousNameValue = this.state.value2;
                this.setState({ loading: false })
                if (res.status === 401) {
                    this.setState({ redirect: this.loginUrl})
                } else {
                    res.json().then(res => {
                        if (!res || !res.data) {
                            return;
                        }
                        let data: any[] = res.data;
                        data.forEach(b => b.confirmingDelete = false);
                        setTimeout(() => {
                            this.setState({ data: data, page: res.page });   
                        });
                    })
                }
            })
            .catch(err => {
                this.setState({ loading: false })
                console.error(err)
            });
        }
        else {
            this.setState({ redirect: this.loginUrl });
        }
    }

    clearFilters() {
        this.setState({ value1: null, value2: "" })
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }
        return (
            <section className="full-width">
                <Dimmer active={this.state.loading} inverted>
                    <Loader size='medium'></Loader>
                </Dimmer>
                <h2>Бүтээгдэхүүн</h2>
                <Link to="/mongol/products/new">
                    <Button basic color='orange'>Шинээр үүсгэх</Button>
                </Link>

                <div className="width-100 margin-top-20">
                    <Form>
                        <Form.Group>
                            <Form.Dropdown width={5} required placeholder='Нийлүүлэгч' fluid labeled clearable selection options={this.state.list1}
                                value={this.state.value1} onChange={this.onValue1Change} />
                            <Form.Input width={5} placeholder="бүтээгдэхүүний нэр" type="text" value={this.state.value2 || ""} onChange={this.onValue2Change}></Form.Input>
                            <Button color="orange" width={3} onClick={() => this.refresh()}><Icon name="search"/>Хайх</Button>
                            <Button width={3} onClick={() => this.clearFilters()}><Icon name="cancel"/>Филтер цэвэрлэх</Button>
                        </Form.Group>
                    </Form>
                </div>

                {this.state.errorMsg ? <div className="error-msg">
                    <Icon name="warning circle"/>&nbsp;
                    {this.state.errorMsg}
                </div> : null}

                <p>Нийт {this.state.page.totalRows} бүтээгдэхүүн олдлоо</p>
                <Table striped width={16}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>№</Table.HeaderCell>
                            <Table.HeaderCell>Нэр</Table.HeaderCell>
                            <Table.HeaderCell>Нийлүүлэгч</Table.HeaderCell>
                            <Table.HeaderCell>Үнэ</Table.HeaderCell>
                            <Table.HeaderCell>Үлдэгдэл</Table.HeaderCell>
                            <Table.HeaderCell>Хэмжээ</Table.HeaderCell>
                            <Table.HeaderCell>Хүргэх өдөр</Table.HeaderCell>
                            <Table.HeaderCell>Идэвхитэй</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        { this.state?.data?.map((row: any, index: number) => {
                                return <Table.Row key={row.id}>
                                    <Table.Cell>{ String(index+1) }</Table.Cell>
                                    <Table.Cell>
                                        <strong>
                                            <Link className="underline hover-blue" to={"/mongol/product/" + row.id}>{ row.name }</Link>
                                        </strong>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Link className="underline" to={"/mongol/provider/" + row.providerId}>{row.providerName}</Link>
                                    </Table.Cell>
                                    <Table.Cell>{row.saleActive ? row.salePrice : row.price} ₮</Table.Cell>
                                    <Table.Cell> {row.count} </Table.Cell>
                                    <Table.Cell> {row.size} {row.unit} </Table.Cell>
                                    <Table.Cell> {row.deliveryDayMargin}</Table.Cell>
                                    <Table.Cell> { row.active ? <Icon name="checkmark"/> : null } </Table.Cell>
                                    <Table.Cell>
                                        { row.confirmingDelete ? 
                                            <React.Fragment>
                                                <Button primary size='mini' onClick={() => this.delete(index)}>Устга</Button>
                                                <Button size='mini' onClick={() => this.toggleDelete(index)}>Болих</Button>
                                            </React.Fragment> :
                                            <Icon name="trash" className="cursor chef-icon-hover padding-5" onClick={() => this.toggleDelete(index)}/>
                                        }
                                    </Table.Cell>
                                </Table.Row>
                            })
                        }
                    </Table.Body>
                </Table>

                <div className="flex-div margin-top-50">
                    <Pagination activePage={this.state.page.page} totalPages={this.state.page.totalPages} onPageChange={this.onPageChange}></Pagination>
                </div>
            </section>
        );
    }
}
            
export default AdminProductList;