import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Icon, Form, Dimmer, Loader } from 'semantic-ui-react';
import store from '../../store';
import { ProviderUserModel } from './provider.user.model';
import { validateEmail, validatePassword, validatePhone } from '../../utils/shared.utils';
import './account.scss';

const FormGroup = styled.div`
    margin-top: 20px;
    color: #fff;
`;

const MyLabel = styled.div`
    margin-bottom: 10px;
    font-weight: 600;
`;

const mapStateToProps = (state: any) => {
  return {
    provider: state.provider
  }
}

function ProviderAccountPage(props: any) {

  const [providerToken, setProviderToken] = useState("");
  const [original, setOriginal] = useState(new ProviderUserModel());
  const [providerUser, setProviderUser] = useState(new ProviderUserModel());

  const [loading, setLoading] = useState(false);
  const [editingAccount, setEditingAccount] = useState(false);
  const [editingAddress, setEditingAddress] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);

  const [password, setPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState("");

  const history = useHistory()

  useEffect(() => {
    var token = localStorage.getItem('providerToken')
    if (token && token !== 'undefined') {
      setProviderToken(token)
      fetch(process.env.REACT_APP_CHEF_API + 'provider', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': token,
            'Content-Type': 'application/json'
        }
      })
      .then((res: any) => {
          if (res.ok) {
              res.json().then((resJson: any) => {
                  let data = resJson.user
                  if (!data.town) data.town = ""
                  if (!data.district) data.district = ""
                  if (!data.unit) data.unit = ""
                  if (!data.street) data.street = ""
                  if (!data.zipcode) data.zipcode = ""

                  setProviderUser(data)
                  setOriginal(Object.assign({}, data));
              })
          }
          else {
            setErrorMsg("Сүлжээнд алдаа гарлаа")
          }
      })
      .catch(err => {
        console.error(err)
        setErrorMsg("Сүлжээний алдаа")
      })
    }
    else {
      history.push("/provider-login")
    }
  }, []);

  const setTabHeader = (index: number) => {
    setActiveIndex(index)
    setErrorMsg("")
    setSuccessMsg("")
    // discard changes
    setEditingAccount(false)
    setEditingAddress(false)
    setProviderUser(Object.assign({}, providerUser, original))
    setPassword("")
    setPasswordMatch("")
  }

  const validateMyDetails = (): boolean => {
    setSuccessMsg("");
    if (!providerUser.ownName) {
      setErrorMsg("Та өөрийн нэрээ оруулна уу")
      return false;
    }
    if (!providerUser.email) {
      setErrorMsg("Та имэйл оруулна уу")
      return false;
    }
    if (!validateEmail(providerUser.email)) {
      setErrorMsg("Имэйл буруу форматтай байна")
      return false;
    }
    if (!providerUser.phone) {
      setErrorMsg("Та гар утасны дугаар оруулна уу")
      return false;
    }
    if (!validatePhone(providerUser.phone)) {
      setErrorMsg("Та гар утасны дугаараа шалгаж үзнэ үү")
      return false;
    }
    if (providerUser.parentName === original.parentName &&
      providerUser.ownName === original.ownName &&
      providerUser.email === original.email &&
      providerUser.phone === original.phone
    ) {
      setErrorMsg("Өөрчлөлт байхгүй байна");
      return false;
    }
    return true;
  }

  const validatePasswordChange = () => {
    setSuccessMsg("");
    if (!password) {
      setErrorMsg("Та нууц үг оруулна уу");
      return false;
    }
    if (!validatePassword(password)) {
      setErrorMsg("Нууц үг 6 тэмдэгтээс урт байх хэрэгтэй");
      return false;
    }
    if (!passwordMatch) {
      setErrorMsg("Нууц үгээ дахин оруулна уу");
      return false;
    }
    if (password !== passwordMatch) {
      setErrorMsg("Дахин оруулсан нууц үг өөр байна");
      return false;
    }
    return true;
  }

  const validateAddress = () => {
    setSuccessMsg("")
    if (!providerUser.town) {
      setErrorMsg("Хот оруулна уу");
      return false;
    }
    if (!providerUser.district) {
      setErrorMsg("Дүүрэг оруулна уу");
      return false;
    }
    if (!providerUser.unit) {
      setErrorMsg("Хороо оруулна уу");
      return false;
    }
    if (!providerUser.street) {
      setErrorMsg("Дэлгэрэнгүй хаяг оруулна уу");
      return false;
    }
    if (providerUser.town === original.town &&
      providerUser.district === original.district &&
      providerUser.unit === original.unit &&
      providerUser.street === original.street &&
      providerUser.zipcode === original.zipcode
    ) {
      setErrorMsg("Өөрчлөлт байхгүй байна");
      return false;
    }
    return true;
  }

  const saveMyDetails = () => {
    if (!validateMyDetails()) {
      scrollUp();
      return;
    }
    postClient({
      action: 'account',
      ownName: providerUser.ownName,
      parentName: providerUser.parentName,
      email: providerUser.email,
      phone: providerUser.phone
    })
  };

  const saveAddress = () => {
    if (!validateAddress()) {
      scrollUp();
      return;
    }
    postClient({
      action: 'address',
      town: providerUser.town,
      district: providerUser.district,
      unit: providerUser.unit,
      street: providerUser.street,
      zipcode: providerUser.zipcode
    })
  }

  const changePassword = () => {
    if (!validatePasswordChange()) {
      scrollUp();
      return;
    }
    postClient({
      action: 'password',
      password: password
    })
  }

  const postClient = (data: any) => {
    setLoading(true)
    setErrorMsg("")
    setSuccessMsg("")

    fetch(process.env.REACT_APP_CHEF_API + 'provider/user', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': providerToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(res => {
      if (!res.ok) {
        if (res.body) {
          if (res.status !== 404) {
            res.json().then(resJson => {
              setLoading(false)
              setErrorMsg(resJson.error)
            })
          }
        }
      }
      else {
        setLoading(false)
        setSuccessMsg("Амжилттай хадгаллаа")
        setErrorMsg("")
        setOriginal(Object.assign({}, providerUser));
        if (activeIndex === 2) {
          setPassword("")
          setPasswordMatch("")
        }
        else if (activeIndex === 1) {
          setEditingAddress(false)
        }
        else if (activeIndex === 0) {
          setEditingAccount(false)
        }
        // store.dispatch({
        //   type: 'authenticateProvider',
        //   client: providerUser
        // })
        setTimeout(() => setSuccessMsg(""), 3000)
        scrollUp()
      }
    })
    .catch(err => {
      setLoading(false)
      setErrorMsg("Сүлжээнд алдаа гарлаа")
      scrollUp()
    })
  }

  const scrollUp = () => {
    let element = document.getElementById("my-form");
    if (element) {
        element.scrollIntoView();
    }
  }

  const changeHandler = (e: any) => {
    setProviderUser({ ...providerUser, [e.target.name]: e.target.value })
  }

  return (
    <div className="provider-page page">
      <Dimmer active={loading}>
        <Loader size='medium'></Loader>
      </Dimmer>

      <div className="tab-head">
          <div className={"tab-header" + (activeIndex === 0 ? " selected" : "")} onClick={() => setTabHeader(0)}>Аккаунт</div>
          <div className={"tab-header" + (activeIndex === 1 ? " selected" : "")} onClick={() => setTabHeader(1)}>Хаяг</div>
          <div className={"tab-header" + (activeIndex === 2 ? " selected" : "")} onClick={() => setTabHeader(2)}>Нууц үг</div>
        </div>

      <div id="my-form">
        { errorMsg ? <div className="error-msg"><Icon name="warning circle"/>{ errorMsg }</div> : null }
        { successMsg ? <div className="success-msg"><Icon name="checkmark" color="green"/>{ successMsg }</div> : null }
        { activeIndex === 0 ? 
          <div className="tab-body">
            { editingAccount ?
            <Form widths='equal'>
              <FormGroup>
                <MyLabel>Имэйл</MyLabel>
                <input type="email" name="email" required placeholder='Имэйл' value={providerUser.email} onChange={changeHandler} />
              </FormGroup>
              <FormGroup>
                <MyLabel>Гар утас</MyLabel>
                <input type="text" name="phone" required placeholder='Гар утас' value={providerUser.phone} onChange={changeHandler} />
              </FormGroup>
              <FormGroup>
                <MyLabel>Овог</MyLabel>
                <Form.Input type="text" name="parentName" placeholder='Овог' value={providerUser.parentName || ""} onChange={changeHandler} />
              </FormGroup>
              <FormGroup>
                <MyLabel>Нэр</MyLabel>
                <input type="text" name="ownName" required placeholder='Нэр' value={providerUser.ownName} onChange={changeHandler} />
              </FormGroup>
              <FormGroup>
                <Button className="width-100" color='red' onClick={() => saveMyDetails()} disabled={loading}>Хадгалах</Button>
              </FormGroup>
            </Form>
            :
            <div>
              <div className="info-group">
                <div className="label">Имэйл</div>
                <div className="value">{providerUser.email}</div>
              </div>
              <div className="info-group">
                <div className="label">Утасны дугаар</div>
                <div className="value">{providerUser.phone}</div>
              </div>
              <div className="info-group">
                <div className="label">Овог</div>
                <div className="value">{providerUser.parentName}</div>
              </div>
              <div className="info-group">
                <div className="label">Нэр</div>
                <div className="value">{providerUser.ownName}</div>
              </div>
              <br/>
              <div className="info-group">
                <Button className="width-100" onClick={() => setEditingAccount(true)}>Өөрчлөх</Button>
              </div>
            </div> }
          </div> : null
        }

        { activeIndex === 1 ? 
          <div className="tab-body">
            { editingAddress ? 
            <Form widths='equal'>
              <FormGroup>
                <MyLabel>Хот</MyLabel>
                <Form.Input type="text" name="town" required value={providerUser.town} onChange={changeHandler} />
              </FormGroup>
              <FormGroup>
                <MyLabel>Дүүрэг</MyLabel>
                <input type="text" name="district" required value={providerUser.district} onChange={changeHandler} />
              </FormGroup>
              <FormGroup>
                <MyLabel>Хороо</MyLabel>
                <input type="text" name="unit" required value={providerUser.unit} onChange={changeHandler} />
              </FormGroup>
              <FormGroup>
                <MyLabel>Дэлгэрэнгүй</MyLabel>
                <textarea cols={12} rows={4} name="street" required value={providerUser.street} onChange={changeHandler} />
              </FormGroup>
              <FormGroup>
                <Button className="width-100" color='red' onClick={() => saveAddress()} disabled={loading}>Хадгалах</Button>
              </FormGroup>
              <FormGroup></FormGroup>
            </Form>
            :
            <div>
              <div className="info-group">
                <div className="label">Хаяг</div>
                <div className="value">{providerUser.town}, {providerUser.district}, {providerUser.unit}, {providerUser.street}</div>
              </div>
              <br/>
              <div className="info-group">
                <Button className="width-100" onClick={() => setEditingAddress(true)}>Өөрчлөх</Button>
              </div>
            </div>
            }
          </div> : null
        }

        { activeIndex === 2 ? 
          <div className="tab-body">
            <Form widths='equal'>
              <FormGroup>
                <p className="sm-grey-text">Нууц үг 6 тэмдэгтээс урт байх хэрэгтэй</p>
                <input type="password" required placeholder='Шинэ нууц үг' value={password} onChange={(e) => setPassword(e.target.value)} />
              </FormGroup>
              <FormGroup>
                <input type="password" required placeholder='Нууц үг дахин оруулна уу' value={passwordMatch} onChange={(e) => setPasswordMatch(e.target.value)} />
              </FormGroup>
              <br/>
              <FormGroup>
                <Button className="width-100" onClick={() => changePassword()} disabled={loading}>Нууц үг солих</Button>
              </FormGroup>
            </Form>
          </div> : null
        }
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(ProviderAccountPage);