import React from 'react';
import { Form, Container, Dimmer, Loader, Icon } from 'semantic-ui-react';
import ImageOnS3 from '../../../components/imageOnS3/imageOnS3.component';
import MyForm from './form';
import { ContentModel } from '../../../entities/content.model';
import { ImageModel } from '../../../entities/image.model';
import MyFormState from './MyFormState';
import { Redirect } from 'react-router-dom';

interface FormProps {
    match: any,
    history: any
}

class ContentForm extends MyForm<FormProps, MyFormState> {

    entity = "content";

    constructor(props: any) {
        super(props);
        this.state = {
            model: new ContentModel,
            successMsg: "",
            errorMsg: "",
            loading: true,
            editing: false,
            editingSlug: false,
            slugValid: true,
            imageObj: ImageModel.empty(),
            imageObjs: { lg: ImageModel.empty(), sm: ImageModel.empty(), xs: ImageModel.empty(),
                image1: ImageModel.empty(), image2: ImageModel.empty(), image3: ImageModel.empty(),
                image4: ImageModel.empty(), image5: ImageModel.empty(), image6: ImageModel.empty()
            },
            redirect: "",
        }
        this.state.model.type = 2;
    }

    componentWillMount() {
        let model: ContentModel = new ContentModel();
        this.setState({ model: model});
    }

    validate(): boolean {
        this.setState({ successMsg: "" });
        if (!this.state.model.name) {
            this.setState({ errorMsg: "Контентийн нэрийг оруулна уу." });
            return false;
        }
        if (!this.state.model.type) {
            this.setState({ errorMsg: "Контентийн төрөл сонгоно уу." });
            return false;
        }
        if (!this.state.model.url) {
            this.setState({ errorMsg: "Контентийн URL оруулна уу." });
            return false;
        }
        // Featured
        if (!this.state.imageObjs.xs.file && !this.state.model.imageXsUrl) {
            this.setState({ errorMsg: "Жижиг хэмжээтэй зураг оруулна уу" });
            return false;
        }

        if (this.state.model.id !== undefined) {
            if (this.state.model.name === this.originalModel.name
                && this.state.model.type === this.originalModel.type
                && this.state.model.info === this.originalModel.info
                && this.state.imageObjs.lg.name === this.originalModel.imageLgUrl
                && this.state.imageObjs.sm.name === this.originalModel.imageSmUrl
                && this.state.imageObjs.xs.name === this.originalModel.imageXsUrl
                && this.state.model.url === this.originalModel.url
                && this.state.model.active === this.originalModel.active) {
                this.setState({ errorMsg: "Өөрчлөлт байхгүй байна" });
                return false;
            }
        }
        this.setState({ errorMsg: "" });
        return true;
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }
        return (
            <Container>
                <Dimmer active={this.state.loading} inverted>
                    <Loader size='medium'></Loader>
                </Dimmer>
                <h2>{!this.state.editing ? 'Шинэ' : ''} Контент</h2>
                <Form widths='equal'>
                    <Form.Group>
                        {this.state.successMsg ? <div className="success-msg">{this.state.successMsg}</div> : null}
                        {this.state.errorMsg ? <div className="error-msg">{this.state.errorMsg}</div> : null}
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field required label='Нэр' control='input' value={this.state.model.name} onChange={this.onNameChange} />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field required label='URL' control='input' value={this.state.model.url} onChange={this.onUrlChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.TextArea label='Текст' value={this.state.model.info} onChange={this.onInfoChange} />
                    </Form.Group>
                    <Form.Group className="flex-space-between">
                        <div className="field">
                            <label>Жижиг хэмжээтэй зураг (400 x 120 pixel)</label>
                        </div>
                        <div className={this.state.imageObjs.xs.uploaded && this.state.imageObjs.xs.showOriginal ? "flex-space-between" : "hidden-div"}>
                            <ImageOnS3 imgKey={this.state.model.imageXsUrl} width="150px" height="45px"/>
                            <Icon className="cursor margin-10" name="trash" size='large' onClick={() => this.toggleImage('imageXsUrl')} />
                        </div>
                        <Icon className={this.state.imageObjs.xs.uploaded && !this.state.imageObjs.xs.showOriginal ? "" : "hidden-div"} name='redo' size='large' onClick={() => this.toggleImage('imageXsUrl')}/>
                        <Form.Field control='input' type="file" accept="image/png, image/jpeg" onChange={this.handleImageXsChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Checkbox checked={this.state.model.active} onChange={this.onActiveChange} label='Идэвхитэй' />
                    </Form.Group>
                    <br />
                    <Form.Button disabled={this.state.loading} onClick={() => this.save()}>Хадгалах</Form.Button>
                </Form>
            </Container >
        );
    }
}

export default ContentForm;