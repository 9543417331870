import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteProps, Redirect, Link } from 'react-router-dom';
import { Button, Icon, Form, Dimmer, Container, Loader, Table } from 'semantic-ui-react';
import store from '../../store';
import './account.scss';
import './company.scss';

interface CompanyProps {
  client: any
}

interface CompanyState {
  loading: boolean
  errorMsg: string,
  successMsg: string,
  redirect: string,
  creatingNewClient: boolean,
  password: string,
  passwordMatch: string,
  activeIndex: number
  users: any[]
}

const mapStateToProps = (state: any) => {
  return {
    client: state.client
  }
}

class CompanyUsersPage extends Component<CompanyProps & RouteProps, CompanyState> {

  clientToken: any;
  originalClient: any;

  constructor(props: any) {
    super(props);

    this.state = {
      successMsg: "",
      errorMsg: "",
      redirect: "",
      creatingNewClient: false,
      password: "",
      passwordMatch: "",
      loading: true,
      activeIndex: 0,
      users: [],
    };
  }

  componentDidMount() {
    var token = localStorage.getItem('clientToken')
    if (token && token !== 'undefined') {
      this.clientToken = token
      this.getCompanyUsers();
      window.scrollTo(0, 0)
    }
    else {
      console.log("Will redirect to: " + window.location.pathname)
      store.dispatch({ type: "redirectUrl", data: window.location.pathname })
      this.setState({ redirect: "/" })
    }
  }

  componentDidUpdate() {
    if (this.props.client && this.props.client.id) {
      if (!this.props.client.role || this.props.client.role !== 'MANAGER') {
        this.setState({ redirect: "/client/company" })
      }
    }
  }

  getCompanyUsers() {
    fetch(process.env.REACT_APP_CHEF_API + 'client/company/users', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': this.clientToken,
        'Content-Type': 'application/json'
      }
    })
      .then((res: any) => {
        this.setState({ loading: false })
        if (res.ok) {
          res.json().then((resJson: any) => {
            let users = resJson.data;
            users.forEach((p: any) => {
              p.newCount = p.count;
              p.editing = false;
              p.confirmingDelete = false;
            });
            this.setState({ users: users })
          })
        }
      })
      .catch(err => {
        this.setState({ errorMsg: "Сүлжээний алдаа", loading: false })
        console.error(err)
      })
  }

  scrollUp() {
    let element = document.getElementById("my-form");
    if (element) {
      element.scrollIntoView();
    }
  }

  public toggleDelete = (index: number) => {
    let users: any[] = this.state.users;
    users[index].confirmingDelete = !users[index].confirmingDelete;
    this.setState({ users: users });
  }

  public delete = (index: number) => {
    if (!this.state.users[index].id) {
      return;
    }
    var token = localStorage.getItem('clientToken')
    if (token && token !== 'undefined') {
      let toDelete = this.state.users[index];
      this.setState({ errorMsg: "", loading: true })
      fetch(`${process.env.REACT_APP_CHEF_API}client/company/user/unlink/${toDelete.id}`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': token,
          'Content-Type': 'application/json'
        }
      }).then(res => {
        this.setState({ loading: false })
        if (res.ok) {
          this.setState({ errorMsg: "" });
          let users = this.state.users
          users.splice(index, 1);
          this.setState({ users: users })
        }
        else {
          res.json().then((resJson: any) => {
            if (resJson.error) {
              this.setState({ errorMsg: resJson.error });
            }
          })
        }
      }).catch(err => {
        this.setState({ errorMsg: "Сүлжээний алдаа", loading: false })
        console.error(err);
      })
    }
  }

  parseRole = (role: string): string => {
    switch (role) {
      case 'MANAGER':
        return 'Менежер';
      case 'STAFF':
        return 'Ажилтан';
      case 'REQUESTED':
        return 'Шинэ ажилтан';
      default:
        return role;
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <div className="page margin-top-50-sm">
        <Container>
          <div className="flex-div width-100">
            <Dimmer active={this.state.loading}>
              <Loader size='medium'></Loader>
            </Dimmer>
            <section className="company-form" id="my-form">

              <div className="company-controls">
                <Link className="link-to" to="/client/company">
                  <span>Байгууллагын мэдээлэл</span>
                  <Icon name="angle right"></Icon>
                </Link>
              </div>

              <h3>Байгууллагын аккаунттай холбоотой хэрэглэгч нар:</h3>

              {this.state.errorMsg ?
                <Form.Group>
                  <div className="error-msg">
                    <Icon name="warning sign" color='red' />
                    {this.state.errorMsg}
                  </div>
                </Form.Group> : null
              }
              {this.state.successMsg ?
                <Form.Group>
                  <div className="success-msg">
                    <Icon name="shield alternate" color='green' />
                    {this.state.successMsg}
                  </div>
                </Form.Group> : null
              }

              <Table striped width={16}>
                <Table.Header className="hidden-xs">
                  <Table.Row>
                    <Table.HeaderCell>Нэр</Table.HeaderCell>
                    <Table.HeaderCell>Имэйл</Table.HeaderCell>
                    <Table.HeaderCell>Гар утас</Table.HeaderCell>
                    <Table.HeaderCell>Эрх</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {this.state?.users?.map((user: any, index: number) => {
                    return <Table.Row key={"user-" + index}>
                      <Table.Cell>
                        <strong>
                          <span style={{ 'color': '#484847' }} className="underline hover-blue">{user.parentName?.substring(0, 1) + "."} {user.ownName}</span>
                        </strong>
                      </Table.Cell>
                      <Table.Cell>{user.email}</Table.Cell>
                      <Table.Cell>{user.phone}</Table.Cell>
                      <Table.Cell title={user.role === 'REQUESTED' ? "Хүсэлтэд хариу өгөөгүй байна" : ""}
                        className={user.role === 'REQUESTED' ? "cursor" : ""}>
                        {this.parseRole(user.role)}
                        {user.role === 'REQUESTED' ?
                          <Icon name="info circle" ></Icon> : null}
                      </Table.Cell>
                      <Table.Cell>
                        { user.id !== this.props?.client?.id ?
                        user.confirmingDelete ?
                          <React.Fragment>
                            <Button primary size='mini' onClick={() => this.delete(index)}>Устга</Button>
                            <Button size='mini' onClick={() => this.toggleDelete(index)}>Болих</Button>
                          </React.Fragment> :
                          <Icon name="trash" className="cursor chef-icon-hover padding-5" onClick={() => this.toggleDelete(index)} />
                        : null}
                      </Table.Cell>
                    </Table.Row>
                  })
                  }
                </Table.Body>
              </Table>

              <br/>

              <div>
                <p> <Icon name="info circle" />
                Менежер нь бусад хэрэглэгчдийг бүртгэх, хасах эрхтэй байна.</p>
                <p>Ажилтан нь бусад хэрэглэгчдийг бүртгэх, хасах эрхгүй.</p>

                <p>
                  <Icon name="info circle" />
                  Байгууллагын бүх ажилтан болон менежер нар байгууллагын нэр дээр захиалга үүсгэх эрхтэй.
                </p>

              </div>

              <br />

              <Link to="/client/company/new-employee">
                <Button className="width-100" color='grey'>Шинэ ажилтанд аккаунт үүсгэх</Button>
              </Link>

            </section>
          </div>
        </Container>
      </div>
    );
  }
}

export default connect(mapStateToProps)(CompanyUsersPage);