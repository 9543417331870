import React, { Component } from 'react';
import "./no-content.scss";

class NoContent extends Component {
    render() {
        return (
            <div className="page no-content-page margin-top-50">
                No content found.
            </div>
        )
    }
}

export default NoContent;