export class ProductModel {
    id: any
    categoryId: any
    subCategoryId: any
    brandId: any
    providerId: any
    slug: string = ''
    name: string = ''
    shortName: string = ''
    unit: string = ''
    size: string = ''
    price: any
    saleActive: boolean = false
    salePrice: any
    saleUntil: any
    deliveryDayMargin: number = 1
    info: string = ''
    imageLgUrl: string = ''// нүүр зураг
    imageSmUrl: string = ''// нүүр зураг
    imageXsUrl: string = ''// нүүр зураг
    image1: string = ''// тайлбар зураг 1 - том/дунд
    image2: string = ''// тайлбар зураг 2 - том/дунд
    image3: string = ''// тайлбар зураг 3 - том/дунд
    image4: string = ''// тайлбар зураг 2 - жижиг
    image5: string = ''// тайлбар зураг 3 - жижиг
    image6: string = ''// тайлбар зураг 3 - жижиг
    active: boolean = true
    types: any[] = []
    usages: any[] = []
}