import React, { Component } from 'react';
import store from '../../store';
import { connect } from 'react-redux';
import { BrandModel } from '../../entities/brand.model';
import { ContentModel } from '../../entities/content.model';

const mapStateToProps = (state: any) => {
  return {
    client: state.client,
    refreshProduct: state.refreshProduct,
  }
}

interface ShopState {
}

interface ShopProps {
  children: any
  refreshProduct: number
}

const bucket = process.env.REACT_APP_S3_BUCKET
const region = process.env.REACT_APP_S3_REGION

class ShopComponent extends Component<ShopProps, ShopState> {
  public triedContents = 1;
  public triedCategories = 1;
  public triedBrands = 1;
  public triedProducts = 1;
  public triedSettings = 1;

  constructor(props: any) {
    super(props)
    this.state = { redirect: "" }
  }

  componentDidMount() {
    // restore cached data
    this.restoreContentsLocalStorage()
    this.restoreCategoriesLocalStorage()
    this.restoreBrandsLocalStorage()
    this.restoreProductsLocalStorage()
    this.restoreBasketFromLocalStorage();
    
    // fetch latest data
    this.fetchContents()
    
    setTimeout(() => {
      this.fetchCategories()
    }, 100);
  }

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.refreshProduct > this.props.refreshProduct) {
      this.fetchProducts();
    }
  }

  async fetchContents() {
    fetch(process.env.REACT_APP_CHEF_API + "contents")
    .then((response: any) => {
      if (response.ok) {
        setTimeout(() => {
          this.fetchBrands()
          this.fetchProducts()
        });
      }
      response.json().then((res: any) => {
        if (!res || !res.data) {
          return;
        }
        let contents: ContentModel[] = res.data;
        contents.forEach(b => {
          b.imageLgUrl = `https://${bucket}.s3.${region}.amazonaws.com/${b.imageLgUrl}`
          b.imageSmUrl = `https://${bucket}.s3.${region}.amazonaws.com/${b.imageSmUrl}`
          b.imageXsUrl = `https://${bucket}.s3.${region}.amazonaws.com/${b.imageXsUrl}`
        });
        store.dispatch({
          type: "contents",
          data: contents
        })
        // cache in local storage
        localStorage.setItem("CHEF_CONTENTS", JSON.stringify(contents))
      })
    })
    .catch((err: any) => {
      console.error(err)
      if (this.triedContents < 4) {
        this.triedContents++;
        this.fetchContents()
      }
    })
  }

  async fetchCategories() {
    fetch(process.env.REACT_APP_CHEF_API + "categories")
    .then((response: any) => {
      response.json().then((res: any) => {
        if (!res || !res.data) {
          return;
        }
        let categoryMap: any = {};
        let categories: any[] = [];
        // first find main categories
        for (let c of res.data) {
          c.imageLgUrl = `https://${bucket}.s3.${region}.amazonaws.com/${c.imageLgUrl}`
          c.imageSmUrl = `https://${bucket}.s3.${region}.amazonaws.com/${c.imageSmUrl}`
          c.imageXsUrl = `https://${bucket}.s3.${region}.amazonaws.com/${c.imageXsUrl}`
          if (!c.parentId) {
            categoryMap[c.id] = categories.length;
            c.subCategories = [];
            categories.push(c);
          }
        }
        // find sub categories
        for (let c of res.data) {
          if (c.parentId) {
            let index = categoryMap[c.parentId];
            if (index && c.active === 1) {
              categories[index].subCategories.push(c);
            }
          }
        }
        store.dispatch({
          type: "categories",
          data: categories
        })
        // cache in local storage
        localStorage.setItem("CHEF_CATEGORIES", JSON.stringify(categories))
      })
    })
    .catch((err: any) => {
      console.error(err)
      if (this.triedCategories < 4) {
        this.triedCategories++;
        this.fetchCategories()
      }
    })
  }

  async fetchBrands() {
    fetch(process.env.REACT_APP_CHEF_API + "brands")
    .then((response: any) => {
      response.json().then((res: any) => {
        if (!res || !res.data) {
          return;
        }
        let brands: BrandModel[] = res.data;
        brands.forEach(b => {
          b.imageLgUrl = `https://${bucket}.s3.${region}.amazonaws.com/${b.imageLgUrl}`
          b.imageSmUrl = `https://${bucket}.s3.${region}.amazonaws.com/${b.imageSmUrl}`
          b.imageXsUrl = `https://${bucket}.s3.${region}.amazonaws.com/${b.imageXsUrl}`
        });
        store.dispatch({
          type: "brands",
          data: brands
        })
        // cache in local storage
        localStorage.setItem("CHEF_BRANDS", JSON.stringify(brands))
      })
    })
    .catch((err: any) => {
      console.error(err)
      if (this.triedBrands < 4) {
        this.triedBrands++;
        this.fetchBrands()
      }
    })
  }

  async fetchProducts() {
    fetch(process.env.REACT_APP_CHEF_API + "products")
    .then((response: any) => {
      if (response.ok) {
        setTimeout(() => {
          this.fetchSettings()
        }, 2000);
      }
      response.json().then((res: any) => {
        if (!res || !res.data) {
          return;
        }
        let products: any[] = res.data;
        products.forEach(p => {
          if (p.saleActive === 1 && p.saleUntil) {
            let saleUntil = new Date(p.saleUntil);
            saleUntil.setDate(saleUntil.getDate() + 1)
            let today = new Date();
            p.saleActive = (saleUntil >= today)
          }
          else {
            p.saleActive = false;
          }
          p.active = p.active === 1;
          p.usages = (p.usages) ? String(p.usages).split(",") : [];
          p.types = (p.types) ? String(p.types).split(",") : [];
          p.imageLgUrl = `https://${bucket}.s3.${region}.amazonaws.com/${p.imageLgUrl}`
          p.imageSmUrl = `https://${bucket}.s3.${region}.amazonaws.com/${p.imageSmUrl}`
          p.imageXsUrl = `https://${bucket}.s3.${region}.amazonaws.com/${p.imageXsUrl}`
          if (p.image1) {
            p.image1 = `https://${bucket}.s3.${region}.amazonaws.com/${p.image1}`
          }
          if (p.image2) {
            p.image2 = `https://${bucket}.s3.${region}.amazonaws.com/${p.image2}`
          }
          if (p.image3) {
            p.image3 = `https://${bucket}.s3.${region}.amazonaws.com/${p.image3}`
          }
          if (p.image4) {
            p.image4 = `https://${bucket}.s3.${region}.amazonaws.com/${p.image4}`
          }
          if (p.image5) {
            p.image5 = `https://${bucket}.s3.${region}.amazonaws.com/${p.image5}`
          }
          if (p.image6) {
            p.image6 = `https://${bucket}.s3.${region}.amazonaws.com/${p.image6}`
          }
        });
        store.dispatch({
          type: "products",
          data: products
        })
        // cache in local storage
        localStorage.setItem("CHEF_PRODUCTS", JSON.stringify(products))
        // set age of the products data
        let date = new Date();
        let seconds = Math.round(date.getTime() / 1000);
        localStorage.setItem("CHEF_PRODUCTS_AGE", String(seconds))
      })
    })
    .catch((err: any) => {
      console.error(err)
      if (this.triedProducts < 4) {
        this.triedProducts++;
        this.fetchProducts();
      }
      else {
        window.alert("Интернет байхгүй байна")
      }
    })
  }

  async fetchSettings() {
    fetch(process.env.REACT_APP_CHEF_API + "settings")
    .then((response: any) => {
      response.json().then((res: any) => {
        if (!res || !res.data) {
          return;
        }
        store.dispatch({
          type: "settings",
          data: res.data
        })
      })
    })
    .catch((err: any) => {
      console.error(err)
      if (this.triedSettings < 4) {
        this.triedSettings++;
        this.fetchSettings()
      }
    })
  }

  restoreBasketFromLocalStorage() {
    //TODO: handle inactive products

    let basketStr = localStorage.getItem("CHEF_BASKET");
    if (basketStr) {
      let age = localStorage.getItem("CHEF_BASKET_AGE");
      if (age) {
        // check the age of the products data
        let date = new Date();
        let seconds = Math.round(date.getTime() / 1000);
        if (seconds - Number(age) > 3 * 60 * 60) {
          // cache is older than 3 hour so delete it
          localStorage.removeItem("CHEF_BASKET")
          return;
        }  
      }
      let basket = JSON.parse(basketStr);
      store.dispatch({
        type: "restoreBasket",
        basket: basket
      })
    }
  }

  restoreContentsLocalStorage() {
    let str = localStorage.getItem("CHEF_CONTENTS");
    if (str) {
      let list = JSON.parse(str);
      store.dispatch({
        type: "contents",
        data: list
      })
    }
  }

  restoreCategoriesLocalStorage() {
    let str = localStorage.getItem("CHEF_CATEGORIES");
    if (str) {
      let list = JSON.parse(str);
      store.dispatch({
        type: "categories",
        data: list
      })
    }
  }

  restoreBrandsLocalStorage() {
    let str = localStorage.getItem("CHEF_BRANDS");
    if (str) {
      let list = JSON.parse(str);
      store.dispatch({
        type: "brands",
        data: list
      })
    }
  }

  restoreProductsLocalStorage() {
    let age = localStorage.getItem("CHEF_PRODUCTS_AGE");
    if (age) {
      // check the age of the products data
      let date = new Date();
      let seconds = Math.round(date.getTime() / 1000);
      if (seconds - Number(age) > 24 * 60 * 60) {
        // cache is older than 24 hour so delete it
        localStorage.removeItem("CHEF_PRODUCTS_AGE")
        return;
      }  
    }
    let str = localStorage.getItem("CHEF_PRODUCTS");
    if (str) {
      let list = JSON.parse(str);
      store.dispatch({
        type: "products",
        data: list
      })
    }
  }

  render() {
    return (
      <React.Fragment>
          {this.props.children}
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(ShopComponent);