import React from 'react';

interface ImageOnS3Props {
    imgKey: string
    width: string
    height: string
}

class ImageOnS3 extends React.Component<ImageOnS3Props> {
    src: string = ""

    constructor(props: any) {
        super(props)
        const bucket = process.env.REACT_APP_S3_BUCKET
        const region = process.env.REACT_APP_S3_REGION
        this.src = `https://${bucket}.s3.${region}.amazonaws.com`
    }

    render() {
        return(
            <React.Fragment>
                { this.props.imgKey ?
                    <img src={`${this.src}/${this.props.imgKey}`} width={this.props.width} height={this.props.height} style={{'backgroundColor': '#cecece'}}/>
                    : 'хоосон' }
            </React.Fragment>
        )
    }
}

export default ImageOnS3;