import React from 'react';
import { Form, Container, Icon, Dimmer, Loader, Label, Button } from 'semantic-ui-react';
import MyForm from './form';
import ImageOnS3 from '../../../components/imageOnS3/imageOnS3.component';
import { ImageModel } from '../../../entities/image.model';
import MyFormState from './MyFormState';
import { Redirect } from 'react-router-dom';
import { SettingsModel } from '../../../entities/settings.model';

interface FormProps {
    match: any,
    history: any
}

class SettingsForm extends MyForm<FormProps, MyFormState> {
    entity = 'settings'
    navigationEntity = 'setting'

    constructor(props: any) {
        super(props);
        this.state = {
            model: new SettingsModel,
            successMsg: "",
            errorMsg: "",
            loading: true,
            editing: false,
            editingSlug: false,
            slugValid: true,
            imageObj: ImageModel.empty(),
            imageObjs: { lg: ImageModel.empty(), sm: ImageModel.empty(), xs: ImageModel.empty(),
                image1: ImageModel.empty(), image2: ImageModel.empty(), image3: ImageModel.empty(),
                image4: ImageModel.empty(), image5: ImageModel.empty(), image6: ImageModel.empty()
            },
            redirect: "",
        }

        this.onValueChange = this.onValueChange.bind(this);
    }

    onValueChange(e?: any) {
        let model: any = this.state.model;
        model.value = e.target.value;
        this.setState({ model: model });
    }

    validate(): boolean {
        this.setState({ successMsg: ""});
        if (!this.state.model.key) {
            this.setState({ errorMsg: "Нэр хоосон байна." });
            return false;
        }
        if (!this.state.model.value) {
            this.setState({ errorMsg: "Утга оруулна уу." });
            return false;
        }
       
        if (this.state.model.id !== undefined) {
            if (this.state.model.key === this.originalModel.key 
                && this.state.model.value === this.originalModel.value
                && this.state.model.info === this.originalModel.info
                ) {
                    this.setState({ errorMsg: "Өөрчлөлт байхгүй байна"});
                    return false;
                }
        }
        this.setState({ errorMsg: "" });
        return true;
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }
        return (
            <Container>
                <Dimmer active={this.state.loading} inverted>
                    <Loader size='medium'></Loader>
                </Dimmer>
                <h2>{!this.state.editing ? 'Шинэ' : ''} Тохиргоо</h2>
                <Form>
                    <Form.Group>
                        { this.state.successMsg ? <div className="success-msg">{this.state.successMsg}</div> : null }
                        { this.state.errorMsg ? <div className="error-msg">{this.state.errorMsg}</div> : null }
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <Form.Input required label='Нэр' value={this.state.model.key} disabled={true}/>
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <Form.Input required label='Утга' value={this.state.model.value} onChange={this.onValueChange} />
                    </Form.Group>

                    <Form.Group>
                        <Form.TextArea label='Тайлбар' width="16" value={this.state.model.info} onChange={this.onInfoChange} />
                    </Form.Group>
                
                    <br/>
                    <Form.Button disabled={this.state.loading} onClick={() => this.save()}>Хадгалах</Form.Button>
                </Form>
            </Container>
        );
    }
}

export default SettingsForm;