import React, { useState, useEffect } from 'react';
import { ClientModel } from '../../entities/client.model';
import './company.scss';
import { Form, Button, Container, Dimmer, Loader, Icon } from 'semantic-ui-react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => {
    return {
        client: state.client
    }
}

function NewEmployee(props: any) {
    const [client, setClient] = useState(new ClientModel());
    const [showFullForm, setShowFullForm] = useState(false);
    const [emailCheckResult, setEmailCheckResult] = useState({
        valid: false,
        exist: false
    });
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");

    const history = useHistory()

    useEffect((() => {
        if (props.client && props.client.id) {
            if (!props.client.role) {
                history.push("/client/company")
            }
        }
    }))

    const changeHandler = (e: any) => {
        setClient({ ...client, [e.target.name]: e.target.value })
    }

    const validateMyDetails = (): boolean => {
        setSuccessMsg("")
        if (!client.parentName) {
            setErrorMsg("Овог оруулна уу")
            return false;
        }
        if (!client.ownName) {
            setErrorMsg("Өөрийн нэр оруулна уу")
            return false;
        }
        if (!client.email) {
            setErrorMsg("Имэйл оруулна уу")
            return false;
        }
        if (!validateEmail()) {
            setErrorMsg("Имэйл буруу форматтай байна")
            return false;
        }
        if (!client.phone) {
            setErrorMsg("Та гар утасны дугаар оруулна уу")
            return false;
        }
        if (!validatePhone()) {
            setErrorMsg("Та гар утасны дугаараа шалгаж үзнэ үү")
            return false;
        }
        return true;
    }

    const checkEmail = () => {
        if (!client.email) {
            return;
        }

        var token = localStorage.getItem('clientToken')
        if (!token || token === 'undefined') {
            return;
        }

        setLoading(true)

        fetch(process.env.REACT_APP_CHEF_API + 'client/company/users/check-email', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: client.email })

        }).then(res => {
            setLoading(false)
            if (res.ok) {
                res.json().then(resJson => {
                    setErrorMsg("")
                    setEmailCheckResult(resJson)
                    if (!resJson.exist) {
                        // email is not registered
                        setShowFullForm(true)
                    }
                    else if (!resJson.valid) {
                        setErrorMsg(`${client.email} имэйл хаяг нь өөр байгууллагын аккаунттай холбоотой байна.`)
                    }
                })
            }
            else if (res.status === 409) {
                setEmailCheckResult({ exist: true, valid: false })
                res.json().then(resJson => {
                    if (resJson.linked) {
                        setErrorMsg(`${client.email} имэйл хаяг нь таны байгууллагын аккаунттай аль хэдийн холбоотой байна.`)
                    }
                    else {
                        setErrorMsg(`${client.email} имэйл хаягийг байгууллагын аккаунттай холбох хүсэлтийг илгээсэн байна.`)
                    }
                })
            }
            else {
                setErrorMsg("Сүлжээнд алдаа гарлаа")
            }
        }).catch(err => {
            console.error(err)
            setErrorMsg("Сүлжээнд алдаа")
            setSuccessMsg("")
            scrollUp();
        })
    }

    const saveNewEmployee = () => {
        if (!validateMyDetails()) {
            return;
        }

        var token = localStorage.getItem('clientToken')
        if (!token || token === 'undefined') {
            return;
        }

        let payload = {
            ownName: client.ownName,
            parentName: client.parentName,
            email: client.email,
            phone: client.phone
        }

        setErrorMsg("")
        setLoading(true)

        fetch(process.env.REACT_APP_CHEF_API + 'client/company/user', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)

        }).then(res => {
            setLoading(false)
            if (!res.ok) {
                res.json().then(resJson => {
                    setErrorMsg(resJson.errorMsg)
                })
            }
            else {
                setErrorMsg("")
                setSuccessMsg("Амжилттай хадгаллаа")
                setClient(new ClientModel())
                history.push('/client/company/users')
            }
        }).catch(err => {
            setErrorMsg("Сүлжээнд алдаа гарлаа")
            setSuccessMsg("")
            scrollUp();
        })
    };

    const scrollUp = () => {
        let element = document.getElementById("my-form");
        if (element) {
            element.scrollIntoView();
        }
    }

    const validateEmail = (): boolean => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(client.email).toLowerCase());
    }

    const validatePhone = (): boolean => {
        var re = /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/;
        return re.test(String(client.phone).toLowerCase());
    }

    return (
        <div className="page margin-top-50-sm">
            <Container>
                <Dimmer active={loading}>
                    <Loader size='medium'></Loader>
                </Dimmer>
                <div className="flex-div width-100">
                    <section className="company-form slim" id="my-form">

                        <Link className="link-to" to="/client/company/users">
                            <span>Байгууллагын хэрэглэгч нар</span>
                            <Icon name="angle right"></Icon>
                        </Link>

                        <h2>Шинэ хэрэглэгч үүсгэх</h2>

                        {showFullForm ?
                            <Form widths='equal'>
                                <div className="form-group">
                                    {successMsg ? <div className="success-msg">{successMsg}</div> : null}
                                    {errorMsg ? <div className="error-msg">{errorMsg}</div> : null}
                                </div>
                                <div className="form-group">
                                    <div className="label">Имэйл</div>
                                    <input type="email" required placeholder='Имэйл' name="email" value={client.email} onChange={changeHandler} />
                                </div>
                                <div className="form-group">
                                    <div className="label">Гар утас</div>
                                    <input type="text" required placeholder='Гар утас' name="phone" value={client.phone} onChange={changeHandler} />
                                </div>
                                <div className="form-group">
                                    <div className="label">Овог</div>
                                    <Form.Input type="text" required placeholder='Овог' name="parentName" value={client.parentName} onChange={changeHandler} />
                                </div>
                                <div className="form-group">
                                    <div className="label">Нэр</div>
                                    <input type="text" required placeholder='Нэр' name="ownName" value={client.ownName} onChange={changeHandler} />
                                </div>
                                <div className="form-group">
                                    <Button className="width-100" color='red' onClick={() => saveNewEmployee()} disabled={loading}>Шинэ ажилтанд аккаунт үүсгэх</Button>
                                </div>
                            </Form>

                            :

                            <React.Fragment>
                                {emailCheckResult.exist ?
                                    <React.Fragment>
                                        {emailCheckResult.valid ?
                                            <div className="success-msg">
                                                <p>
                                                    <Icon name="info circle" />
                                                    Хэрэглэгчийн имэйл руу хүсэлт илгээлээ.
                                                </p>
                                                <p>
                                                    Хэрэглэгч тус имэйл дэх линкийг дарж идэвхижүүлснээр таны байгууллагын аккаунттай холбогдоно.
                                                </p>
                                            </div>

                                            :

                                            <div className="warning-msg">
                                                <p>
                                                    <Icon name="warning circle" />
                                                    { errorMsg }
                                                </p>
                                                <p>Танд тодруулах зүйл байвал веб сайтын админтай холбогдоно уу.</p>
                                            </div>
                                        }

                                        <Link to="/client/company/users">
                                            <Button color="grey" className="width-100">Буцах</Button>
                                        </Link>
                                    </React.Fragment>

                                    :

                                    <div>
                                        <div>
                                            <Icon name="info circle" />
                                            <span>Та шинээр үүсгэх ажилтаны имэйл хаягийг оруулна уу</span>
                                        </div>
                                        <Form widths='equal'>
                                            <div className="form-group">
                                                {successMsg ? <div className="success-msg">{successMsg}</div> : null}
                                                {errorMsg ? <div className="error-msg">{errorMsg}</div> : null}
                                            </div>

                                            <div className="form-group">
                                                <div className="label">Имэйл</div>
                                                <input type="email" required placeholder='Имэйл' name="email" value={client.email} onChange={changeHandler} />
                                            </div>
                                            <div className="form-group">
                                                <Button className="width-100" color='red' onClick={() => checkEmail()} disabled={loading}>Бүртгэх</Button>
                                            </div>
                                        </Form>
                                    </div>
                                }
                            </React.Fragment>
                        }
                    </section>
                </div>
            </Container>
        </div>
    );
}

export default connect(mapStateToProps)(NewEmployee);