import React from 'react';
import { Button, Table, Icon, Label } from 'semantic-ui-react';
import 'semantic-ui-css/components/table.min.css';
import { Link, Redirect } from 'react-router-dom';
import MyList from './list';
import S3Manager from '../s3/s3manager';

class CategoryList extends MyList {

    entity = "category";

    refresh() {
        var token = localStorage.getItem('adminToken')
        if (token && token !== 'undefined') {
            fetch(process.env.REACT_APP_CHEF_API + "list/category", {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': token,
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                if (res.status === 401) {
                    this.setState({ redirect: this.loginUrl })
                }
                return res
            })
            .then(res => res.json())
            .then(res => {
                console.log(res);
                if (!res || !res.data) {
                    return;
                }
                // organize as parent-child nodes
                let categories: any[] = [];
                let cmap = new Map<any, any>();
                for (let c of res.data) {
                    if (!c.parentId) {
                        cmap.set(c.id, categories.length);
                        categories.push({
                            id: c.id,
                            name: c.name,
                            active: c.active,
                            confirmingDelete: false,
                            subCategories: [],
                            extended: true,
                            imageLgUrl: c.imageLgUrl,
                            imageSmUrl: c.imageSmUrl,
                            imageXsUrl: c.imageXsUrl
                        });
                    }
                }
                for (let c of res.data) {
                    if (c.parentId && cmap.has(c.parentId)) {
                        categories[cmap.get(c.parentId)].subCategories.push({
                            id: c.id,
                            name: c.name,
                            active: c.active,
                            confirmingDelete: false,
                            subCategories: [],
                            extended: true,
                            imageLgUrl: c.imageLgUrl,
                            imageSmUrl: c.imageSmUrl,
                            imageXsUrl: c.imageXsUrl
                        });
                    }
                }
                this.setState({ data: categories });
            })
            .catch(err => console.error(err));
        }
        else {
            this.setState({ redirect: this.loginUrl });
        }
    }

    public toggleDelete = (index: number, subIndex: number = -1) => {
        let data = this.state.data;
        if (subIndex === -1) {
            data[index].confirmingDelete = !data[index].confirmingDelete;
        } else {
            data[index].subCategories[subIndex].confirmingDelete = !data[index].subCategories[subIndex].confirmingDelete;
        }
        this.setState({ data: data });
    }

    public delete = (index: number, subIndex: number = -1) => {
        if (!this.state.data[index].id) {
            return;
        }
        var token = localStorage.getItem('adminToken')
        if (token && token !== 'undefined') {
            let toDelete: any = subIndex === -1 ? this.state.data[index] : this.state.data[index].subCategories[subIndex];
            fetch(process.env.REACT_APP_CHEF_API + "delete/category/" + toDelete.id, {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': token,
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                if (res.status === 401) {
                    this.setState({ redirect: this.loginUrl })
                }
                else if (res.status === 200) {
                    this.setState({ errorMsg: "" });
                    this.deleteImagesAfterwards(toDelete);
                    this.refresh();
                    // delete images
                }
                else {
                    res.json().then(resJson => {
                        if (resJson.error) {
                            this.setState({ errorMsg: resJson.error});
                        }
                    })
                }
            })
        }
        else {
            this.setState({ redirect: this.loginUrl });
        }
    }

    async deleteImagesAfterwards(record: any) {
        if (record.imageLgUrl) {
            S3Manager.deleteImage(record.imageLgUrl);
        }
        if (record.imageSmUrl) {
            S3Manager.deleteImage(record.imageSmUrl);
        }
        if (record.imageXsUrl) {
            S3Manager.deleteImage(record.imageXsUrl);
        }

        if (record.subCategories) {
            record.subCategories.forEach((sub: any) => {
                this.deleteImagesAfterwards(sub);
            });
        }
    }

    public toggleExtend(index: number) {
        let data = this.state.data;
        data[index].extended = !data[index].extended;
        this.setState({ data: data });
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }
        return (
            <div className="full-width">
                <h2>Үндсэн категориуд</h2>
                {/* Нийт: {this.state?.data?.listCategories?.items.length} */}
                <Link to="/mongol/categories/new">
                    <Button basic color='orange'>Шинээр үүсгэх</Button>
                </Link>

                {this.state.errorMsg ? <div className="error-msg">
                    <Icon name="warning circle"/>&nbsp;
                    {this.state.errorMsg}
                </div> : null}

                <Table striped width={16}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>№</Table.HeaderCell>
                            <Table.HeaderCell>Категорийн нэр</Table.HeaderCell>
                            <Table.HeaderCell>Дэд категорийн тоо</Table.HeaderCell>
                            <Table.HeaderCell>Идэвхитэй</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        { this.state?.data?.map((row: any, index: number) => {
                                return <React.Fragment key={row.id}>
                                    <Table.Row>
                                    <Table.Cell> { String(index+1) } </Table.Cell>
                                    <Table.Cell>
                                        <strong>
                                            <Link className="underline hover-blue" to={"/mongol/category/" + row.id}>{ row.name }</Link>
                                        </strong>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Label className="cursor hover-blue-background" onClick={()=>this.toggleExtend(index)}>{ row.subCategories.length }</Label>
                                    </Table.Cell>
                                    <Table.Cell> { row.active ? <Icon name="checkmark"/> : null } </Table.Cell>
                                    <Table.Cell>
                                        { row.confirmingDelete ? 
                                            <React.Fragment>
                                                <Button primary size='mini' onClick={() => this.delete(index)}>Устга</Button>
                                                <Button size='mini' onClick={() => this.toggleDelete(index)}>Болих</Button>
                                            </React.Fragment> :
                                            <Icon name="trash" className="cursor chef-icon-hover padding-5" onClick={() => this.toggleDelete(index)}/>
                                        }
                                    </Table.Cell>
                                </Table.Row>
                                { row.extended && row.subCategories && row.subCategories.length > 0 ? row.subCategories.map((sub: any, subIndex: number) => {
                                    return <Table.Row key={sub.id}>
                                        <Table.Cell> { String(index+1) + "." + String(subIndex+1) } </Table.Cell>
                                        <Table.Cell>
                                            <Link className="underline hover-blue" to={"/mongol/category/" + sub.id}>{ sub.name }</Link>
                                        </Table.Cell>
                                        <Table.Cell> {row.name} </Table.Cell>
                                        <Table.Cell>{ sub.active ? <Icon name="checkmark"/> : null }</Table.Cell>
                                        <Table.Cell>
                                            { sub.confirmingDelete ? 
                                                <React.Fragment>
                                                    <Button primary size='mini' onClick={() => this.delete(index, subIndex)}>Устга</Button>
                                                    <Button size='mini' onClick={() => this.toggleDelete(index, subIndex)}>Болих</Button>
                                                </React.Fragment> :
                                                <Icon name="trash" className="cursor chef-icon-hover padding-5" onClick={() => this.toggleDelete(index, subIndex)}/>
                                            }
                                        </Table.Cell>
                                    </Table.Row>
                                }) : null}
                                </React.Fragment>
                            })
                        }
                    </Table.Body>
                </Table>
            </div>
        );
    }
}
            
export default CategoryList;