import React from 'react';
import { Form, Container, Dimmer, Loader, Icon } from 'semantic-ui-react';
import MyFormState from './MyFormState';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import MyForm from './form';
import { ImageModel } from '../../../entities/image.model';

const MyLabel = styled.div`
    width: 140px;
    font-weight: 600;
`;

interface FormProps {
    match: any,
    history: any
}

class CompanyForm extends MyForm<FormProps, MyFormState> {
    entity = 'client_company'

    constructor(props: any) {
        super(props);
        this.state = {
            model: {
                active: false,
                approved: false,
                canPayLater: false,
            },
            successMsg: "",
            errorMsg: "",
            loading: true,
            editing: false,
            editingSlug: false,
            slugValid: true,
            imageObj: ImageModel.empty(),
            imageObjs: { lg: ImageModel.empty(), sm: ImageModel.empty(), xs: ImageModel.empty(),
                image1: ImageModel.empty(), image2: ImageModel.empty(), image3: ImageModel.empty(),
                image4: ImageModel.empty(), image5: ImageModel.empty(), image6: ImageModel.empty()
            },
            redirect: "",
        }

        this.onPayLaterChange = this.onPayLaterChange.bind(this)
    }

    onPayLaterChange(e?: any) {
        let model: any = this.state.model;
        model.canPayLater = Boolean(e.target.checked);
        this.setState({ model: model });
    }

    validate(): boolean {
        this.setState({ successMsg: "" });
        if (!this.state.model.name) {
            this.setState({ errorMsg: "Байгууллагын нэрийг оруулна уу." });
            return false;
        }
        if (!this.state.model.register) {
            this.setState({ errorMsg: "Байгууллагын регистр оруулна уу." });
            return false;
        }
        if (!this.state.model.town) {
            this.setState({ errorMsg: "Хот оруулна уу" });
            return false;
        }
        if (!this.state.model.district) {
            this.setState({ errorMsg: "Дүүрэг оруулна уу" });
            return false;
        }
        if (!this.state.model.unit) {
            this.setState({ errorMsg: "Хороо оруулна уу" });
            return false;
        }
        if (!this.state.model.street) {
            this.setState({ errorMsg: "Дэлгэрэнгүй хаяг оруулна уу" });
            return false;
        }
        // if (this.state.model.id !== undefined) {
        //     if (this.state.model.active === this.originalModel.active 
        //         && this.state.model.approved === this.originalModel.approved
        //         && this.state.model.canPayLater === this.originalModel.canPayLater
        //         ) {
        //             this.setState({ errorMsg: "Өөрчлөлт байхгүй байна"});
        //             return false;
        //         }
        // }
        if (this.state.model.id !== undefined) {
            if (this.state.model.name === this.originalModel.name
                && this.state.model.register === this.originalModel.register
                && this.state.model.approved === this.originalModel.approved
                && this.state.model.active === this.originalModel.active
                && this.state.model.canPayLater === this.originalModel.canPayLater
                && this.state.model.town === this.originalModel.town
                && this.state.model.district === this.originalModel.district
                && this.state.model.unit === this.originalModel.unit
                && this.state.model.street === this.originalModel.street
                ) {
                this.setState({ errorMsg: "Өөрчлөлт байхгүй байна" });
                return false;
            }
        }
        this.setState({ errorMsg: "" });
        return true;
    }

    async save() {
        if (!this.validate()) {
            window.scrollTo(0, 0);
            return;
        }

        var token = this.getAdminToken()
        this.setState({ loading: true });

        // POST to API
        let payload = {
            companyId: this.state.model.id,
            name: this.state.model.name,
            register: this.state.model.register,
            active: this.state.model.active ? 1 : 0,
            approved: this.state.model.approved ? 1 : 0,
            canPayLater: this.state.model.canPayLater ? 1 : 0,
            town: this.state.model.town,
            district: this.state.model.district,
            unit: this.state.model.unit,
            street: this.state.model.street,
        }

        fetch(process.env.REACT_APP_CHEF_API + 'mongol/company', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
        .then(res => {
            if (res.status === 200) {
                this.originalModel.active = this.state.model.active;
                this.originalModel.approved = this.state.model.approved;
                this.originalModel.canPayLater = this.state.model.canPayLater;
                if (this.state.model.id !== undefined) {
                    this.setState({ loading: false, successMsg: "Амжилттай хадгаллаа." })
                    window.scrollTo(0,0);
                } else {
                    window.location.pathname = `/mongol/companies`
                }
            }
            else if (res.status === 401) {
                this.setState({ redirect: this.loginUrl})
            }
            else {
                res.json().then(res => {
                    if (res.error) {
                        this.setState({ loading: false, errorMsg: res.error })
                        this.recordSaved = false;
                        window.scrollTo(0,0);
                        return;
                    }
                    
                })
            }
        })
        .catch(err => {
            this.setState({ loading: false });
            console.error(err);
        });
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }
        return (
            <Container>
                <Dimmer active={this.state.loading} inverted>
                    <Loader size='medium'></Loader>
                </Dimmer>
                <h2>{!this.state.editing ? 'Шинэ' : ''} Байгууллага</h2>
                <Form widths='equal'>
                    <Form.Group>
                        { this.state.successMsg ? <div className="success-msg">{this.state.successMsg}</div> : null }
                        { this.state.errorMsg ? <div className="error-msg">{this.state.errorMsg}</div> : null }
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field label='Байгууллагын нэр' required control='input' value={this.state.model.name} onChange={this.onNameChange} />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field label='Байгууллагын регистр' required control='input' value={this.state.model.register} onChange={this.onRegisterChange} />
                    </Form.Group>
                    <Form.Group>
                        <input type="checkbox" id="company-active" onChange={this.onActiveChange} checked={this.state.model.active} />
                        &nbsp;
                        <label htmlFor="company-active">{ this.state.model.active ? "Идэвхитэй" : "Идэвхигүй" }</label>
                    </Form.Group>

                    <div style={{'height': '35px'}}>
                        <strong>Байгууллагын бүртгэлийг шалгаж баталгаажуулсан эсэх:</strong>
                    </div>
                    <Form.Group>
                        <input type="checkbox" id="company-approved" onChange={this.onApprovedChange} checked={this.state.model.approved} />
                        &nbsp;
                        <label htmlFor="company-approved">{ this.state.model.approved ? "Шалгасан" : "Шалгаагүй" }</label>
                    </Form.Group>
                    <Form.Group>
                        <input type="checkbox" id="company-pay" onChange={this.onPayLaterChange} checked={this.state.model.canPayLater} />
                        &nbsp;
                        <label htmlFor="company-pay">{"Төлбөрөө дараа нь төлөх" + (this.state.model.canPayLater ? " эрхтэй" : " эрхгүй")}</label>
                    </Form.Group>
                    <Form.Group>
                        <MyLabel>Хаяг:</MyLabel>
                    </Form.Group>
                    <Form.Group>
                        <Form.Input label='Хот' required value={this.state.model.town} onChange={this.onTownChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Input label='Дүүрэг' required value={this.state.model.district} onChange={this.onDistrictChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Input label='Хороо' required value={this.state.model.unit} onChange={this.onAddressUnitChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.TextArea label='Дэлгэрэнгүй хаяг' required value={this.state.model.street} onChange={this.onStreetChange} />
                    </Form.Group>
                    {/* <Form.Group>
                        <MyLabel>Зипкод</MyLabel>
                        <div>{this.state.model.zipcode}</div>
                    </Form.Group> */}

                    <br/>
                    <Form.Button disabled={this.state.loading} onClick={() => this.save()}>Хадгалах</Form.Button>
                </Form>
            </Container>
        );
    }
}

export default CompanyForm;