import { AddressModel } from '../entities/address.model';
import { ClientModel } from '../entities/client.model';
import { ProviderUserModel } from '../entities/provider.user.model';

class BasketModel {
    public addedIds: Set<Number> = new Set<number>();
    public quantityById: Map<Number, Number> = new Map<Number, Number>();
    public productMap: Map<Number, any> = new Map<Number, any>();
    public totalAmount: Number = 0;
    public address: AddressModel = new AddressModel();;
    public addressValue: string = '';

    constructor() {
        this.totalAmount = 0;
    }
}

const initialState = {
    sidebarOpen: false,
    basketOpen: false,
    categories: [],
    categorySlugMap: new Map<string, number>(),
    categoryId: null,
    subCategoryId: null,
    products: [],
    productMap: new Map<Number, any>(),
    brands: [],
    brandSlugMap: new Map<string, number>(),
    featured: [],
    banners: [],
    settings: [],
    basket: new BasketModel(),
    clientToken: "",
    client: ClientModel,
    provider: ProviderUserModel,
    refreshProduct: 0,
    redirectUrl: "",
};

// CLIENT
function authenticate(stateClient: any, user: any) {
    if (!user || !user.id || !user.email) {
        return stateClient;
    }
    
    return user;
}

function logout() {
    return new ClientModel()
}

function logoutProvider() {
    return new ClientModel()
}

// BASKET
function addToBasket(basket: any, action: any): any {
    if (!action.product) {
        return;
    }

    if (!basket) {
        localStorage.removeItem('CHEF_BASKET')
        basket = new BasketModel();
    }

    let count = Number(action.count || 1);

    if (basket.quantityById[action.product.id] !== null && basket.quantityById[action.product.id] !== undefined) {
        // existing product
        basket.quantityById[action.product.id] += count;

    } else {
        // new product in the basket
        basket.addedIds.add(action.product.id);
        basket.quantityById[action.product.id] = count;
        basket.productMap[action.product.id] = parseProduct(action.product);
        basket.productMap[action.product.id].count = count;
    }

    basket.totalAmount += (action.product.saleActive ? action.product.salePrice : action.product.price) * count;

    // save basket to local storage
    saveBasketInLocalStorage(basket)

    return basket;
}

function parseProduct(product: any) {
    return {
        id: product.id,
        name: product.name,
        slug: product.slug,
        shortName: product.shortName,
        price: product.price,
        saleActive: product.saleActive,
        salePrice: product.salePrice,
        saleUntil: product.saleUntil,
        deliverDayMargin: product.deliveryDayMargin,
        imageXsUrl: product.imageXsUrl,
    }
}

function updateBasketItemCount(basket: any, action: any): any {
    if (!action.product || !basket) {
        return;
    }

    let total = 0;

    if (action.count === "0") {
        basket.quantityById[action.product.id] = 0;
        basket.productMap[action.product.id].count = 0;
        for (let itemId of basket.addedIds.values()) {
            if (itemId !== action.product.id) {
                total += basket.quantityById[itemId] * (basket.productMap[itemId].saleActive ? basket.productMap[itemId].salePrice : basket.productMap[itemId].price)
            }
        }
    }
    else {
        if (!basket.addedIds.has(action.product.id)) {
            basket.addedIds.add(action.product.id);
        }
        if (!basket.productMap[action.product.id]) {
            basket.productMap[action.product.id] = parseProduct(action.product);
        }
        basket.quantityById[action.product.id] = Number(action.count || 0);
        basket.productMap[action.product.id].count = Number(action.count || 0);
        for (let itemId of basket.addedIds.values()) {
            total += basket.quantityById[itemId] * (basket.productMap[itemId].saleActive ? basket.productMap[itemId].salePrice : basket.productMap[itemId].price)
        }
    }

    basket.totalAmount = total;

    // save basket to local storage
    saveBasketInLocalStorage(basket)

    return basket;
}

function updateBasketAddress(basket: any, action: any) {
    basket.address = action.data
    basket.addressValue = action.value
    // save basket to local storage
    saveBasketInLocalStorage(basket)

    return basket;
}

function removeFromBasket(basket: any, action: any): any {
    if (!action.product) {
        return;
    }

    if (!basket) {
        localStorage.removeItem('CHEF_BASKET')
        return new BasketModel();
    }

    let count: number = basket.quantityById[action.product.id];
    if (count && count > 1) {
        // decrease
        basket.quantityById[action.product.id]--;
        basket.productMap[action.product.id].count--;
    } else {
        // remove
        delete basket.quantityById[action.product.id];
        delete basket.productMap[action.product.id];
        basket.addedIds.delete(action.product.id);
    }

    basket.totalAmount -= (action.product.saleActive ? action.product.salePrice : action.product.price);

    // save basket to local storage
    saveBasketInLocalStorage(basket)

    return basket;
}

function removeItemFromBasket(basket: any, action: any) {
    if (!action.product) {
        return;
    }

    if (!basket) {
        localStorage.removeItem('CHEF_BASKET')
        return new BasketModel();
    }
    
    basket.addedIds.delete(action.product.id);

    if (basket.quantityById[action.product.id] !== null) {
        basket.totalAmount -= (action.product.saleActive ? action.product.salePrice : action.product.price) * basket.quantityById[action.product.id];
        delete basket.quantityById[action.product.id];
        delete basket.productMap[action.product.id]
    }

    // save basket to local storage
    saveBasketInLocalStorage(basket)
    
    return basket;
}

function updateProductWarehouseCount(state: any, action: any) {
    if (action.productId === undefined || action.count === undefined || action.productId === undefined) {
        return state;
    }

    // if (state.productMap.has(action.productId)) {
    //     console.debug(`Updated count of ${action.productId} into ${action.count} in product map`)
    //     state.productMap[action.productId].warehouseCount = action.count;
    // }

    for (let product of state.products) {
        if (product.id === action.productId) {
            product.warehouseCount = action.count
            console.log(`Updated count of ${action.productId} into ${action.count} in products`)
            break;
        }
    }
    
    return state;
}

function recalculateBasketAmount(basket: any) {
    let total = 0;

    if (basket.addedIds) {
        for (let itemId of basket.addedIds.values()) {
            total += basket.quantityById[itemId] * (basket.productMap[itemId].saleActive ? basket.productMap[itemId].salePrice : basket.productMap[itemId].price)
        }
    }

    basket.totalAmount = total;

    return total;
}

function restoreBasket(basket: any) {
    if (!basket?.addedIds) {
        return new BasketModel();
    }

    if (basket.productIdArr && basket.productIdArr.length > 0) {
        var addedIds = new Set();

        for (let productId of basket.productIdArr) {
            if (basket.productMap && basket.quantityById && basket.productMap[productId] && basket.quantityById[productId]) {
                addedIds.add(productId);
            }
        }

        basket.addedIds = addedIds;
        basket.totalAmount = recalculateBasketAmount(basket);
        return basket;
    }
    else {
        return new BasketModel();
    }
}

async function saveBasketInLocalStorage(basket: any) {
    basket.productIdArr = Array.from(basket.addedIds)
    localStorage.setItem('CHEF_BASKET', JSON.stringify(basket))
    let date = new Date();
    let seconds = Math.round(date.getTime() / 1000);
    localStorage.setItem("CHEF_BASKET_AGE", String(seconds))
}

// DATA
function prepareContents(state: any, contents: any[]) {
    let featured: any[] = [];
    let banners: any[] = [];
    for (let content of contents) {
        if (String(content.type) === "1") {
            banners.push(content);
        } else if (String(content.type) === "2") {
            featured.push(content);
        }
    }
    state.featured = featured;
    state.banners = banners;
    return state;
}

function prepareMainProducts(state: any) {
    if (state.products.length > 0 && state.categories.length > 0) {
        for (let category of state.categories) {
            category.topProducts = []
            // the main category doesn't have any sub categories then find its top products
            if (category.parentId === undefined || category.parentId === null) {
                if (!category.subCategories || category.subCategories.length === 0) {
                    for (let product of state.products) {
                        if (product.categoryId === category.id) {
                            category.topProducts.push(product)
                            if (category.topProducts.length === 6) {
                                break;
                            }
                        }
                    }
                }
            }
        }
    }
}

function prepareProductMap(products: any[]) {
    let productMap = new Map<Number, any>()

    for (let p of products) {
        productMap.set(p.id, parseProduct(p))
    }

    return productMap
}

function prepareSettings(state: any, settings: any[]) {
    let companyName;
    let facebookMsgLink;
    let khanbankAccountNumber;
    let khanbankAccountName;
    let supportEmail;
    let supportPhone;
    let deliveryMaxDays;
    let freeDeliveryMinAmount;
    let deliveryPrice;
    let deliveryTime;
    let dayOffs;

    if (settings) {
        for (let setting of settings) {
            if (setting.key === "KHAN_BANK_ACCOUNT_NUMBER") {
                khanbankAccountNumber = setting.value
            }
            else if (setting.key === "KHAN_BANK_ACCOUNT_NAME") {
                khanbankAccountName = setting.value
            }
            else if (setting.key === "CHEF_ADMIN_EMAIL") {
                supportEmail = setting.value
            }
            else if (setting.key === "CHEF_ADMIN_PHONE") {
                supportPhone = setting.value
            }
            else if (setting.key === "DELIVERY_MAX_DAYS") {
                deliveryMaxDays = Number(setting.value)
            }
            else if (setting.key === "FREE_DELIVERY_MIN") {
                freeDeliveryMinAmount = Number(setting.value)
            }
            else if (setting.key === "DELIVERY_PRICE") {
                deliveryPrice = Number(setting.value)
            }
            else if (setting.key === "DELIVERY_TIME") {
                deliveryTime = setting.value
            }
            else if (setting.key === "DAYOFFS") {
                dayOffs = setting.value
            }
        }
    }

    state.settings = {
        khanbankAccountName: khanbankAccountName,
        khanbankAccountNumber: khanbankAccountNumber,
        supportEmail: supportEmail,
        supportPhone: supportPhone,
        freeDeliveryMinAmount: freeDeliveryMinAmount,
        deliveryMaxDays: deliveryMaxDays,
        deliveryPrice: deliveryPrice,
        deliveryTime: deliveryTime,
        dayOffs: dayOffs,
        facebookMsgLink: facebookMsgLink,
        companyName: companyName,
    }
}

function prepareBrands(brands: any[]) {
    let brandSlugMap = new Map<string, number>();

    for (let i=0; i<brands.length; i++) {
        brandSlugMap.set(brands[i].slug, i);
    }

    return {
        brands: brands,
        brandSlugMap: brandSlugMap,
    }
}

function prepareCategories(categories: any[]) {
    let categorySlugMap = new Map<string, number>();

    for (let i=0; i<categories.length; i++) {
        categorySlugMap.set(categories[i].slug, i);
        if (categories[i].subCategories) {
            categories[i].subCategorySlugMap = new Map<string, number>();
            for (let j=0; j<categories[i].subCategories.length; j++) {
                categories[i].subCategorySlugMap.set(categories[i].subCategories[j].slug, j);
            }
        }
    }

    return {
        categories: categories,
        categorySlugMap: categorySlugMap,
    }
}

// SAVED PRODUCT
function setSavedProducts(client: any, savedProducts: any) {
    client.savedProducts = savedProducts
    return client
}

function addSavedProduct(client: any, productId: number) {
    client.savedProducts.push({
        productId: productId,
        userId: client.id
    })
    return client;
}

function removeSavedProduct(client: any, productId: number) {
    for (let i = 0; i < client.savedProducts.length; i++) {
        let p = client.savedProducts[i];
        if (p.productId === productId) {
            client.savedProducts.splice(i, 1);
            break;
        }
    }
    return client
}

// MAIN
function rootReducer(state = initialState, action: any) {
    switch (action.type) {
        case "REFRESH_PRODUCTS":
            return Object.assign({}, state, { refreshProduct: state.refreshProduct + 1})
        case "categories":
            state = Object.assign(state, prepareCategories(action.data))
            prepareMainProducts(state)
            return state
        case "brands":
            return Object.assign(state, prepareBrands(action.data))
        case "products":
            state = Object.assign({}, state, {
                products: action.data
            })
            state.productMap = prepareProductMap(action.data)
            prepareMainProducts(state)
            return state
        case "contents":
            return Object.assign({}, state, prepareContents(state, action.data))
        case "settings":
            return Object.assign({}, state, prepareSettings(state, action.data))
        case 'sidebarOpen':
            return Object.assign({}, state, {
                sidebarOpen: action.value
            });
        case 'basketOpen':
            return Object.assign({}, state, {
                basketOpen: action.value
            });
        case 'clearBasket':
            saveBasketInLocalStorage(new BasketModel())
            return Object.assign({}, state, {
                basket: new BasketModel()
            });
        case 'restoreBasket':
            return Object.assign({}, state, {
                basket: restoreBasket(action.basket)
            });
        case 'addToBasket':
            return Object.assign({}, state, {
                basket: addToBasket(state.basket, action)
            });
        case 'removeFromBasket':
            return Object.assign({}, state, {
                basket: removeFromBasket(state.basket, action)
            });
        case 'updateBasketItemCount':
            return Object.assign({}, state, {
                basket: updateBasketItemCount(state.basket, action)
            })
        case 'removeItemFromBasket':
            return Object.assign({}, state, {
                basket: removeItemFromBasket(state.basket, action)
            });
        case 'basketAddress':
            return Object.assign({}, state, {
                basket: updateBasketAddress(state.basket, action)
            })
        case 'updateProductWarehouseCount':
            return Object.assign({}, updateProductWarehouseCount(state, action))
        case 'logout':
            logout();
            return Object.assign({}, state, {
                client: new ClientModel()
            });
        case 'authenticateClient':
            return Object.assign({}, state, {
                client: authenticate(state.client, action.client)
            });
        case 'authenticateProvider':
            return Object.assign({}, state, {
                provider: action.provider
            });
        case 'logoutProvider':
            return Object.assign({}, state, {
                provider: new ProviderUserModel()
            }); 
        case 'clientToken':
            return Object.assign({}, state, {
                clientToken: action.clientToken
            });
        case 'savedProducts':
            return Object.assign({}, state, {
                client: setSavedProducts(state.client, action.data)
            });
        case 'addSavedProduct':
            return Object.assign({}, state, {
                client: addSavedProduct(state.client, action.data)
            });
        case 'removeSavedProduct':
            return Object.assign({}, state, {
                client: removeSavedProduct(state.client, action.data)
            });
        case 'redirectUrl':
            return Object.assign({}, state, { redirectUrl: action.data });
        default:
            return state;
    }
};

export default rootReducer;