import React, { Component } from 'react';
import './brands.scss';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-grid-system';
import { Link } from 'react-router-dom';

interface BrandsProps {
  brands: any[]
}

const mapStateToProps = (state: any) => {
  return {
    brands: state.brands
  }
}

class BrandsPage extends Component<BrandsProps> {

  componentDidMount() {
    window.scrollTo(0,0);
  }

  render() {
    return (
      <div className="page brands-page">
        <Container fluid={true} id="brands-page-container">
          <Row className="brands-content">
            {/* <h2>Brands</h2> */}
              { this.props.brands.map(brand => {
                return <Col xs={6} sm={4} md={3} lg={2} key={"brand-" + brand.id}>
                  <Link to={`/brand/${brand.slug}`}>
                    <img className="brand-item" src={brand.imageXsUrl} height="100" alt={brand.name} key={"brand-" + brand.id}/>
                  </Link>
                </Col>
              }) }
          </Row>
        </Container>
      </div>
    );
  }
}

export default connect(mapStateToProps)(BrandsPage);