import * as React from "react";
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Form, Button, Message, Icon } from "semantic-ui-react";

interface ChangePasswordProps {
  match: any
}

interface ChangePasswordState {
  successMsg: string,
  errorMsg: string,
  newPassword: string,
  newPasswordMatch: string,
  loading: boolean
}

const Section = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const MyForm = styled.div`
    width: 350px;
    margin-top: 20px;
    color: #fff!important;
`;

const FormGroup = styled.div`
    margin-top: 20px;
    color: #fff;
    box-sizing: border-box;
`;

// create a password schema
class AdminChangePasswordComponent extends React.Component<ChangePasswordProps, ChangePasswordState> {

  constructor(props: any) {
    super(props);

    this.state = {
      successMsg: "",
      errorMsg: "",
      newPassword: "",
      newPasswordMatch: "",
      loading: false
    };

    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onPasswordMatchChange = this.onPasswordMatchChange.bind(this)
  }

  onPasswordChange(e?: any, v?: any) {
    this.setState({ newPassword: e.target.value });
  }

  onPasswordMatchChange(e?: any, v?: any) {
    this.setState({ newPasswordMatch: e.target.value });
  }

  validatePassword() {
    return this.state.newPassword.length > 6;
  }

  validate(): boolean {
    if (!this.state.newPassword || !this.validatePassword()) {
      this.setState({ errorMsg: "Нууц үг 6 тэмдэгтээс урт байх хэрэгтэй" })
      return false;
    }
    if (!this.props.match.params.forgotKey) {
      this.setState({ errorMsg: "Линк алдаатай байна. Нууц үг сэргээх хүсэлт шинээр үүсгэнэ үү" })
      return false;
    }
    return true;
  }

  handleSubmit = () => {
    if (!this.validate()) {
      return;
    }

    this.setState({ errorMsg: "", loading: true })

    fetch(process.env.REACT_APP_CHEF_API + 'mongol-change-password', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ forgotKey: this.props.match.params.forgotKey, newPassword: this.state.newPassword })
    })
      .then(res => {
        console.log(res)
        if (!res.ok) {
          res.json().then(resJson => {
            this.setState({ errorMsg: resJson.errorMsg, loading: false })
          })
        } else {
          this.setState({ successMsg: "Нууц үг амжилттай солигдлоо", loading: false })
        }
      })
      .catch(err => {
        console.error(err)
        this.setState({ errorMsg: "Сүлжээнд алдаа гарлаа", loading: false })
      })
  };

  scrollToForm = () => {
    let element = document.getElementById("signup-form");
    if (element) {
      element.scrollIntoView();
    }
  }

  render() {
    return (
      <div className="page signup-page margin-top-50">
        <Section>
          <MyForm>
            <Form style={{ color: '#fff' }} id="signup-form">
              { this.state.successMsg ?
                <React.Fragment>
                  <FormGroup>
                    <Icon name="mail outline" color='green' />
                    {this.state.successMsg}
                  </FormGroup>
                  <FormGroup>
                    <Link to="/mongol-login">
                      <Button className="width-100" color='red'>Нэвтрэх</Button>
                    </Link>
                  </FormGroup>
                </React.Fragment>
                :
                <React.Fragment>
                  {this.state.errorMsg ? <React.Fragment>
                    <br />
                    <FormGroup>
                      <Icon name="warning sign" color='red' />
                      {this.state.errorMsg}
                    </FormGroup>
                  </React.Fragment> : null
                  }

                  <FormGroup>
                    <input type="password" required placeholder='Шинэ нууц үг' value={this.state.newPassword} onChange={this.onPasswordChange} />
                  </FormGroup>

                  <FormGroup>
                    <input type="password" required placeholder='Дахин оруулна уу' value={this.state.newPasswordMatch} onChange={this.onPasswordMatchChange} />
                  </FormGroup>

                  <br />
                  <Button className="width-100" color='red' onClick={this.handleSubmit}>Нууц үг өөрчлөх</Button>
                </React.Fragment>
              }
            </Form>
          </MyForm>
        </Section>
      </div>
    );
  }
}

export default AdminChangePasswordComponent;