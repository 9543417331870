import React from 'react';
import { Icon } from 'semantic-ui-react';
import './checkout-header.scss';

interface CheckoutHeaderProps {
    activeIndex: number,
    onClick: any,
}

function CheckoutHeader(props: CheckoutHeaderProps) {

    const navigateTo = (index: any) => {
        props.onClick(index)
    }

    return (
        <React.Fragment>
            <div className="checkout-header-container">
                <div className="checkout-header">
                    <div className={"header-item" + (props.activeIndex === 1 ? " active" : "")} onClick={() => navigateTo(1)}>
                        <Icon name="shopping cart" size="large" />
                    </div>
                    <div className={"header-text" + (props.activeIndex === 1 ? " active" : "")} onClick={() => navigateTo(1)}>САГС</div>
                    <div className="header-arrow">
                        <Icon name="angle right" size="big" />
                    </div>
                    <div className={"header-item" + (props.activeIndex === 2 ? " active" : "")} onClick={() => navigateTo(2)}>
                        <Icon name="truck" size="large" />
                    </div>
                    <div className={"header-text" + (props.activeIndex === 2 ? " active" : "")} onClick={() => navigateTo(2)}>ХҮРГЭЛТ</div>
                    <div className="header-arrow">
                        <Icon name="angle right" size="big" />
                    </div>
                    <div className={"header-item" + (props.activeIndex === 3 ? " active" : "")} onClick={() => navigateTo(3)}>
                        <Icon name="handshake" size="large" />
                    </div>
                    <div className={"header-text" + (props.activeIndex === 3 ? " active" : "")} onClick={() => navigateTo(3)}>ТӨЛБӨР</div>
                </div>
            </div>
            <div className="header-line">
                <div className={"line" + (props.activeIndex === 1 ? " active" : "")}></div>
                <div className={"line" + (props.activeIndex === 2 ? " active" : "")}></div>
                <div className={"line" + (props.activeIndex === 3 ? " active" : "")}></div>
            </div>
        </React.Fragment>
    )
}

export default CheckoutHeader