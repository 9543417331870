import React, { Component } from 'react';
import './category-page.scss';
import { RouteProps, Link } from 'react-router-dom';
import { Container, Row } from 'react-grid-system';
import TopProducts from '../top-products/top-products';
import { Icon } from 'semantic-ui-react';
import SubCategories from '../categories/sub-categories';
import Products from '../products/products';
import TopProductItem from '../top-product-item/top-product';
import { connect } from 'react-redux';
import store from '../../store';

interface CategoryProps {
  categories: any[],
  products: any[],
  categorySlugMap: Map<string, number>,
  match: any,
  history: any
}

interface CategoryState {
  category: any
  parentCategory: any
  products: any[]
}

const mapStateToProps = (state: any) => {
  return {
    products: state.products?.filter((p: any) => p.active && p.brandActive && p.categoryActive),
    categories: state.categories,
    categorySlugMap: state.categorySlugMap,
  }
}

class CategoryPage extends Component<CategoryProps & RouteProps, CategoryState> {

  isSubCategory: boolean = false;

  constructor(props: any) {
    super(props);
    this.state = this.prepareData(props);
  }

  componentWillReceiveProps(nextProps: any) {
    this.setState(this.prepareData(nextProps));
  }

  componentDidMount() {
    setTimeout(() => {
      let element = document.getElementById("main-layout");
      console.log(element)
      if (element) {
        element.scrollIntoView();
      }
      store.dispatch({ type: 'REFRESH_PRODUCTS' })
    });
  }

  prepareData(nextProps: any): any {
    let parentCategory: any;
    let category: any;
    let products: any[] = [];
    
    if (nextProps.match.params.parentSlug && this.props.categories) {
      // Parent category
      let cindex = this.props?.categorySlugMap?.get(nextProps.match.params.parentSlug)
      if (cindex !== undefined && cindex !== null) {
        parentCategory = this.props?.categories[cindex]
      }
    }
    if (nextProps.match.params.slug && this.props.categories) {
      if (parentCategory) {
        // Category with parent
        let cindex = parentCategory.subCategorySlugMap?.get(nextProps.match.params.slug)
        if (cindex !== undefined && cindex !== null) {
          category = parentCategory.subCategories[cindex]
          this.isSubCategory = true;
        }
      }
      else {
        // Category without parent
        let cindex = this.props?.categorySlugMap?.get(nextProps.match.params.slug)
        if (cindex !== undefined && cindex !== null) {
          category = this.props?.categories[cindex]
        }
      }
    }

    for (let p of this.props?.products) {
      // parent category include all sub category items
      if (!parentCategory) {
        if (p['categoryId'] === category?.id) {
          products.push(p);
          continue;
        }
      }
      else if (category && p['subCategoryId'] === category?.id) {
        products.push(p);
        continue;
      }
    }

    return {
      category: category,
      parentCategory: parentCategory,
      products: products,
    };
  }

  render() {
    return (
      <div className="page category-page" id="page-container">
        <Container fluid={true} className="category-page-container">
          <Row className="category-content">
            <div className="category-header">
              <div className="breadcrumb">
                <Link to="/" className="item">{process.env.REACT_APP_LOGO_NAME1} {process.env.REACT_APP_LOGO_NAME2}</Link>
                <Icon name="angle right"></Icon>
                {this.isSubCategory ? <Link to={"/category/" + this.state.parentCategory?.slug} className="item">{this.state.parentCategory?.name}</Link> : null}
                {this.isSubCategory ? <Icon name="angle right"></Icon> : null}
                <span className="item">{this.state.category?.name}</span>
              </div>
              <div className="category-image">
                <img className="image" src={window?.innerWidth <= 800 ? this.state.category?.imageSmUrl : this.state.category?.imageLgUrl}/>
                <div className="image-content">
                  <h1>{this.state?.category?.name}</h1>
                  <div className="description">{this.state.category?.description}</div>
                </div>
              </div>
            </div>

            <div className="sub-categories">
              <SubCategories parentCategory={this.state.category} categories={this.state.category?.subCategories ? this.state.category.subCategories : []} />
            </div>
          </Row>
            <Products products={this.state.products} />
            <TopProducts title="Санал болгох бүтээгдэхүүн" products={(this.state?.products).filter((p: any) => p.warehouseCount > 0).map((i: any) => <TopProductItem product={i}/>)}/> 
        </Container>
      </div>
    );
  }
}

export default connect(mapStateToProps)(CategoryPage);