import React, { Component } from 'react';
import './top-products.scss';
import { Icon } from 'semantic-ui-react';
import AliceCarousel from 'react-alice-carousel';

interface MyProps {
  title: string,
  products: any[]
}

/* necessary */
const handleOnDragStart = (e: any) => e.preventDefault();

class TopProducts extends Component<MyProps> {

  public Carousel: any;

  responsive = {
    0: { items: 1 },
    500: { items: 2 },
    724: { items: 3 },
    768: { items: 4 },
    1024: { items: 6 },
  }

  render() {
    return (
      <React.Fragment>
        {
          this.props.products && this.props.products.length > 0 ?
          <div className="top-products-container">

            <div className="title">{this.props.title}</div>
            <div className="top-products-content">
              <div className="arrow-control left" onClick={() => this.Carousel.slidePrev()}>
                <Icon name="angle left" size='large'></Icon>
              </div>

              <AliceCarousel
                ref={(el) => (this.Carousel = el)}
                items={this.props.products}
                mouseTrackingEnabled={true} touchTrackingEnabled={true}
                buttonsDisabled={true} dotsDisabled={true} infinite={false} responsive={this.responsive}>
              </AliceCarousel>

              <div className="arrow-control right" onClick={() => this.Carousel.slideNext()}>
                <Icon name="angle right" size='large'></Icon>
              </div>
            </div>
          </div> : null
        }
      </React.Fragment>
    );
  }
}

export default TopProducts;