import React from 'react';
import { Button, Table, Icon } from 'semantic-ui-react';
import 'semantic-ui-css/components/table.min.css';
import { Link, Redirect } from 'react-router-dom';
import MyList from './list';

interface ListProps {

}

interface ListState {
    data: any[],
    errorMsg: string
}

class CompanyList extends MyList {
    entity = "client_company"

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }
        return (
            <section className="full-width">
                <h2>Худалдан авагч байгууллагын жагсаалт</h2>

                <Table striped width={16}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>№</Table.HeaderCell>
                            <Table.HeaderCell>Нэр</Table.HeaderCell>
                            <Table.HeaderCell>Регистр</Table.HeaderCell>
                            <Table.HeaderCell>Идэвхитэй</Table.HeaderCell>
                            <Table.HeaderCell>Дараа төлбөрт</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        { this.state?.data?.map((row: any, index: number) => {
                                return <Table.Row key={row.id}>
                                    <Table.Cell>{ String(index+1) }</Table.Cell>
                                    <Table.Cell>
                                        <strong>
                                            <Link className="underline hover-blue" to={"/mongol/company/" + row.id}>{ row.name }</Link>
                                        </strong>
                                    </Table.Cell>
                                    <Table.Cell> { row.register } </Table.Cell>
                                    <Table.Cell> { row.active ? <Icon name="checkmark"/> : null } </Table.Cell>
                                    <Table.Cell> { row.canPayLater ? <Icon name="checkmark"/> : null } </Table.Cell>
                                </Table.Row>
                            })
                        }
                    </Table.Body>
                </Table>
            </section>
        );
    }
}
            
export default CompanyList;