import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Dimmer, Container, Loader, Table, Icon, Pagination } from 'semantic-ui-react';
import NumberFormat from 'react-number-format';
import OrderDeliveryComponent from '../order/order.delivery';
import OrderModel from '../order/order.model';
import { parseStatus, formatDateTime } from '../../../utils/shared.utils';

// const bucket = process.env.REACT_APP_S3_BUCKET
// const region = process.env.REACT_APP_S3_REGION

function ReportOrders(props: any) {

  const history = useHistory()
  const [orders, setOrders] = useState<any[]>([])
  const [page, setPage] = useState({ page: 1, size: 10, totalPages: 1 })
  const [loading, setLoading] = useState(true)
  const [errorMsg, setErrorMsg] = useState("")
  const [fetchedOrders, setFetchedOrders] = useState(false)

  useEffect(() => {
      if (fetchedOrders) {
        return
      }
      fetchOrders(1);
      setFetchedOrders(true)
  }, []);

  const fetchOrders = (pageNum: number) => {
    
    var token = localStorage.getItem('adminToken')
    if (token && token !== 'undefined') {
      setLoading(true)
      fetch(process.env.REACT_APP_CHEF_API + 'mongol/orders', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ page: { page: pageNum, size: 10 } })
      }).then((res: any) => {
        setLoading(false)
        if (res.ok) {
          res.json().then((resJson: any) => {
            let orders: OrderModel[] = resJson.data;
            for (let order of orders) {
              if (order.reserveUntil) {
                order.reserveUntil = new Date(order.reserveUntil)
                order['reserveDate'] = formatDateTime(order.reserveUntil)
              }
              for (let delivery of order.deliveries) {
                let date = new Date(delivery.date)
                delivery.date = `${date.getMonth() + 1} сарын ${date.getUTCDate()}`
                // for (let item of delivery.items) {
                  // item.product.imageXsUrl = `https://${bucket}.s3.${region}.amazonaws.com/${item.product.imageXsUrl}`
                // }
              }
            }
            setOrders(orders)
            setPage(resJson.page)
          })
        }
      }).catch(err => {
        setLoading(false)
        setErrorMsg("Сүлжээний алдаа")
        console.error(err)
      })
    }
    else {
      window.alert("Та дахин нэвтэрнэ үү")
      history.push("/mongol/login")
    }
  }

  const toggle = (index: number) => {
    let ordersCopy = [... orders];
    ordersCopy[index].showItems = !ordersCopy[index].showItems;
    setOrders(ordersCopy);
  }

  const onPageChange = (event: any, data: any) => {
      setPage(Object.assign(page, { page: data.activePage }))
      fetchOrders(data.activePage)
      window.scrollTo(0, 100)
  }

  return (
    <div className="page margin-top-50-sm">
      <Container>
        <div className="flex-div width-100">
          <Dimmer active={loading} inverted>
            <Loader size='medium'></Loader>
          </Dimmer>
          <section className="client-orders" id="my-form">

            <div className="width-100 text-center"><h2>Захиалгууд</h2></div>

            <div className="error-msg">{ errorMsg }</div>

            <Table width={16}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>№</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell>Нийт дүн</Table.HeaderCell>
                  <Table.HeaderCell>Төлөв</Table.HeaderCell>
                  <Table.HeaderCell>Хүчинтэй хугацаа</Table.HeaderCell>
                  <Table.HeaderCell>Хэрэглэгч</Table.HeaderCell>
                  <Table.HeaderCell>Байгууллага</Table.HeaderCell>
                  <Table.HeaderCell>Дэлгэрэнгүй</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              
              <Table.Body>
                {orders?.map((order: any, index: number) => {
                  return <React.Fragment key={"order-" + index}>
                    <Table.Row style={{'backgroundColor': '#f9fafb'}}>
                      <Table.Cell className="underline cursor">
                        <a target="_blank" href={`/mongol/report/order/${order.id}`}><strong>#{order.id}</strong></a>
                      </Table.Cell>
                      <Table.Cell className="cursor">
                        <a target="_blank" href={`/mongol/report/order/${order.id}`}><strong>{order.token}</strong></a>
                      </Table.Cell>
                      <Table.Cell className="cursor" onClick={() => toggle(index)}>
                        <NumberFormat displayType={'text'} value={order.totalAmount} suffix="₮" thousandSeparator={true}/>
                      </Table.Cell>
                      <Table.Cell className={`cursor order-status ${String(order.status || '').toLowerCase()}`} 
                        onClick={() => toggle(index)}>
                        {parseStatus(order.status)} {order.payLater ? '(дараа төлөх)' : ''}
                      </Table.Cell>
                      <Table.Cell>
                        { order.reserveDate }
                      </Table.Cell>
                      <Table.Cell>
                        <Link className="underline" to={"/mongol/client/" + order.clientId}>{ order.ownName }</Link>
                      </Table.Cell>
                      <Table.Cell>
                        <Link className="underline" to={"/mongol/company/" + order.companyId}>{ order.companyName }</Link>
                      </Table.Cell>
                      <Table.Cell className="cursor">
                        <a target="_blank" href={`/mongol/report/order/${order.id}`}>
                          <strong><Icon name="folder open outline"/></strong>
                        </a>
                      </Table.Cell>
                      <Table.Cell className="cursor" onClick={() => toggle(index)}>
                        { order.showItems ? <Icon name="minus"/> : <Icon name="plus"/> }
                      </Table.Cell>
                    </Table.Row>
                    { order.showItems ? <React.Fragment>
                      <Table.Row>
                        <Table.Cell colSpan={9}>
                          <strong className="col-top">Хаяг: </strong>
                          <span>{order.town}, {order.district}, {order.unit}, {order.street}</span>
                        </Table.Cell>
                      </Table.Row>
                      {order.deliveries?.map((delivery: any, dindex: number) => {
                        return <OrderDeliveryComponent key={delivery.id} delivery={delivery}/>
                      })
                      }
                      <Table.Row>
                        <Table.Cell colSpan={9}>
                          <div className="order-summary">
                            <div>Барааны нийт үнэ: <NumberFormat displayType="text" value={order.totalAmount - order.totalDeliveryCost} suffix="₮" thousandSeparator={true} /></div>
                            <div>Хүргэлтийн нийт үнэ: <NumberFormat displayType="text" value={order.totalDeliveryCost} suffix="₮" thousandSeparator={true} /></div>
                            <div>Нийт үнэ: <NumberFormat displayType="text" value={order.totalAmount} suffix="₮" thousandSeparator={true} /></div>
                          </div>
                        </Table.Cell>
                    </Table.Row>
                    </React.Fragment> : null
                    }
                  </React.Fragment>
                })}
              </Table.Body>
            </Table>

            <div className="flex-div margin-top-50">
              <Pagination defaultActivePage={1} value={page.page} totalPages={page.totalPages} onPageChange={onPageChange}></Pagination>
            </div>
          </section>
        </div>
      </Container>
    </div>
  );
}

export default ReportOrders;