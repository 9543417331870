import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { Link, useHistory } from 'react-router-dom';
import { Dimmer, Container, Loader, Table, Form, Button } from 'semantic-ui-react';
import { formatDate, formatDateTime } from '../../../utils/shared.utils';

function ReportPayments(props: any) {

  const history = useHistory()
  const [payments, setPayments] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")

  const tomorrow = new Date();
  const weekBefore = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  weekBefore.setDate(weekBefore.getDate() - 14);

  const [startDate, setStartDate] = useState(formatDate(weekBefore))
  const [endDate, setEndDate] = useState(formatDate(tomorrow))

  useEffect(() => {
    setTimeout(() => {
      fetchReport();
    });
  }, []);

  const fetchReport = () => {
    
    var token = localStorage.getItem('adminToken')
    if (token && token !== 'undefined') {
      if (!startDate) {
        setErrorMsg("Эхний өдөр оруулна уу")
        return;
      }
      if (!endDate) {
        setErrorMsg("Сүүлийн өдөр оруулна уу")
        return;
      }

      // fetch report data
      setLoading(true)
      fetch(process.env.REACT_APP_CHEF_API + 'mongol/payments', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ startDate: startDate, endDate: endDate })
      }).then((res: any) => {
        setLoading(false)
        if (res.ok) {
          res.json().then((resJson: any) => {
            let data: any[] = resJson.data;
            for (let payment of data) {
              payment['paidAtStr'] = formatDateTime(new Date(payment.paidAt))
            }
            setPayments(data)
          })
        }
      }).catch(err => {
        setLoading(false)
        setErrorMsg("Сүлжээний алдаа")
        console.error(err)
      })
    }
    else {
      window.alert("Та дахин нэвтэрнэ үү")
      history.push("/mongol/login")
    }
  }

  return (
    <div className="page margin-top-50-sm">
      <Container>
        <div className="flex-div width-100">
          <Dimmer active={loading} inverted>
            <Loader size='medium'></Loader>
          </Dimmer>
          <section className="client-orders" id="my-form">

            <div className="width-100 text-center"><h2>Орлогын тайлан</h2></div>

            <div className="report-filter flex-div margin-top-50">
              <Form.Input label="Эхний өдөр: " type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)}/>
              &nbsp;&nbsp;&nbsp;
              <Form.Input label="Сүүлийн өдөр: " type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)}/>
              &nbsp;&nbsp;&nbsp;
              <Button onClick={() => fetchReport()}>Хайх</Button>
            </div>

            <div className="error-msg width-100 text-center">{ errorMsg }</div>
            
            <Table width={16}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Захиалга</Table.HeaderCell>
                  <Table.HeaderCell>Огноо</Table.HeaderCell>
                  <Table.HeaderCell>Гүйлгээний дугаар</Table.HeaderCell>
                  <Table.HeaderCell>Мөнгөн дүн</Table.HeaderCell>
                  <Table.HeaderCell>Бүртгэсэн</Table.HeaderCell>
                  <Table.HeaderCell>Хэрэглэгч</Table.HeaderCell>
                  <Table.HeaderCell>Байгууллага</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {payments?.map((payment: any, index: number) => {
                  return <React.Fragment key={"order-" + index}>
                    <Table.Row style={{'backgroundColor': '#f9fafb'}}>
                      <Table.Cell className="underline cursor">
                        <Link to={"/mongol/report/order/" + payment.orderId}>
                          <strong>#{payment.orderId} {payment.token}</strong>
                        </Link>
                      </Table.Cell>
                      <Table.Cell>
                          {(payment.paidAtStr)}
                      </Table.Cell>
                      <Table.Cell>{ payment.txnId }</Table.Cell>
                      <Table.Cell>
                        <NumberFormat displayType={'text'} value={payment.amount} suffix="₮" thousandSeparator={true}/>
                      </Table.Cell>
                      <Table.Cell>{ payment.method } ({ payment.createdBy })</Table.Cell>
                      <Table.Cell>
                        <Link className="underline" to={"/mongol/client/" + payment.clientId}>{ payment.ownName }</Link>
                      </Table.Cell>
                      <Table.Cell>
                        <Link className="underline" to={"/mongol/company/" + payment.companyId}>{ payment.companyName }</Link>
                      </Table.Cell>
                    </Table.Row>
                  </React.Fragment>
                })}
              </Table.Body>
            </Table>

          </section>
        </div>
      </Container>
    </div>
  );
}

export default ReportPayments;