import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Dimmer, Loader, Icon } from 'semantic-ui-react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import store from '../../store';
import './warehouse.scss';

const mapStateToProps = (state: any) => {
  return {
    provider: state.provider
  }
}

function WarehouseComponent(props: any) {

    const history = useHistory()

    useEffect(() => {
        var token = localStorage.getItem('providerToken')
        if (token && token !== 'undefined') {
            getWarehouse();
        }
        else {
            console.log("Will redirect to: " + window.location.pathname)
            store.dispatch({ type: "redirectUrl", data: window.location.pathname })
            history.push("/")
        }
    }, []);

    const [originalWarehouse, setOriginalWarehouse] = useState({ id: null, name: "", count: 0, minCount: 0, slug: ""});
    const [warehouse, setWarehouse] = useState({ id: null, name: "", count: 0, minCount: 0, slug: ""});
    const [fetchedDetails, setFetchedDetails] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");

    const getWarehouse = () => {
        var token = localStorage.getItem('providerToken')
        if (!token || token === 'undefined') {
            console.log("Will redirect to: " + window.location.pathname)
            store.dispatch({ type: "redirectUrl", data: window.location.pathname })
            history.push("/provider-login")
            return;
        }

        fetch(process.env.REACT_APP_CHEF_API + 'provider/warehouse/' + props.match.params.id, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': token,
                'Content-Type': 'application/json'
            }
        }).then((res: any) => {
            if (res.ok) {
                res.json().then((resJson: any) => {
                    if (resJson?.warehouse?.id) {
                        setOriginalWarehouse(Object.assign(originalWarehouse, resJson.warehouse))
                        setWarehouse(resJson.warehouse);
                    }
                    setFetchedDetails(true);
                })
            }
        }).catch(err => {
            console.error(err)
        })
    }

    const changeHandler = (e: any) => {
        setWarehouse({ ...warehouse, [e.target.name]: e.target.value })
    }

    const save = () => {
        var token = localStorage.getItem('providerToken')

        if (token && token !== 'undefined') {

            let payload = { id: warehouse.id, count: warehouse.count, minCount: warehouse.minCount }

            setLoading(true)

            fetch(process.env.REACT_APP_CHEF_API + 'provider/warehouse/count', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            })
            .then((res: any) => {
                setLoading(false)
                if (!res.ok) {
                    setErrorMsg("Сүлжээнд алдаа гарлаа")
                }
                else {
                    setErrorMsg("")
                    setSuccessMsg("Амжилттай хадгаллаа")
                    setTimeout(() => {
                        setSuccessMsg("")
                    }, 3000);
                }
            })
            .catch(err => {
                setLoading(false)
                setErrorMsg("Сүлжээний алдаа")
                console.error(err)
            })
        }
    }

    return (
        <div className="page margin-top-50-sm">
            <Container>
                <Dimmer active={loading}>
                    <Loader size='medium'></Loader>
                </Dimmer>

                <div className="flex-div width-100">
                    {!fetchedDetails ? null :
                        <section className="warehouse-form" id="my-form">
                            <div className="form-group">
                                <Link to="/provider/warehouse"><Icon name="arrow left"/>
                                    <span className="underline">Буцах</span>
                                </Link>
                            </div>
                            <div className="form-group">
                                <Link className="underline" to={"/provider/warehouse-actions/" + warehouse.id}>Үлдэгдлийн түүх</Link>
                            </div>
                            <div className="form-group">
                                <div className="label">
                                    <h2>{ warehouse.name }</h2>
                                    <a className="underline" href={"/product/" + warehouse.slug} target="_blank">Худалдааны линк</a>
                                    <br/>
                                </div>
                            </div>

                            <Form widths='equal'>
                                <div className="form-group">
                                    {errorMsg ? <div className="error-msg"><Icon name="warning circle"/>{errorMsg}</div> : null}
                                </div>
                                <div className="form-group">
                                    {successMsg ? <div className="success-msg"><Icon name="checkmark"/>{successMsg}</div> : null}
                                </div>
                                <div className="form-group">
                                    <div className="label">Үлдэгдэл:</div>
                                    <Form.Input type="number" required name="count" value={warehouse.count} min={0} onChange={changeHandler} />
                                </div>
                                <div className="form-group">
                                    <div className="label">Анхааруулах үлдэгдэл:</div>
                                    <Form.Input type="number" required name="minCount" value={warehouse.minCount} min={0} onChange={changeHandler} />
                                    <p><Icon name="info circle"/>Бүтээгдэхүүний үлдэгдэл хэмжээ нь тус хэмжээнээс бага болох үед танд имэйл илгээж мэдэгдэнэ</p>
                                </div>
                                <br/>
                                <div className="form-group">
                                    <Button className="width-100" color='red' onClick={() => save()} disabled={loading}>Хадгалах</Button>
                                </div>
                            </Form>
                        </section>
                    }
                </div>
            </Container>
        </div>
    );
}

export default connect(mapStateToProps)(WarehouseComponent)