export class CompanyModel {
    id: any
    name: string = ''
    register: string = ''
    approved? = 0
    canPayLater? = 0
    active? = 0;
    town: string = 'Улаанбаатар'
    district: string = ''
    unit: string = ''
    street: string = ''
    zipcode: string = ''
}