import React from 'react';
import { Form, Container, Dimmer, Loader } from 'semantic-ui-react';
import MyFormState from './MyFormState';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import MyForm from './form';
import { ImageModel } from '../../../entities/image.model';
import { BrandModel } from '../../../entities/brand.model';

const MyLabel = styled.div`
    width: 140px;
    font-weight: 600;
`;

interface FormProps {
    match: any,
    history: any
}

class ProviderUserForm extends MyForm<FormProps, MyFormState> {
    entity = 'provider_user'

    constructor(props: any) {
        super(props);
        this.state = {
            model: new BrandModel,
            successMsg: "",
            errorMsg: "",
            loading: true,
            editing: false,
            editingSlug: false,
            slugValid: true,
            imageObj: ImageModel.empty(),
            imageObjs: { lg: ImageModel.empty(), sm: ImageModel.empty(), xs: ImageModel.empty(),
                image1: ImageModel.empty(), image2: ImageModel.empty(), image3: ImageModel.empty(),
                image4: ImageModel.empty(), image5: ImageModel.empty(), image6: ImageModel.empty()
            },
            redirect: "",
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }
        return (
            <Container>
                <Dimmer active={this.state.loading} inverted>
                    <Loader size='medium'></Loader>
                </Dimmer>
                <Form widths='equal'>
                    <Form.Group>
                        { this.state.successMsg ? <div className="success-msg">{this.state.successMsg}</div> : null }
                        { this.state.errorMsg ? <div className="error-msg">{this.state.errorMsg}</div> : null }
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <MyLabel>Нэр</MyLabel>
                        <div>{this.state.model.ownName}</div>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <MyLabel>Имэйл</MyLabel>
                        <div>{this.state.model.email}</div>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <MyLabel>Утасны дугаар</MyLabel>
                        <div>{this.state.model.phone}</div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Checkbox disabled checked={this.state.model.active} onChange={this.onActiveChange} label='Идэвхитэй' />
                    </Form.Group>
                </Form>
            </Container>
        );
    }
}

export default ProviderUserForm;