import React, { Component, useContext, useEffect, useState } from 'react';
import './filter.scss';
import { Form } from 'semantic-ui-react';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';

interface FilterProps {
    priceMin: number
    priceMax: number
    priceUp: number
    priceDown: number
    unitMap: Map<string, any>
    brands: string[]
    usageSet: string[]
    typeSet: string[],
    sale: boolean,
    onChange: (value: any) => any;
}

function ProductsFilter(props: FilterProps) {

    const buildUnitOptions = () => {
        let units: any[] = [];
        ['Бүгд', 'грамм', 'кг', 'мл', 'литр'].forEach((unit: string) => {
            units.push({ key: unit, value: unit, text: unit })
        })
        return units;
    }

    const [ priceVal, setPriceVal ] = useState<any>({ min: props.priceDown, max: props.priceUp })
    const [ unitMax, setUnitMax ] = useState(0)
    const [ unitMin, setUnitMin ] = useState(0)
    const [ unitVal, setUnitVal ] = useState({ min: props.priceMin, max: props.priceMax })
    const [ units, setUnits ] = useState<any[]>(buildUnitOptions())
    const [ selectedUnit, setSelectedUnit ] = useState("")

    let selectedBrands: Set<String> = new Set()
    let selectedTypes: Set<String> = new Set()
    let selectedUsages: Set<String> = new Set()

    // const [selectedBrands, setSelectedBrands] = useState<Set<String>>(new Set())
    // const [selectedTypes, setSelectedTypes] = useState<Set<String>>(new Set())
    // const [selectedUsages, setSelectedUsages] = useState<Set<String>>(new Set())

    useEffect(() => {
        let unitMin = 0;
        let unitMax = 0;
        if (props.unitMap.has(units[0].value)) {
            unitMin = props.unitMap.get(units[0].value).min
            unitMax = props.unitMap.get(units[0].value).max
        }
        
        if (props.priceUp && props.priceDown) {
            if (props.priceUp !== priceVal.max || props.priceDown !== priceVal.min) {
                setPriceVal({ min: props.priceDown, max: props.priceUp })
            }
        }
    })

    const onSaleChange = (e: any) => {
        props.onChange({
            sale: e.target.checked
        })
    }

    const onPriceChange = (value: any) => {
        props.onChange({
            priceValue: value
        })
    }

    const onUnitChange = (e: any, drop: any) => {
        let unit
        if (props.unitMap.has(drop.value)) {
            let unitObj = props.unitMap.get(drop.value);
            unit = { min: unitObj.min, max: unitObj.max }
            setUnitMin(unitObj.min)
            setUnitMax(unitObj.max)
        } else {
            unit = { min: 0, max: 0 }
            setUnitMin(0)
            setUnitMax(0)
        }
        setUnitVal(unit)
        setSelectedUnit(drop.value)
        props.onChange({
            unitValue: {
                unit: drop.value,
                min: unit.min,
                max: unit.max
            }
        })
    }

    const onUnitValueChange = (value: any) => {
        setUnitVal(value)
        props.onChange({
            unitValue: {
                unit: selectedUnit,
                min: value.min,
                max: value.max
            }
        })
    }

    const brandChecked = (e: any, index: number) => {
        if (e.target.checked) {
            selectedBrands.add(props.brands[index])
        } else {
            selectedBrands.delete(props.brands[index])
        }
        props.onChange({ brands: selectedBrands })
    }

    const usageChecked = (e: any, index: number) => {
        if (e.target.checked) {
            selectedUsages.add(props.usageSet[index])
        } else {
            selectedUsages.delete(props.usageSet[index])
        }
        props.onChange({ usages: selectedUsages })
    }

    const typeChecked = (e: any, index: number) => {
        if (e.target.checked) {
            selectedTypes.add(props.typeSet[index])
        } else {
            selectedTypes.delete(props.typeSet[index])
        }
        props.onChange({ types: selectedTypes })
    }

    return (
        <div className="products-filter-container">
            <div className="sale-filter filter">
                <div className="filter-body ui checkbox">
                    <input type="checkbox" id="sale-filter-input" onChange={(e) => onSaleChange(e)} checked={props.sale} />
                    <label htmlFor="sale-filter-input">Хямдарсан</label>
                </div>
            </div>
            
            <div className="price-filter filter">
                <div className="flex-div">
                    ₮
                </div>
                <div className="filter-body">
                    { props.priceMax > props.priceMin ?
                    <InputRange
                        maxValue={props.priceMax}
                        minValue={props.priceMin}
                        value={priceVal}
                        onChange={value => onPriceChange(value)} /> :null
                    }
                </div>
            </div>
            
            <div className="size-filter filter">
                <div className="filter-title">Хэмжээ</div>
                <div className="filter-body">
                    <div className="filter-line">
                        <Form.Dropdown options={units} 
                            defaultValue={units[0].value} 
                            onChange={onUnitChange} />
                    </div>
                    { unitVal.max > unitVal.min ?
                        <div className="filter-line">
                        <InputRange disabled={!selectedUnit || selectedUnit === 'Бүгд'}
                            maxValue={unitMax}
                            minValue={unitMin}
                            value={unitVal}
                            onChange={value => onUnitValueChange(value)} />
                    </div> : null
                    }
                </div>
            </div>
            
            <div className="brand-filter filter">
                <div className="filter-title">Брэнд</div>
                <div className="filter-body">
                    { props.brands.map((brandName: any, brandIndex: number) => {
                        return <div className="flex-start" key={"brand-checkbox-" + brandIndex}>
                            <Form.Checkbox id={"brand-checkbox-" + brandIndex} onChange={(e) => brandChecked(e, brandIndex)} />
                            <label htmlFor={"brand-checkbox-" + brandIndex}>{brandName}</label>
                        </div>
                    })}
                </div>
            </div>

            <div className="type-filter filter">
                <div className="filter-title">Төрөл</div>
                <div className="filter-body">
                    { props.typeSet.map((usage: any, typeIndex: number) => {
                        return <div className="flex-start" key={"type-checkbox-" + typeIndex}>
                            <Form.Checkbox onChange={(e) => typeChecked(e, typeIndex)} id={"type-checkbox-" + typeIndex} />
                            <label htmlFor={"type-checkbox-" + typeIndex}>{usage}</label>
                        </div>
                    })}
                </div>
            </div>

            <div className="usage-filter filter">
                <div className="filter-title">Хэрэглээ</div>
                <div className="filter-body">
                    { props.usageSet.map((usage: any, usageIndex: number) => {
                        return <div className="flex-start" key={"usage-checkbox-" + usageIndex}>
                            <Form.Checkbox id={"usage-checkbox-" + usageIndex} onChange={(e) => usageChecked(e, usageIndex)} />
                            <label htmlFor={"usage-checkbox-" + usageIndex}>{usage}</label>
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}

export default ProductsFilter;