import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Dimmer, Loader, Icon, Table } from 'semantic-ui-react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import store from '../../store';
import './warehouse.scss';
import { formatDateTime, formatDate } from '../../utils/shared.utils';

const mapStateToProps = (state: any) => {
  return {
    provider: state.provider
  }
}

function WarehouseActionsComponent(props: any) {

    const history = useHistory()

    useEffect(() => {
        var token = localStorage.getItem('providerToken')
        if (token && token !== 'undefined') {
            getActions(props.match.params.id);
        }
        else {
            console.log("Will redirect to: " + window.location.pathname)
            store.dispatch({ type: "redirectUrl", data: window.location.pathname })
            history.push("/")
        }
    }, []);

    const [productName, setProductName] = useState("");
    const [slug, setSlug] = useState("");
    const [actions, setActions] = useState([]);
    const [loading, setLoading] = useState(false);

    const tomorrow = new Date();
    const pastDate = new Date();
    tomorrow.setDate(tomorrow.getDate());
    pastDate.setDate(pastDate.getDate() - 3);
    const [startDate, setStartDate] = useState(formatDate(pastDate))
    const [endDate, setEndDate] = useState(formatDate(tomorrow))

    const getActions = (warehouseId: number) => {
        var token = localStorage.getItem('providerToken')
        if (!token || token === 'undefined') {
            console.log("Will redirect to: " + window.location.pathname)
            store.dispatch({ type: "redirectUrl", data: window.location.pathname })
            history.push("/provider-login")
            return;
        }
        setLoading(true)
        fetch(process.env.REACT_APP_CHEF_API + 'provider/warehouse-actions/' + warehouseId, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ startDate: startDate + " 00:00:00", endDate: endDate + " 23:59:59"})
        }).then((res: any) => {
            setLoading(false)
            if (res.ok) {
                res.json().then((resJson: any) => {
                    if (resJson?.data?.length > 0) {
                        setProductName(resJson.data[0].name)
                        setSlug(resJson.data[0].slug)

                        resJson.data.forEach((r: any) => {
                            r.createdAtStr = formatDateTime(new Date(r.createdAt))
                            if (r.minCount === undefined || r.minCount === null) {
                                r.minCount = "-"
                            }
                            if (r.countAfter === undefined || r.countAfter === null) {
                                r.countAfter = "-"
                            }
                            if (r.minus === undefined || r.minus === null) {
                                r.minus = "-"
                            }
                            if (r.plus === undefined || r.plus === null) {
                                r.plus = "-"
                            }
                        })
                    }
                    setActions(resJson.data)
                })
            }
        }).catch(err => {
            setLoading(false)
            console.error(err)
        })
    }

    const parseStatus = (status: string, createdBy: string, orderId: string) => {
        switch (status) {
          case 'confirm': return 'Захиалга #' + orderId
          case 'basket': return 'Төлөгдөөгүй #' + orderId
          case 'restock': return 'Төлөгдөөгүй #' + orderId
          case 'PROVIDER UPDATE': return `${createdBy} өөрчилсөн`
          default: return ""
        }
    }

    return (
        <div className="page margin-top-50-sm">
            <Dimmer active={loading}>
                <Loader size='medium'></Loader>
            </Dimmer>

            <div className="flex-div width-100">
                <section className="warehouse-form" id="my-form">
                    <div className="warehouse-actions">
                        <Link to="/provider/warehouse"><Icon name="angle left"/>
                            <span className="underline">Буцах</span>
                        </Link>
                        <div className="width-100 text-center"><h1>Үлдэгдлийн түүх</h1></div>
                        <div className="width-100"><h2>{ productName }</h2></div>
                        { slug ? <a className="underline" href={"/product/" + slug} target="_blank">Худалдааны линк</a> : null }
                        <br/>
                        <div className="report-filter">
                            <Form.Input label="Эхний өдөр: " type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)}/>
                            &nbsp;&nbsp;&nbsp;
                            <Form.Input label="Сүүлийн өдөр: " type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)}/>
                            &nbsp;&nbsp;&nbsp;
                            <Button onClick={() => getActions(Number(props.match.params.id))}>Дата татах</Button>
                            <br/>
                            <br/>
                        </div>

                        <Table striped>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Өөрчлөлт</Table.HeaderCell>
                                    <Table.HeaderCell>Нэмэгдсэн</Table.HeaderCell>
                                    <Table.HeaderCell>Хасагдсан</Table.HeaderCell>
                                    <Table.HeaderCell>Үлдэгдэл</Table.HeaderCell>
                                    <Table.HeaderCell>Анхааруулах үлдэгдэл</Table.HeaderCell>
                                    <Table.HeaderCell>Огноо</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {actions?.map((action: any, index: number) => {
                                    return <Table.Row key={"wa-history-" + index}>
                                        <Table.Cell>{ parseStatus(action.action, action.createdBy, action.orderId) }</Table.Cell>
                                        <Table.Cell className={action.plus && action.plus > 0 ? "plus" : ""}>{ action.plus && action.plus > 0 ? "+" : ""}{action.plus }</Table.Cell>
                                        <Table.Cell className={(action.minus && action.minus > 0 ? "minus" : "") + (action.action === "confirm" ? " confirm" : "")}>
                                            { action.minus && action.minus > 0 ? "-" : ""}{ action.minus }</Table.Cell>
                                        <Table.Cell className={action.action === "PROVIDER UPDATE" ? "updated" : ""}>{ action.countAfter }</Table.Cell>
                                        <Table.Cell className={action.action === "PROVIDER UPDATE" ? "updated" : ""}>{ action.minCount }</Table.Cell>
                                        <Table.Cell>{ action.createdAtStr }</Table.Cell>
                                    </Table.Row>
                                })
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default connect(mapStateToProps)(WarehouseActionsComponent)