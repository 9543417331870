export class ClientModel {
    id: any
    ownName: string = ''
    parentName: string = ''
    email: string = ''
    phone: string = ''
    superUser = 0
    town: string = 'Улаанбаатар'
    district: string = ''
    unit: string = ''
    street: string = ''
    zipcode: string = ''
    savedProducts: any[] = []
    company: any
    active: boolean = false
}