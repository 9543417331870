import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Button, Icon } from 'semantic-ui-react';

interface MyComponentProps extends RouteComponentProps {
  match: any
}

// create a password schema
function CompanyRoleActionPage(props: MyComponentProps) {

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [action, setAction] = useState("");
  const [hash, setHash] = useState("");
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    setAction(props?.match?.params?.action)
    setHash(props?.match?.params?.hash)
    submit()
  })

  const submit = () => {
    if (submitted || !(action && hash)) {
      return;
    }

    if (['accept', 'reject'].indexOf(action) === -1) {
      return;
    }

    fetch(process.env.REACT_APP_CHEF_API + 'client/company/users/role/' + action, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ hash: hash })
    }).then(res => {
      setSubmitted(true)
      if (res.ok) {
        setErrorMsg("")
        if (action.toLowerCase() === 'accept') {
          setSuccessMsg("Та байгууллагын аккаунтад амжилттай холбогдлоо.")
        }
        else {
          setSuccessMsg("Таны татгалзсан хүсэлтийг хүлээн авлаа.")
        }
      }
      else {
        setErrorMsg("Алдаа гарлаа")
      }
    }).catch(err => {
      console.error(err)
    })
  };

  return (
    <div className="page signup-page margin-top-50">
      <div className="text-center">
        {errorMsg ?
          <div>
            <div className="error-msg">
              <Icon name="warning circle"/>
              {errorMsg}
            </div>
          </div> : null
        }
        {
          successMsg ?
            <div>
              <div>
                <Icon name="building outline" color='green'/>
                {successMsg} </div>
            </div> : null
        }

        { submitted ?
          <div>
              <br/>
            {
              action === 'accept' ?
                <Link to='/client/account'>
                  <Button color='grey'>Таны аккаунт</Button>
                </Link>
                :
                <Link to='/'>
                  <Button color='grey'>Нүүр хуудас</Button>
                </Link>
            }
          </div> : null
        }
      </div>
    </div>
  );
}

export default withRouter(CompanyRoleActionPage);