import React from 'react';
import 'semantic-ui-css/components/table.min.css';
import S3Manager from '../s3/s3manager';
import { Redirect } from 'react-router-dom';

interface ListProps {
}

interface ListState {
    loading: boolean
    data: any[]
    errorMsg: string
    redirect: string
    page: { page: number, size: number, totalPages: number,totalRows: number},
    list1: any[],
    value1: any,
    value2: any,
}

class MyList extends React.Component<ListProps, ListState> {
    loginUrl = "/mongol-login"
    entity = ""

    constructor(props: any) {
        super(props);
        this.state = { loading: false, data: [], errorMsg: "", redirect: "", page: { page: 1, size: 15, totalPages: 1, totalRows: 0}, list1: [], value1: null, value2: null };
        this.onPageChange = this.onPageChange.bind(this);
        this.onValue1Change = this.onValue1Change.bind(this);
        this.onValue2Change = this.onValue2Change.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        var token = localStorage.getItem('adminToken')
        if (token && token !== 'undefined') {
            fetch(process.env.REACT_APP_CHEF_API + "list/" + this.entity, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': token,
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                if (res.status === 401) {
                    this.setState({ redirect: this.loginUrl})
                } else {
                    res.json().then(res => {
                        console.log(res);
                        if (!res || !res.data) {
                            return;
                        }
                        let data: any[] = res.data;
                        data.forEach(b => b.confirmingDelete = false);
                        this.setState({ data: data });
                    })
                }
            })
            .catch(err => console.error(err));
        }
        else {
            this.setState({ redirect: this.loginUrl });
        }
    }

    onPageChange(event: any, data: any) {
        this.setState({ page: Object.assign(this.state.page, { page: data.activePage }) }, () => {
            this.refresh()
            window.scrollTo(0, 100)
        });
    }

    onValue1Change(e: any, drop: any) {
        this.setState({ value1: drop.value });
    }

    onValue2Change(e: any) {
        this.setState({ value2: e.target.value })
    }

    public toggleDelete = (index: number) => {
        let data = this.state.data;
        data[index].confirmingDelete = !data[index].confirmingDelete;
        this.setState({ data: data });
    }

    public delete = (index: number) => {
        if (!this.state.data[index].id) {
            return;
        }
        var token = localStorage.getItem('adminToken')
        if (token && token !== 'undefined') {
            let toDelete = this.state.data[index];
            fetch(`${process.env.REACT_APP_CHEF_API}delete/${this.entity}/${toDelete.id}`, {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': token,
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                console.log(res);
                if (res.ok) {
                    this.setState({ errorMsg: "" });
                    // delete images
                    this.deleteImagesAfterwards(toDelete);
                    this.refresh();
                }
                else {
                    res.json().then((resJson: any) => {
                        if (resJson.error) {
                            this.setState({ errorMsg: resJson.error});
                        }
                    })
                }
            });
        }
    }

    async deleteImagesAfterwards(record: any) {
        if (record.imageLgUrl) {
            S3Manager.deleteImage(record.imageLgUrl);
        }
        if (record.imageSmUrl) {
            S3Manager.deleteImage(record.imageSmUrl);
        }
        if (record.imageXsUrl) {
            S3Manager.deleteImage(record.imageXsUrl);
        }
        if (record.imageUrl) {
            S3Manager.deleteImage(record.imageUrl);
        }
        if (record.image1) {
            S3Manager.deleteImage(record.imageUrl);
        }
        if (record.image2) {
            S3Manager.deleteImage(record.imageUrl);
        }
        if (record.image3) {
            S3Manager.deleteImage(record.imageUrl);
        }
        if (record.image4) {
            S3Manager.deleteImage(record.imageUrl);
        }
        if (record.image5) {
            S3Manager.deleteImage(record.imageUrl);
        }
        if (record.image6) {
            S3Manager.deleteImage(record.imageUrl);
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }
        return (
            <React.Fragment></React.Fragment>
        );
    }
}
            
export default MyList;