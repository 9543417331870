import React, { Component } from 'react';
import './brand-page.scss';
import { connect } from 'react-redux';
import { RouteProps, Link } from 'react-router-dom';
import { Container, Row } from 'react-grid-system';
import Products from '../products/products';
import { Icon } from 'semantic-ui-react';
import store from '../../store';

interface BrandProps {
  id: number,
  products: any,
  match: any,
  history: any
}

interface BrandState {
  brand: any
}

const mapStateToProps = (state: any) => {
  return {
    products: state.products?.filter((p: any) => p.active && p.brandActive && p.categoryActive),
  }
}

class BrandPage extends Component<BrandProps & RouteProps, BrandState> {

  constructor(props: any) {
    super(props);
    this.state = {
      brand: this.findBrand(this.props)
    };
  }

  componentWillReceiveProps(nextProps: any) {
    this.setState({
      brand: this.findBrand(nextProps)
    });
  }

  componentDidMount() {
    let element = document.getElementById("main-layout");
    if (element) {
      element.scrollIntoView();
    }
    setTimeout(() => {
      store.dispatch({ type: 'REFRESH_PRODUCTS' })
    });
  }

  findBrand(nextProps: any): any {
    if (store.getState()?.brandSlugMap) {
      let brandIndex = store.getState().brandSlugMap.get(nextProps.match.params.slug)
      if (brandIndex !== undefined && brandIndex !== null) {
        return store.getState()?.brands[brandIndex];
      }
    }
    return {};
  }

  render() {
    return (
      <div className="page brand-page">
        <Container fluid={true} className="brand-page-container" id="brand-page-container">
          <Row>
            <div className="brand-header">
            <div className="breadcrumb">
              <Link to="/" className="item">{process.env.REACT_APP_LOGO_NAME1} {process.env.REACT_APP_LOGO_NAME2}</Link>
              <Icon name="angle right"></Icon>
              <Link to="/brands" className="item">Brands</Link>
              <Icon name="angle right"></Icon>
              <span className="item">{this.state.brand?.name}</span>
            </div>
            <div className="brand">
              <div className="brand-image">
                <img className="image" src={window?.innerWidth <= 800 ? this.state.brand?.imageSmUrl : this.state.brand?.imageLgUrl}/>
              </div>
            </div>
            </div>
            
          </Row>
          <Products products={this.props.products.filter((p: any) => p.brandId && p.brandId === this.state.brand.id)} />
        </Container>
      </div>
    );
  }
}

export default connect(mapStateToProps)(BrandPage);