import React from 'react';
import { Form, Container, Icon, Loader, Dimmer, Label, Button } from 'semantic-ui-react';
import { CategoryModel } from '../../../entities/category.model';
import MyForm from './form';
import { ImageModel } from '../../../entities/image.model';
import ImageOnS3 from '../../../components/imageOnS3/imageOnS3.component';
import MyFormState from './MyFormState';
import { Redirect } from 'react-router-dom';

interface FormProps {
    match: any,
    history: any
}

interface CategoryFormState extends MyFormState {
    parentCategories: any[],
    newType: "",
    newUsage: "",
    model: CategoryModel
}

class CategoryForm extends MyForm<FormProps, CategoryFormState> {
    entity = "category";

    constructor(props: any) {
        super(props);
        this.state = {
            model: new CategoryModel,
            successMsg: "",
            errorMsg: "",
            loading: true,
            editing: false,
            editingSlug: false,
            slugValid: true,
            imageObj: ImageModel.empty(),
            imageObjs: {
                lg: ImageModel.empty(), sm: ImageModel.empty(), xs: ImageModel.empty(),
                image1: ImageModel.empty(), image2: ImageModel.empty(), image3: ImageModel.empty(),
                image4: ImageModel.empty(), image5: ImageModel.empty(), image6: ImageModel.empty()
            },
            parentCategories: [],
            newType: "",
            newUsage: "",
            redirect: "",
        }
        this.onParentChange = this.onParentChange.bind(this);
        this.onItemChange = this.onItemChange.bind(this);
        this.onUsageChange = this.onUsageChange.bind(this);
    }

    componentDidMount() {
        this.fetchAllCategories();// used for parent dropdown and subcategories
        this.fetchUsages();
    }

    fetchAllCategories() {
        var token = localStorage.getItem('adminToken')
        if (!token || token === 'undefined') {
            this.setState({ redirect: this.loginUrl })
            return;
        }
        fetch(process.env.REACT_APP_CHEF_API + "list/category", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': token,
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                if (res.status === 401) {
                    this.setState({ redirect: this.loginUrl})
                } else {
                    res.json().then(res => {
                        if (res.data) {
                            let parentCategories: any[] = [];
                            let category: any;
                            res.data.forEach((c: any) => {
                                if (!c.parentId && (!this.props.match.params.id || (this.props.match.params.id !== String(c.id)))) {
                                    parentCategories.push({
                                        key: c.id,
                                        value: c.id,
                                        text: c.name
                                    });
                                }
                                if (this.props.match.params.id && this.props.match.params.id === String(c.id)) {
                                    category = c;
                                    category.active = category.active === 1;
                                    category.isFinal = category.isFinal === 1;
                                }
                            });
                            let newState = { model: this.state.model, parentCategories: parentCategories, editing: this.state.editing }
                            if (category) {
                                this.imageNamePrefix = String(category.name).toLowerCase().replace(/[^a-z ]/g, "").replace("  ", " ").replace(" ", "-").trim()
                                this.originalModel = category;
                                if (category.imageLgUrl) {
                                    this.state.imageObjs.lg = new ImageModel(undefined, category.imageLgUrl, true, true);
                                }
                                if (category.imageSmUrl) {
                                    this.state.imageObjs.sm = new ImageModel(undefined, category.imageSmUrl, true, true);
                                }
                                if (category.imageXsUrl) {
                                    this.state.imageObjs.xs = new ImageModel(undefined, category.imageXsUrl, true, true);
                                }
                                newState.model = Object.assign({}, category);
                                newState.editing = true;
                            }
                            this.setState(newState);
                        }
                    })
                }
            })
            .catch(err => console.error(err))
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    fetchUsages() {
        var token = localStorage.getItem('adminToken')
        if (!token || token === 'undefined') {
            this.setState({ redirect: this.loginUrl })
            return;
        }
        if (this.props.match.params.id) {
            fetch(process.env.REACT_APP_CHEF_API + `category/${this.props.match.params.id}/usage`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': token,
                    'Content-Type': 'application/json'
                }
            })
                .then((res: any) => {
                    if (res.status === 401) {
                        this.setState({ redirect: this.loginUrl})
                    } else {
                        res.json().then((res: any) => {
                            if (res.data && res.data.length > 0) {
                                let model: any = this.state.model;
                                let types: any[] = [];
                                let usages: any[] = [];
                                res.data.forEach((u: any) => {
                                    u.active = true;
                                    if (u.type === 'type') {
                                        types.push(u);
                                    } else if (u.type === 'usage') {
                                        usages.push(u);
                                    }
                                });
                                this.originalModel.usages = Object.assign([], usages)
                                this.originalModel.types = Object.assign([], types)
                                model.types = types;
                                model.usages = usages;
                                this.setState(model);
                            }
                        })
                    }
                })
                .catch(err => console.error(err))
                .finally(() => {
                    this.setState({ loading: false });
                });
        }
    }

    onParentChange(e: any, drop: any) {
        let model: CategoryModel = this.state.model;
        model.parentId = drop.value;
        this.setState({ model: model });
    }

    onItemChange(e?: any) {
        this.setState({ newType: e.target.value });
    }

    onUsageChange(e?: any) {
        this.setState({ newUsage: e.target.value });
    }

    addType() {
        if (this.state.newType) {
            let model: any = this.state.model;
            if (this.state.model.types) {
                for (let type of this.state.model.types) {
                    if (type.name === this.state.newType) {
                        this.setState({ newType: "" });
                        return;
                    }
                }
            } else {
                model.types = [];
            }
            model.types.push({ id: null, type: 'type', active: true, name: this.state.newType });
            this.setState({ model: model, newType: "" });
        }
    }

    removeType(index: number) {
        let model: any = this.state.model;
        if (model.types[index].id !== undefined && model.types[index].id !== null) {
            model.types[index].active = false;
        } else {
            model.types.splice(index, 1)
        }
        this.setState({ model: model, newType: "" });
    }

    addUsage() {
        if (this.state.newUsage) {
            let model: any = this.state.model;
            if (this.state.model.usages) {
                for (let usage of this.state.model.usages) {
                    if (usage.name === this.state.newUsage) {
                        this.setState({ newUsage: "" });
                        return;
                    }
                }
            } else {
                model.usages = [];
            }
            model.usages.push({ id: null, active: true, type: "usage", name: this.state.newUsage });
            this.setState({ model: model, newUsage: "" });
        }
    }

    removeUsage(index: number) {
        let model: any = this.state.model;
        if (model.usages[index].id !== undefined && model.usages[index].id !== null) {
            model.usages[index].active = false;
        } else {
            model.usages.splice(index, 1)
        }
        this.setState({ model: model, newUsage: "" });
    }

    validate(): boolean {
        this.setState({ successMsg: "" });
        if (!this.state.model.name) {
            this.setState({ errorMsg: "Категорийн нэрийг оруулна уу" });
            return false;
        }
        if (this.state.editingSlug || !this.state.slugValid || !this.state.model.slug) {
            this.setState({ errorMsg: "URL нэрийг шалгана уу." });
            return false;
        }
        if (!this.state.imageObjs.lg.file && !this.state.model.imageLgUrl) {
            this.setState({ errorMsg: "Том хэмжээтэй зураг оруулна уу" });
            return false;
        }
        if (!this.state.imageObjs.sm.file && !this.state.model.imageSmUrl) {
            this.setState({ errorMsg: "Дунд хэмжээтэй зураг оруулна уу" });
            return false;
        }
        if (!this.state.imageObjs.xs.file && !this.state.model.imageXsUrl) {
            this.setState({ errorMsg: "Жижиг хэмжээтэй зураг оруулна уу" });
            return false;
        }
        if (this.state.model.id !== undefined) {
            if (this.state.model.name === this.originalModel.name
                && this.state.model.shortName === this.originalModel.shortName
                && this.state.model.slug === this.originalModel.slug
                && this.state.model.parentId === this.originalModel.parentId
                && this.state.model.info === this.originalModel.info
                && this.state.imageObjs.lg.name === this.originalModel.imageLgUrl
                && this.state.imageObjs.sm.name === this.originalModel.imageSmUrl
                && this.state.imageObjs.xs.name === this.originalModel.imageXsUrl
                && this.state.model.types === this.originalModel.types
                && this.state.model.active === this.originalModel.active
                && this.state.model.isFinal === this.originalModel.isFinal
                && this.state.model.usages?.filter(u => u.active).map(u => u.name).join(",") === this.originalModel.usages?.map((u: any) => u.name).join(",")
                && this.state.model.types?.filter(u => u.active).map(u => u.name).join(",") === this.originalModel.types?.map((u: any) => u.name).join(",")
            ) {
                this.setState({ errorMsg: "Өөрчлөлт байхгүй байна" });
                return false;
            }
        }
        this.setState({ errorMsg: "" });
        return true;
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <Container>
                <Dimmer active={this.state.loading} inverted>
                    <Loader size='medium'></Loader>
                </Dimmer>
                <h2>{!this.state.editing ? 'Шинэ' : ''} Категори</h2>
                <Form>
                    <Form.Group>
                        {this.state.successMsg ? <div className="success-msg">{this.state.successMsg}</div> : null}
                        {this.state.errorMsg ? <div className="error-msg">{this.state.errorMsg}</div> : null}
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field required label='Категорийн нэр' control='input' value={this.state.model.name} 
                            onBlur={this.onNameBlur}
                            onChange={this.onNameChange} />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field label='Категорийн товч нэр' control='input' value={this.state.model.shortName} onChange={this.onShortNameChange} />
                    </Form.Group>
                    <Form.Group>
                        <div className="required field">
                            <label className="required">URL нэр</label>
                            { this.state.editingSlug ?
                            <React.Fragment>
                                <div className="flex-start">
                                    <Form.Input required value={this.state.model.slug || ""}
                                        onBlur={this.onSlugBlur}
                                        onChange={this.onSlugChange} />
                                    { this.state.slugValid ? null :
                                        <React.Fragment>
                                            <Icon name="warning circle" color='red' size='large'/>
                                            <span className="error-msg">URL нэр ашиглагдсан байна</span>
                                        </React.Fragment>
                                    }
                                    &nbsp;&nbsp;
                                    <Button className="cursor" color='orange'
                                        disabled={this.originalModel.slug === this.state.model.slug}
                                        onClick={this.postNewSlug}>Шалгах</Button>
                                    <Button className="cursor" onClick={this.cancelSlugChange}>Болих</Button>
                                </div>
                                <div>
                                    <br/>
                                    <Icon name="info circle" color="blue" />
                                    Зөвхөн англи үсэг болон - тэмдэг ашиглана
                                </div>
                            </React.Fragment>
                            :
                            <div>
                                <Label>{`${process.env.REACT_APP_DOMAIN}/category/${this.state.model.parentId ? "**parent**/" : ""}${this.state.model.slug}`}</Label>
                                { this.state.slugValid ?
                                    (this.state.model.slug && this.state.model.slug.length > 0) ?
                                        <Icon name="check circle" color='green' size='large'/> : null :
                                    <Icon name="warning circle" color='red' size='large'/>
                                }
                                &nbsp;&nbsp;
                                <Icon className="cursor" name="edit outline" size='large' onClick={this.toggleSlug}/>
                            </div>
                        }
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <div className="field">
                            <label>Үндсэн категори</label>
                        </div>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Dropdown placeholder='Үндсэн категори' fluid labeled clearable selection options={this.state.parentCategories}
                            value={this.state.model.parentId}
                            onChange={this.onParentChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.TextArea label='Тайлбар' width="16" value={this.state.model.info} onChange={this.onInfoChange} />
                    </Form.Group>
                    <Form.Group className="flex-space-between">
                        <div className="field">
                            <label>Том хэмжээтэй зураг (1280 x 320 pixel)(4:1)</label>
                        </div>
                        {this.state.imageObjs.lg.uploaded ?
                            <div className={this.state.imageObjs.lg.uploaded && this.state.imageObjs.lg.showOriginal ? "flex-space-between" : "hidden-div"}>
                                <ImageOnS3 imgKey={this.state.model.imageLgUrl} width='160px' height='40px' />
                                <Icon className="cursor margin-10" name="trash" size='large' onClick={() => this.toggleImage('imageLgUrl')} />
                            </div> : null}
                        <Icon className={this.state.imageObjs.lg.uploaded && !this.state.imageObjs.lg.showOriginal ? "" : "hidden-div"} name='redo' size='large' onClick={() => this.toggleImage('imageLgUrl')} />
                        <Form.Input control='input' type="file" accept="image/png, image/jpeg" onChange={this.handleImageLgChange} />
                    </Form.Group>
                    <Form.Group className="flex-space-between">
                        <div className="field">
                            <label>Дунд хэмжээтэй зураг (800 x 200 pixel)(4:1)</label>
                        </div>
                        {this.state.imageObjs.sm.uploaded ?
                            <div className={this.state.imageObjs.sm.uploaded && this.state.imageObjs.sm.showOriginal ? "flex-space-between" : "hidden-div"}>
                                <ImageOnS3 imgKey={this.state.model.imageSmUrl} width='160px' height='40px' />
                                <Icon className="cursor margin-10" name="trash" size='large' onClick={() => this.toggleImage('imageSmUrl')} />
                            </div> : null}
                        <Icon className={this.state.imageObjs.sm.uploaded && !this.state.imageObjs.sm.showOriginal ? "" : "hidden-div"} name='redo' size='large' onClick={() => this.toggleImage('imageSmUrl')} />
                        <Form.Field control='input' type="file" accept="image/png, image/jpeg" onChange={this.handleImageSmChange} />
                    </Form.Group>
                    <Form.Group className="flex-space-between">
                        <div className="field">
                            <label>Жижиг хэмжээтэй зураг (400 x 300 pixel)(4:3)</label>
                        </div>
                        {this.state.imageObjs.xs.uploaded ?
                            <div className={this.state.imageObjs.xs.showOriginal ? "flex-space-between" : "hidden-div"}>
                                <ImageOnS3 imgKey={this.state.model.imageXsUrl} width="80px" height="60px" />
                                <Icon className="cursor margin-10" name="trash" size='large' onClick={() => this.toggleImage('imageXsUrl')} />
                            </div> : null}
                        <Icon className={this.state.imageObjs.xs.uploaded && !this.state.imageObjs.xs.showOriginal ? "" : "hidden-div"} name='redo' size='large' onClick={() => this.toggleImage('imageXsUrl')} />
                        <Form.Field control='input' type="file" accept="image/png, image/jpeg" onChange={this.handleImageXsChange} />
                    </Form.Group>
                    <Form.Group>
                        <div className="field">
                            <label>Бүтээгдэхүүний төрлүүд</label>
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Input value={this.state.newType} onChange={this.onItemChange} />
                        <Form.Button onClick={() => this.addType()}>Нэмэх</Form.Button>
                    </Form.Group>
                    <Form.Group>
                        <div className="category-types">
                            {this.state.model.types?.map((type: any, index: number) => {
                                return type.active ? <Label color='blue' key={type.name}>{type.name}&nbsp;&nbsp;
                                    <Icon className="cursor" name="trash" onClick={() => this.removeType(index)} />
                                </Label> : null
                            })}
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <div className="field">
                            <label>Бүтээгдэхүүний хэрэглээ</label>
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Input value={this.state.newUsage} onChange={this.onUsageChange} />
                        <Form.Button onClick={() => this.addUsage()}>Нэмэх</Form.Button>
                    </Form.Group>
                    <Form.Group>
                        <div className="category-usages">
                            {this.state.model.usages?.map((usage: any, index: number) => {
                                return usage.active ? <Label color='blue' key={usage.name}>{usage.name}&nbsp;&nbsp;
                                    <Icon className="cursor" name="trash" onClick={() => this.removeUsage(index)} />
                                </Label> : null
                            })}
                        </div>
                    </Form.Group>
                    {/* <Form.Group>
                        <Form.Checkbox checked={this.state.model.isFinal} onChange={this.onIsFinalChange} label='Дор нь дэд категори байх уу'/>
                    </Form.Group> */}
                    <Form.Group>
                        <Form.Checkbox checked={this.state.model.active} onChange={this.onActiveChange} label='Идэвхитэй' />
                    </Form.Group>
                    <br />
                    <Form.Button disabled={this.state.loading} onClick={() => this.save()}>Хадгалах</Form.Button>
                </Form>
            </Container>
        );
    }
}

export default CategoryForm;