const parseStatus = (status) => {
    switch (status) {
      case 'PLACED': return 'ТӨЛӨГДӨӨГҮЙ'
      case 'CONFIRMED': return 'АМЖИЛТТАЙ'
      case 'CANCELLED': return 'ЦУЦЛАГДСАН'
      case 'ARCHIVED': return 'УСТГАСАН'
      default: return ""
    }
}

const parseDeliveryStatus = (status) => {
  switch (status) {
    case 'PLACED': return 'САГС'
    case 'CONFIRMED': return 'ЗАХИАЛАГДСАН'
    case 'DELIVERED': return 'ХҮРГЭСЭН'
    case 'CANCELLED': return 'ЦУЦЛАГДСАН'
    case 'ARCHIVED': return 'УСТГАСАН'
    default: return ""
  }
}

const formatDateTime = (date) => {
  let month = date.getMonth()+1;
  let day = date.getDate();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();
  month = month < 10 ? '0'+month : month;
  day = day < 10 ? '0'+day : day;
  hours = hours < 10 ? '0'+hours : hours;
  minutes = minutes < 10 ? '0'+minutes : minutes;
  seconds = seconds < 10 ? '0'+seconds : seconds;
  var strTime = hours + ':' + minutes + ":" + seconds;
  return date.getFullYear() + "-" + month + "-" + day + "  " + strTime;
}

function formatDate(d) {
	let month = '' + (d.getMonth() + 1)
	let day = '' + d.getDate()
	let year = d.getFullYear()

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

    return [year, month, day].join('-');
}

const formatMongolianName = (parentName, ownName) => {
  let result = "";
  if (parentName && parentName.length > 1) {
    if (ownName) {
      result += parentName.substring(0, 1);
    }
    else {
      result += parentName;
    }
  }
  if (ownName) {
    result += ". ";
    result += ownName;
  }
  return result;
}

const validateEmail = (email) => {
  if (!email) return false;
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const validatePhone = (phone) => {
  if (!phone) return false;
  var re = /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/;
  return re.test(String(phone).toLowerCase());
}

const validatePassword = (password) => {
  if (!password) return false;
  return password.length > 6;
}

module.exports = {
    parseStatus,
    parseDeliveryStatus,
    formatDateTime,
    formatDate,
    formatMongolianName,
    validatePassword,
    validateEmail,
    validatePhone,
}