import React, { Component } from 'react';
import './categories.scss';
import SubCategoryItem from './sub-category-item';
import { Col } from 'react-grid-system';

interface CategoriesProp {
  categories: any[],
  parentCategory: any
}

class SubCategories extends Component<CategoriesProp> {

  render() {
    return (
      <div className={"categories-container" + (this.props.categories?.length > 0 ? " has-content" : "")}>
        {this.props.categories.map((category, i) => {
          return <Col xs={6} sm={4} md={4} lg={4} xl={3} key={category.id}>
            <SubCategoryItem parentCategory={this.props.parentCategory} item={category}/>
          </Col>
        })}
      </div>
    );
  }
}

export default SubCategories