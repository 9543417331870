import React, { Component } from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';

const AdminContainer = styled.section`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const loginUrl = "/mongol-login";

interface AdminState {
  redirect: string
}

interface AdminProps {
  children: any
}

class AdminPage extends Component<AdminProps, AdminState> {
  constructor(props: any) {
    super(props)
    this.state = { redirect: "" }
  }

  componentDidMount() {
    var token = localStorage.getItem('adminToken')
    if (token && token !== 'undefined') {
      fetch(process.env.REACT_APP_CHEF_API + 'refresh', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': token,
            'Content-Type': 'application/json'
        }
      })
      .then((res: any) => {
          if (res.ok) {
              res.json().then((resJson: any) => {
                  localStorage.setItem('adminToken', resJson.token)
              })
          } else {
            this.setState({ redirect: loginUrl })
          }
      })
      .catch(err => {
        console.error(err)
        this.setState({ redirect: "/" })
      })
    } else {
      this.setState({ redirect: loginUrl })
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect}/>
    }
    return (
      <React.Fragment>
        <AdminContainer>
          {this.props.children}
        </AdminContainer>
      </React.Fragment>
    );
  }
}

export default AdminPage;