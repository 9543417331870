import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import './order.invoice.scss';
import { Button, Icon } from 'semantic-ui-react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import OrderDetailsModel from '../../entities/order.details.model';

function OrderInvoice(props: any) {

    const bucket = process.env.REACT_APP_S3_BUCKET
    const region = process.env.REACT_APP_S3_REGION
    const [order, setOrder] = useState(new OrderDetailsModel())

    useEffect(() => {
        fetchOrder()
    }, [props.token])

    const fetchOrder = () => {
        var clientToken = localStorage.getItem('clientToken')
        if (clientToken && clientToken !== 'undefined' && props.token && props.token !== 'undefined') {
            fetch(process.env.REACT_APP_CHEF_API + 'client/order-detail/' + props.token, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': clientToken,
                    'Content-Type': 'application/json'
                }
            }).then((res: any) => {
                if (res.ok) {
                    res.json().then((resJson: any) => {
                        if (resJson.order && resJson.order.id) {
                            let orderObj = resJson.order;
                            for (let delivery of orderObj.deliveries) {
                                let date = new Date(delivery.date)
                                delivery.date = `${date.getMonth() + 1} сарын ${date.getUTCDate()}`
                                for (let item of delivery.items) {
                                    item.product.imageXsUrl = `https://${bucket}.s3.${region}.amazonaws.com/${item.product.imageXsUrl}`
                                }
                            }
                            setOrder(orderObj)
                        }
                    })
                }
            }).catch(err => {
                console.error(err)
            })
        }
    }

    function printDocument() {
        const input = document.getElementById('pdf-print');
        html2canvas(input!)
          .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            pdf.addImage(imgData, 'JPEG', 0, 0, 200, 0);
            // pdf.output('dataurlnewwindow');
            pdf.save("invoice.pdf");
          })
        ;
    }

    return (
        <React.Fragment>
            <div  className="order-invoice-container">
                <Button className="ui orange button button-print" onClick={printDocument}>Нэхэмжлэх татах</Button>
                <div className="flex-div width-100">
                    {order?.id ?
                        <section id="pdf-print">
                            <div className="order-invoice">
                                <div className="invoice-title">
                                    <strong>Нэхэмжлэх</strong>
                                </div>

                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Нэхэмжлэгч:</td>
                                            <td>Төлөгч:</td>
                                        </tr>
                                        <tr>
                                            <td>Байгууллага: Шеф Про ХХК</td>
                                            <td>
                                                {order.company?.name ? "Байгууллага: " : ""}
                                                {order.company?.name ? order.company.name : props.client.ownName}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Регистр: 6522556</td>
                                            <td>
                                                {order.company?.name ? "Регистр: " + order.company.register : ""}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Утас: 99190290</td>
                                            <td>Утас: {order.company?.name ? "" : props.client.phone}</td>
                                        </tr>
                                        <tr>
                                            <td>Хаяг: Хан-Уул дүүрэг, 5-р хороо, Яармаг, Наадамчдийн зам 77</td>
                                            <td>Хаяг:
                                                {order.company?.name ?
                                                <span> {order.company.town}, {order.company.district}, {order.company.unit}, {order.company.street}</span>
                                                :
                                                <span> {props.client.town}, {props.client.district}, {props.client.unit}, {props.client.street}</span>}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div className="order-address">
                                    <span className="col-top">Хүргэлтийн хаяг: </span>
                                    <span>{order.town}, {order.district}, {order.unit}, {order.street}</span>
                                </div>

                                {order.deliveries?.map((delivery: any, dindex: number) => {
                                    return <div className="order-delivery" key={'delivery-' + delivery.id}>
                                        <div className="order-delivery-top">
                                            <span className="delivery-date">
                                                <Icon name="calendar outline" />{delivery?.date} &nbsp;
                                            </span>
                                            <span className="delivery-cost"> - Хүргэлтийн үнэ: - <NumberFormat displayType="text" value={delivery?.cost} thousandSeparator={true} suffix="₮" /></span>
                                        </div>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>Бүтээгдэхүүн</th>
                                                    <th>ш/үнэ (НӨАТ багтсан)</th>
                                                    <th>Тоо</th>
                                                    <th>Нийт үнэ (НӨАТ багтсан)</th>
                                                </tr>
                                                {delivery?.items?.map((order: any, index: number) => {
                                                    return <tr key={`order-${order.id}-${index}`}>
                                                        <td>
                                                            <Link className="underline" to={`/product/${order.product.slug}`}>{order.product.name}</Link></td>
                                                        <td>
                                                            <NumberFormat displayType="text" value={order.price} suffix="₮" thousandSeparator={true} />
                                                        </td>
                                                        <td>{order.count}</td>
                                                        <td>
                                                            <NumberFormat displayType="text" value={order.price * order.count} suffix="₮" thousandSeparator={true} />
                                                        </td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                })
                                }
                            </div>
                            <div className="order-summary">
                                <div className="order-amount">Барааны нийт үнэ (НӨАТ багтсан): <NumberFormat displayType="text" value={order.totalAmount - order.totalDeliveryCost} suffix="₮" thousandSeparator={true} /></div>
                                <div className="order-amount">Хүргэлтийн нийт үнэ (НӨАТ багтсан): <NumberFormat displayType="text" value={order.totalDeliveryCost} suffix="₮" thousandSeparator={true} /></div>
                                <div className="order-amount">Нийт үнэ (НӨАТ багтсан): <NumberFormat displayType="text" value={order.totalAmount} suffix="₮" thousandSeparator={true} /></div>
                            </div>
                            <div className="order-signature">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Шеф Про ХХК, нягтлан:</td>
                                            <td>
                                                <strong>Хүлээн авсан: </strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img src="/chef-pro-seal.png" height="150"/>
                                            </td>
                                            <td>__________________________</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </section> : null}
                </div>
            </div>
        </React.Fragment>
    )
}

export default OrderInvoice;