export class ProviderModel {
    id: any
    name: string = ''
    email: string = ''
    phone: string = ''
    register: string = ''
    contactName: string = ''
    contactEmail: string = ''
    contactPhone: string = ''
    contactRole: string = ''
    info: string = ''
    imageUrl: string = ''
    active: boolean = true
    approved: boolean = false
    town: string = 'Улаанбаатар'
    district: string = ''
    unit: string = ''
    street: string = ''
    zipcode: string = ''
}