import React from 'react';
import { Form, Container, Icon, Loader, Dimmer, Label, Button } from 'semantic-ui-react';
import MyForm from './form';
import { ImageModel } from '../../../entities/image.model';
import { ProductModel } from '../../../entities/admin.product.model';
import ImageOnS3 from '../../../components/imageOnS3/imageOnS3.component';
import MyFormState from './MyFormState';
import { Redirect } from 'react-router-dom';

interface FormProps {
    match: any,
    history: any
}

interface FormState extends MyFormState {
    parentCategories: any[],
    subCategories: any[],
    // categoryTypes: string[],
    // categoryUsages: string[],
    brands: any[],
    providers: any[],
    units: any[],
    model: ProductModel
}

class ProductForm extends MyForm<FormProps, FormState> {
    entity = "product";
    typeArr: string[] = [];
    allSubCategories: any[] = [];
    triedUsages = 0;

    constructor(props: any) {
        super(props);

        let units: any[] = [];
        ['грамм', 'кг', 'мл', 'литр'].forEach((unit: string) => {
            units.push({ key: unit, value: unit, text: unit })
        })

        this.state = {
            model: new ProductModel,
            successMsg: "",
            errorMsg: "",
            loading: true,
            editing: false,
            editingSlug: false,
            slugValid: true,
            imageObj: ImageModel.empty(),
            imageObjs: {
                lg: ImageModel.empty(), sm: ImageModel.empty(), xs: ImageModel.empty(),
                image1: ImageModel.empty(), image2: ImageModel.empty(), image3: ImageModel.empty(),
                image4: ImageModel.empty(), image5: ImageModel.empty(), image6: ImageModel.empty()
            },
            parentCategories: [],
            subCategories: [],
            providers: [],
            brands: [],
            // categoryTypes: [],
            units: units,
            redirect: "",
        }

        this.onParentCategoryChange = this.onParentCategoryChange.bind(this);
        this.onSubCategoryChange = this.onSubCategoryChange.bind(this);
        this.onBrandChange = this.onBrandChange.bind(this);
        this.onProviderChange = this.onProviderChange.bind(this);
        this.onSizeUnitChange = this.onSizeUnitChange.bind(this);
        this.onSaleActiveChange = this.onSaleActiveChange.bind(this);
        this.onSalePriceChange = this.onSalePriceChange.bind(this);
        this.onSaleUntilChange = this.onSaleUntilChange.bind(this);
        this.onDeliveryDayChange = this.onDeliveryDayChange.bind(this);
        this.onUnitAdded = this.onUnitAdded.bind(this);
    }

    componentDidMount() {
        var token = localStorage.getItem('adminToken')
        if (token && token !== 'undefined') {
            if (this.props.match.params.id) {
                this.setState({ loading: true });
                let url = `${process.env.REACT_APP_CHEF_API}get/${this.entity}/${this.props.match.params.id}`;
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                })
                .then(res => {
                    if (res.status === 401) {
                        this.setState({ redirect: this.loginUrl})
                    } else {
                        res.json().then(res => {
                            if (res.data && res.data.length > 0) {
                                let model = res.data[0];
                                model.active = model.active === 1;
                                model.saleActive = model.saleActive === 1;
                                if (model.saleUntil) {
                                     model.saleUntil = model.saleUntil.substring(0, 10)
                                }
                                let units = this.state.units;
                                if (['грамм', 'кг', 'мл', 'литр'].indexOf(model.unit) === -1) {
                                    // custom unit
                                    units.push({ key: model.unit, value: model.unit, text: model.unit })
                                }
                                // prepare image objs
                                this.state.imageObjs.lg = new ImageModel(undefined, model.imageLgUrl, true, true);
                                this.state.imageObjs.sm = new ImageModel(undefined, model.imageSmUrl, true, true);
                                this.state.imageObjs.xs = new ImageModel(undefined, model.imageXsUrl, true, true);
                                if (model.image1) {
                                    this.state.imageObjs.image1 = new ImageModel(undefined, model.image1, true, true);
                                }
                                if (model.image2) {
                                    this.state.imageObjs.image2 = new ImageModel(undefined, model.image2, true, true);
                                }
                                if (model.image3) {
                                    this.state.imageObjs.image3 = new ImageModel(undefined, model.image3, true, true);
                                }
                                if (model.image4) {
                                    this.state.imageObjs.image4 = new ImageModel(undefined, model.image4, true, true);
                                }
                                if (model.image5) {
                                    this.state.imageObjs.image5 = new ImageModel(undefined, model.image5, true, true);
                                }
                                if (model.image6) {
                                    this.state.imageObjs.image6 = new ImageModel(undefined, model.image6, true, true);
                                }
                                this.imageNamePrefix = String(model.name).toLowerCase().replace(/[^a-z ]/g, "").replace("  ", " ").replace(" ", "-").trim()
                                this.setState({
                                    model: model,
                                    units: units,
                                    loading: false,
                                    editing: true,
                                    imageObjs: this.state.imageObjs
                                });
                                this.originalModel = Object.assign({}, model);
                                this.fetchUsages(model.subCategoryId || model.categoryId);
                            }
                            this.getCategories();
                        })
                    }
                })
                .catch(err => console.error(err))
                .finally(() => {
                    this.setState({ loading: false });
                });

            } else {
                this.setState({ loading: false });
                this.getCategories();
            }

            // Get dropdown values
            this.getBrands();
            this.getProviders();
        }
        else {
            this.setState({ redirect: this.loginUrl });
        }
    }

    getCategories() {
        var token = localStorage.getItem('adminToken')
        if (!token || token === 'undefined') {
            this.setState({ redirect: this.loginUrl })
            return;
        }

        fetch(process.env.REACT_APP_CHEF_API + "list/category", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': token,
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            if (res.status === 401) {
                this.setState({ redirect: this.loginUrl })
            }
            return res
        })
        .then(res => res.json())
        .then(res => {
            if (res.data) {
                let parentCategories: any[] = [];
                let subCategories: any[] = [];
                res.data.forEach((c: any) => {
                    if (!c.active) {
                        return;
                    }
                    if (!c.parentId) {
                        parentCategories.push({
                            key: c.id,
                            value: c.id,
                            text: c.name
                        });
                    } else {
                        subCategories.push({
                            key: c.id,
                            value: c.id,
                            text: c.name
                        });
                        this.allSubCategories.push(c);
                    }
                });
                this.setState({ parentCategories: parentCategories });
                if (this.state.model.categoryId) {
                    this.setState({
                        subCategories: this.allSubCategories
                            .filter(c => c.parentId === this.state.model.categoryId)
                            .map(c => new Object({ 'key': c.id, 'value': c.id, 'text': c.name }))
                    });
                }
            }
        })
        .catch(err => console.error(err));
    }

    getBrands() {
        var token = localStorage.getItem('adminToken')
        if (!token || token === 'undefined') {
            this.setState({ redirect: this.loginUrl })
            return;
        }

        fetch(process.env.REACT_APP_CHEF_API + "list/brand", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': token,
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            if (res.status === 401) {
                this.setState({ redirect: this.loginUrl })
            }
            return res
        })
        .then(res => res.json())
        .then(res => {
            console.log(res);
            if (res.data) {
                let data: any[] = [];
                res.data.forEach((c: any) => {
                    if (c.active) {
                        data.push({
                            key: c.id,
                            value: c.id,
                            text: c.name
                        });
                    }
                });
                this.setState({ brands: data });
            }
        })
        .catch(err => console.error(err));
    }

    getProviders() {
        var token = localStorage.getItem('adminToken')
        if (!token || token === 'undefined') {
            this.setState({ redirect: this.loginUrl })
            return;
        }
        fetch(process.env.REACT_APP_CHEF_API + "list/provider", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': token,
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            if (res.status === 401) {
                this.setState({ redirect: this.loginUrl })
            }
            return res
        })
        .then(res => res.json())
        .then(res => {
            console.log(res);
            if (res.data) {
                let data: any[] = [];
                res.data.forEach((c: any) => {
                    if (c.active) {
                        data.push({
                            key: c.id,
                            value: c.id,
                            text: c.name
                        });
                    }
                });
                this.setState({ providers: data });
            }
        })
        .catch(err => console.error(err));
    }

    onSaleActiveChange(e?: any) {
        let model: any = this.state.model;
        model.saleActive = Boolean(e.target.checked);
        this.setState({ model: model });
    }

    onSalePriceChange(e?: any) {
        let model: any = this.state.model;
        model.salePrice = Number(e.target.value);
        this.setState({ model: model });
    }

    onSaleUntilChange(e?: any) {
        let model: any = this.state.model;
        model.saleUntil = e.target.value;
        this.setState({ model: model });
    }

    onDeliveryDayChange(e?: any) {
        let model: any = this.state.model;
        model.deliveryDayMargin = e.target.value;
        this.setState({ model: model });
    }

    fetchUsages(categoryId: any) {
        if (this.triedUsages > 3) {
            return;
        }
        var token = localStorage.getItem('adminToken')
        if (!token || token === 'undefined') {
            this.setState({ redirect: this.loginUrl })
            return;
        }
        if (this.props.match.params.id) {
            this.triedUsages++;
            fetch(process.env.REACT_APP_CHEF_API + `product/usage/${this.props.match.params.id}/${categoryId}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': token,
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                if (res.status === 401) {
                    this.setState({ redirect: this.loginUrl })
                }
                return res
            })
            .then(res => res.json())
            .then(res => {
                if (res.data) {
                    let model: any = this.state.model;
                    let types: any[] = [];
                    let usages: any[] = [];
                    res.data.forEach((u: any) => {
                        u.active = (u.id !== null && u.id !== undefined)
                        u.productId = this.props.match.params.id
                        if (u.type === 'type') {
                            types.push(u);
                        } else if (u.type === 'usage') {
                            usages.push(u);
                        }
                    });
                    this.originalModel.usages = Object.assign([], usages.map(e => Object.assign({}, e)))
                    this.originalModel.types = Object.assign([], types.map(e => Object.assign({}, e)))
                    model.types = types;
                    model.usages = usages;
                    this.setState(model);
                }
            })
            .catch(err => {
                console.error(err)
                this.fetchUsages(categoryId);
            })
            .finally(() => {
                this.setState({ loading: false });
            });

        } else if (this.state.model.categoryId) {
            fetch(process.env.REACT_APP_CHEF_API + `product/${categoryId}/usage`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': token,
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                if (res.status === 401) {
                    this.setState({ redirect: this.loginUrl })
                }
                return res
            })
            .then(res => res.json())
            .then(res => {
                if (res.data && res.data.length > 0) {
                    let model: any = this.state.model;
                    let types: any[] = [];
                    let usages: any[] = [];
                    res.data.forEach((u: any) => {
                        u.active = (u.id !== null && u.id !== undefined)
                        if (u.type === 'type') {
                            types.push(u);
                        } else if (u.type === 'usage') {
                            usages.push(u);
                        }
                    });
                    this.originalModel.usages = Object.assign([], usages)
                    this.originalModel.types = Object.assign([], types)
                    model.types = types;
                    model.usages = usages;
                    this.setState(model);
                }
                else {
                    let model: any = this.state.model;
                    model.types = [];
                    model.usages = [];
                    this.setState(model)
                }
            })
            .catch(err => console.error(err))
            .finally(() => {
                this.setState({ loading: false });
            });
        }
    }

    toggleUsageStatus(index: number, type: string) {
        let model: any = this.state.model;
        if (type === 'type') {
            model.types[index].active = !model.types[index].active
        } else if (type === 'usage') {
            model.usages[index].active = !model.usages[index].active
        }
        this.setState({ model: model })
    }

    onParentCategoryChange(e: any, drop: any) {
        let model: any = this.state.model;
        model.categoryId = drop.value;
        model.subCategoryId = null;
        this.setState({
            model: model, subCategories: this.allSubCategories
                .filter(c => c.parentId === drop.value)
                .map(c => new Object({ key: c.id, value: c.id, text: c.name }))
        }, () => {
            this.triedUsages = 0;
            this.fetchUsages(model.categoryId);
        });
    }

    onSubCategoryChange(e: any, drop: any) {
        let model: any = this.state.model;
        model.subCategoryId = drop.value;
        this.setState({ model: model }, () => {
            this.triedUsages = 0;
            this.fetchUsages(model.subCategoryId);
        });
    }

    onBrandChange(e: any, drop: any) {
        let model: any = this.state.model;
        model.brandId = drop.value;
        this.setState({ model: model });
    }

    onProviderChange(e: any, drop: any) {
        let model: any = this.state.model;
        model.providerId = drop.value;
        this.setState({ model: model });
    }

    onSizeUnitChange(e: any, drop: any) {
        let model: any = this.state.model;
        model.unit = drop.value;
        this.setState({ model: model });
    }

    onUnitAdded(e:any, drop: any) {
        let units = this.state.units;
        units.push({ key: drop.value, value: drop.value, text: drop.value })
        this.setState({ units: units})
    }

    validate(): boolean {
        // prepare type string from typeArr
        // this.state.model.types = this.typeArr.join(",");

        this.setState({ successMsg: "" });
        if (!this.state.model.name) {
            this.setState({ errorMsg: "Бүтээгдэхүүний нэр оруулна уу" });
            return false;
        }
        if (this.state.editingSlug || !this.state.slugValid || !this.state.model.slug) {
            this.setState({ errorMsg: "URL нэрийг шалгана уу." });
            return false;
        }
        if (!this.state.model.categoryId) {
            this.setState({ errorMsg: "Үндсэн категори сонгоно уу" });
            return false;
        }
        if (this.state.subCategories.length > 0 && !this.state.model.subCategoryId) {
            this.setState({ errorMsg: "Дэд категори сонгоно уу" });
            return false;
        }
        if (!this.state.model.brandId) {
            this.setState({ errorMsg: "Брэнд сонгоно уу" });
            return false;
        }
        if (!this.state.model.providerId) {
            this.setState({ errorMsg: "Нийлүүлэгч сонгоно уу" });
            return false;
        }
        if (!this.state.model.price) {
            this.setState({ errorMsg: "Бүтээгдэхүүний үнэ оруулна уу" });
            return false;
        }
        if (this.state.model.saleActive) {
            if (!this.state.model.salePrice) {
                this.setState({ errorMsg: "Хямдарсан үнэ оруулна уу" });
                return false;
            }
            if (this.state.model.salePrice >= this.state.model.price) {
                this.setState({ errorMsg: "Хямдарсан үнэ үндсэн үнээс бага байх хэрэгтэй" });
                return false;
            }
            if (!this.state.model.saleUntil) {
                this.setState({ errorMsg: "Хямдралын сүүлийн өдрийг оруулна уу" });
                return false;
            }
        }
        if (!this.state.model.deliveryDayMargin) {
            this.setState({ errorMsg: "Хүргэх өдрийн интервал оруулна уу" });
            return false;
        }
        if (this.state.model.deliveryDayMargin < 1 || this.state.model.deliveryDayMargin > 50) {
            this.setState({ errorMsg: "Хүргэх өдрийн интервал 1-50 байх ёстой" });
            return false;
        }
        if (!this.state.imageObjs.lg.file && !this.state.model.imageLgUrl) {
            this.setState({ errorMsg: "Том хэмжээтэй зураг оруулна уу" });
            return false;
        }
        if (!this.state.imageObjs.sm.file && !this.state.model.imageSmUrl) {
            this.setState({ errorMsg: "Дунд хэмжээтэй зураг оруулна уу" });
            return false;
        }
        if (!this.state.imageObjs.xs.file && !this.state.model.imageXsUrl) {
            this.setState({ errorMsg: "Жижиг хэмжээтэй зураг оруулна уу" });
            return false;
        }
        if (this.state.model.id !== undefined) {
            if (this.state.model.name === this.originalModel.name
                && this.state.model.shortName === this.originalModel.shortName
                && this.state.model.slug === this.originalModel.slug
                && this.state.model.categoryId === this.originalModel.categoryId
                && this.state.model.brandId === this.originalModel.brandId
                && this.state.model.providerId === this.originalModel.providerId
                && this.state.model.info === this.originalModel.info
                && this.state.model.size === this.originalModel.size
                && this.state.model.unit === this.originalModel.unit
                && this.state.model.price === this.originalModel.price
                && this.state.model.saleActive === this.originalModel.saleActive
                && this.state.model.salePrice === this.originalModel.salePrice
                && this.state.model.saleUntil === this.originalModel.saleUntil
                && this.state.model.deliveryDayMargin === this.originalModel.deliveryDayMargin
                && this.state.imageObjs.lg.name === this.originalModel.imageLgUrl
                && this.state.imageObjs.sm.name === this.originalModel.imageSmUrl
                && this.state.imageObjs.xs.name === this.originalModel.imageXsUrl
                && this.state.imageObjs.image1.name === this.originalModel.image1
                && this.state.imageObjs.image2.name === this.originalModel.image2
                && this.state.imageObjs.image3.name === this.originalModel.image3
                && this.state.imageObjs.image4.name === this.originalModel.image4
                && this.state.imageObjs.image5.name === this.originalModel.image5
                && this.state.imageObjs.image6.name === this.originalModel.image6
                && this.state.model.active === this.originalModel.active
                && this.state.model.usages?.filter(u => u.active).map(u => u.name).join(",") === this.originalModel.usages?.filter((u: any) => u.active).map((u: any) => u.name).join(",")
                && this.state.model.types?.filter(u => u.active).map(u => u.name).join(",") === this.originalModel.types?.filter((u: any) => u.active).map((u: any) => u.name).join(",")
            ) {
                this.setState({ errorMsg: "Өөрчлөлт байхгүй байна" });
                return false;
            }
        }
        this.setState({ errorMsg: "" });
        return true;
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <Container>
                <Dimmer active={this.state.loading} inverted>
                    <Loader size='medium'></Loader>
                </Dimmer>
                <h2>{!this.state.editing ? 'Шинэ' : ''} Бүтээгдэхүүн</h2>
                <Form>
                    {!this.state.editing ?
                        <Form.Group className="flex-start">
                            <Icon name="info circle" color="orange" />
                            <p>Шинэ бүтээгдэхүүн үүсгэхдээ тухайн бүтээгдэхүүнд харгалзах категори, брэнд, нийлүүлэгчийг эхлээд үүсгэнэ үү</p>
                        </Form.Group>
                        : null}
                    <Form.Group>
                        {this.state.successMsg ? <div className="success-msg">{this.state.successMsg}</div> : null}
                        {this.state.errorMsg ? <div className="error-msg">
                            <Icon name="warning circle" />
                            {this.state.errorMsg}
                        </div> : null}
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Input required label='Бүтээгдэхүүний нэр' value={this.state.model.name}
                            onBlur={this.onNameBlur}
                            onChange={this.onNameChange} />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field label='Бүтээгдэхүүний товч нэр' control='input' value={this.state.model.shortName} onChange={this.onShortNameChange} />
                    </Form.Group>
                    <Form.Group>
                        <div className="required field">
                            <label className="required">URL нэр</label>
                            { this.state.editingSlug ?
                            <React.Fragment>
                            <div className="flex-start">
                                <Form.Input required value={this.state.model.slug || ""}
                                    onBlur={this.onSlugBlur}
                                    onChange={this.onSlugChange} />
                                { this.state.slugValid ? null :
                                    <React.Fragment>
                                        <Icon name="warning circle" color='red' size='large'/>
                                        <span className="error-msg">URL нэр ашиглагдсан байна</span>
                                    </React.Fragment>
                                }
                                &nbsp;&nbsp;
                                <Button className="cursor" color='orange'
                                    disabled={this.originalModel.slug === this.state.model.slug}
                                    onClick={this.postNewSlug}>Шалгах</Button>
                                <Button className="cursor" onClick={this.cancelSlugChange}>Болих</Button>
                            </div>
                            <div>
                                <br/>
                                <Icon name="info circle" color="blue" />
                                Зөвхөн англи үсэг болон - тэмдэг ашиглана
                            </div>
                            </React.Fragment>
                            :
                            <div>
                                <Label>{`${process.env.REACT_APP_DOMAIN}/product/${this.state.model.slug}`}</Label>
                                { this.state.slugValid ?
                                    (this.state.model.slug && this.state.model.slug.length > 0) ?
                                        <Icon name="check circle" color='green' size='large'/> : null :
                                    <Icon name="warning circle" color='red' size='large'/>
                                }
                                &nbsp;&nbsp;
                                <Icon className="cursor" name="edit outline" size='large' onClick={this.toggleSlug}/>
                            </div>
                        }
                        </div>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <div className="field">
                            <label>Категори</label>
                        </div>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Dropdown placeholder='Үндсэн категори' fluid labeled clearable selection options={this.state.parentCategories}
                            value={this.state.model.categoryId}
                            onChange={this.onParentCategoryChange} />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        {this.state.subCategories.length > 0 ?
                            <Form.Dropdown placeholder='Дэд категори' fluid labeled clearable selection options={this.state.subCategories}
                                value={this.state.model.subCategoryId}
                                onChange={this.onSubCategoryChange} /> : <p>Дэд категорийн сонголт хоосон байна</p>}
                    </Form.Group>
                    {this.state.editing && (this.originalModel.categoryId !== this.state.model.categoryId || this.originalModel.subCategoryId !== this.state.model.subCategoryId) ?
                        <Form.Group>
                            <p>Категори солигдоход өмнө нь байсан төрлүүд болон хэрэглээнүүд байхгүй болохыг анхаарна уу</p>
                        </Form.Group> : null}
                    <Form.Group widths='equal'>
                        <div className="field">
                            <label>Брэнд</label>
                        </div>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Dropdown placeholder='Брэнд' fluid labeled clearable selection search options={this.state.brands}
                            value={this.state.model.brandId}
                            onChange={this.onBrandChange} />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <div className="field">
                            <label>Нийлүүлэгч</label>
                        </div>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Dropdown required placeholder='Нийлүүлэгч' fluid labeled clearable selection options={this.state.providers}
                            value={this.state.model.providerId}
                            onChange={this.onProviderChange} />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <div className="field">
                            <label>Хэмжээ</label>
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Input value={this.state.model.size} onChange={this.onSizeChange} />
                        <Form.Dropdown placeholder='Хэмжээс' fluid labeled clearable selection options={this.state.units}
                            search allowAdditions
                            value={this.state.model.unit}
                            onAddItem={this.onUnitAdded}
                            onChange={this.onSizeUnitChange} />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Input type="number" required label='Бүтээгдэхүүний үнэ (₮)' value={this.state.model.price} onChange={this.onPriceChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Checkbox checked={this.state.model.saleActive} onChange={this.onSaleActiveChange} label='Хямдарсан' />
                    </Form.Group>
                    { this.state.model.saleActive ? <React.Fragment>
                        <hr/>
                        <Form.Group widths='equal'>
                            <Form.Input required label='Хямдарсан үнэ (₮)' value={this.state.model.salePrice} onChange={this.onSalePriceChange} />
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Input type='date' required label='Хямдралын сүүлийн өдөр' value={this.state.model.saleUntil} onChange={this.onSaleUntilChange} />
                        </Form.Group>
                        <hr/>
                    </React.Fragment> : null }

                    <Form.Group widths='equal'>
                        <Form.Input type="number" required label='Хүргэх өдрийн интервал (хэдэн өдрийн дараа хүргэх боломжтой)' min={0} max={50} value={this.state.model.deliveryDayMargin} onChange={this.onDeliveryDayChange} />
                    </Form.Group>
                    
                    <Form.Group>
                        <Form.TextArea label='Тайлбар' width="16" value={this.state.model.info} onChange={this.onInfoChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Checkbox checked={this.state.model.active} onChange={this.onActiveChange} label='Идэвхитэй' />
                    </Form.Group>
                    <hr />
                    <Form.Group>
                        <Label color="teal">Нүүр зураг</Label>
                    </Form.Group>
                    <Form.Group className="flex-space-between">
                        <div className="field">
                            <label>Том (800 x 800 pixel)(1:1)</label>
                        </div>
                        <div className={this.state.imageObjs.lg.uploaded && this.state.imageObjs.lg.showOriginal ? "flex-space-between" : "hidden-div"}>
                            <ImageOnS3 imgKey={this.state.model.imageLgUrl} width='50px' height='50px' />
                            <Icon className="cursor margin-10" name="trash" size='large' onClick={() => this.toggleImage('imageLgUrl')} />
                        </div>
                        <Icon className={this.state.imageObjs.lg.uploaded && !this.state.imageObjs.lg.showOriginal ? "" : "hidden-div"} name='redo' size='large' onClick={() => this.toggleImage('imageLgUrl')} />
                        <Form.Input control='input' type="file" accept="image/png, image/jpeg, image/webp" onChange={this.handleImageLgChange} />
                    </Form.Group>
                    <Form.Group className="flex-space-between">
                        <div className="field">
                            <label>Дунд (300 x 300 pixel)(1:1)</label>
                        </div>
                        <div className={this.state.imageObjs.sm.uploaded && this.state.imageObjs.sm.showOriginal ? "flex-space-between" : "hidden-div"}>
                            <ImageOnS3 imgKey={this.state.model.imageSmUrl} width='50px' height='50px' />
                            <Icon className="cursor margin-10" name="trash" size='large' onClick={() => this.toggleImage('imageSmUrl')} />
                        </div>
                        <Icon className={this.state.imageObjs.sm.uploaded && !this.state.imageObjs.sm.showOriginal ? "" : "hidden-div"} name='redo' size='large' onClick={() => this.toggleImage('imageSmUrl')} />
                        <Form.Field control='input' type="file" accept="image/png, image/jpeg, image/webp" onChange={this.handleImageSmChange} />
                    </Form.Group>
                    <Form.Group className="flex-space-between">
                        <div className="field">
                            <label>Жижиг (100 x 100 pixel)(1:1)</label>
                        </div>
                        <div className={this.state.imageObjs.xs.uploaded && this.state.imageObjs.xs.showOriginal ? "flex-space-between" : "hidden-div"}>
                            <ImageOnS3 imgKey={this.state.model.imageXsUrl} width="50px" height="50px" />
                            <Icon className="cursor margin-10" name="trash" size='large' onClick={() => this.toggleImage('imageXsUrl')} />
                        </div>
                        <Icon className={this.state.imageObjs.xs.uploaded && !this.state.imageObjs.xs.showOriginal ? "" : "hidden-div"} name='redo' size='large' onClick={() => this.toggleImage('imageXsUrl')} />
                        <Form.Field control='input' type="file" accept="image/png, image/jpeg, image/webp" onChange={this.handleImageXsChange} />
                    </Form.Group>
                    <hr />
                    <Form.Group>
                        <Label color="orange">Бүтээгдэхүүний зураг 1</Label>
                    </Form.Group>
                    <Form.Group className="flex-space-between">
                        <div className="field">
                            <label>Том (800 x 800 pixel)(1:1)</label>
                        </div>
                        {this.state.imageObjs.image1.uploaded ?
                            <div className={this.state.imageObjs.image1.showOriginal ? "flex-space-between" : "hidden-div"}>
                                <ImageOnS3 imgKey={this.state.model.image1} width="50px" height="50px" />
                                <Icon className="cursor margin-10" name="trash" size='large' onClick={() => this.toggleImage('image1')} />
                            </div> : null}
                        <Icon className={this.state.imageObjs.image1.uploaded && !this.state.imageObjs.image1.showOriginal ? "" : "hidden-div"} name='redo' size='large' onClick={() => this.toggleImage('image1')} />
                        <Form.Field control='input' type="file" accept="image/png, image/jpeg, image/webp" onChange={this.handleImage1Change} />
                    </Form.Group>
                    <Form.Group className="flex-space-between">
                        <div className="field">
                            <label>Жижиг (300 x 300 pixel)(1:1)</label>
                        </div>
                        {this.state.imageObjs.image4.uploaded ?
                            <div className={this.state.imageObjs.image4.showOriginal ? "flex-space-between" : "hidden-div"}>
                                <ImageOnS3 imgKey={this.state.model.image4} width="50px" height="50px" />
                                <Icon className="cursor margin-10" name="trash" size='large' onClick={() => this.toggleImage('image4')} />
                            </div> : null}
                        <Icon className={this.state.imageObjs.image4.uploaded && !this.state.imageObjs.image4.showOriginal ? "" : "hidden-div"} name='redo' size='large' onClick={() => this.toggleImage('image4')} />
                        <Form.Field control='input' type="file" accept="image/png, image/jpeg, image/webp" onChange={this.handleImage4Change} />
                    </Form.Group>
                    <hr />
                    <Form.Group>
                        <Label color="yellow">Бүтээгдэхүүний зураг 2</Label>
                    </Form.Group>
                    <Form.Group className="flex-space-between">
                        <div className="field">
                            <label>Том (800 x 800 pixel)(1:1)</label>
                        </div>
                        {this.state.imageObjs.image2.uploaded ?
                            <div className={this.state.imageObjs.image2.showOriginal ? "flex-space-between" : "hidden-div"}>
                                <ImageOnS3 imgKey={this.state.model.image2} width="50px" height="50px" />
                                <Icon className="cursor margin-10" name="trash" size='large' onClick={() => this.toggleImage('image2')} />
                            </div> : null}
                        <Icon className={this.state.imageObjs.image2.uploaded && !this.state.imageObjs.image2.showOriginal ? "" : "hidden-div"} name='redo' size='large' onClick={() => this.toggleImage('image2')} />
                        <Form.Field control='input' type="file" accept="image/png, image/jpeg, image/webp" onChange={this.handleImage2Change} />
                    </Form.Group>
                    <Form.Group className="flex-space-between">
                        <div className="field">
                            <label>Жижиг (300 x 300 pixel)(1:1)</label>
                        </div>
                        {this.state.imageObjs.image5.uploaded ?
                            <div className={this.state.imageObjs.image5.showOriginal ? "flex-space-between" : "hidden-div"}>
                                <ImageOnS3 imgKey={this.state.model.image5} width="50px" height="50px" />
                                <Icon className="cursor margin-10" name="trash" size='large' onClick={() => this.toggleImage('image5')} />
                            </div> : null}
                        <Icon className={this.state.imageObjs.image5.uploaded && !this.state.imageObjs.image5.showOriginal ? "" : "hidden-div"} name='redo' size='large' onClick={() => this.toggleImage('image5')} />
                        <Form.Field control='input' type="file" accept="image/png, image/jpeg, image/webp" onChange={this.handleImage5Change} />
                    </Form.Group>
                    <hr />
                    <Form.Group>
                        <Label color="olive">Бүтээгдэхүүний зураг 3</Label>
                    </Form.Group>
                    <Form.Group className="flex-space-between">
                        <div className="field">
                            <label>Том (800 x 800 pixel)(1:1)</label>
                        </div>
                        {this.state.imageObjs.image3.uploaded ?
                            <div className={this.state.imageObjs.image3.showOriginal ? "flex-space-between" : "hidden-div"}>
                                <ImageOnS3 imgKey={this.state.model.image3} width="50px" height="50px" />
                                <Icon className="cursor margin-10" name="trash" size='large' onClick={() => this.toggleImage('image3')} />
                            </div> : null}
                        <Icon className={this.state.imageObjs.image3.uploaded && !this.state.imageObjs.image3.showOriginal ? "" : "hidden-div"} name='redo' size='large' onClick={() => this.toggleImage('image3')} />
                        <Form.Field control='input' type="file" accept="image/png, image/jpeg, image/webp" onChange={this.handleImage3Change} />
                    </Form.Group>
                    <Form.Group className="flex-space-between">
                        <div className="field">
                            <label>Жижиг (300 x 300 pixel)(1:1)</label>
                        </div>
                        {this.state.imageObjs.image6.uploaded ?
                            <div className={this.state.imageObjs.image6.showOriginal ? "flex-space-between" : "hidden-div"}>
                                <ImageOnS3 imgKey={this.state.model.image6} width="50px" height="50px" />
                                <Icon className="cursor margin-10" name="trash" size='large' onClick={() => this.toggleImage('image6')} />
                            </div> : null}
                        <Icon className={this.state.imageObjs.image6.uploaded && !this.state.imageObjs.image6.showOriginal ? "" : "hidden-div"} name='redo' size='large' onClick={() => this.toggleImage('image6')} />
                        <Form.Field control='input' type="file" accept="image/png, image/jpeg, image/webp" onChange={this.handleImage6Change} />
                    </Form.Group>
                    <hr />
                    {this.state.model.types?.length > 0 || this.state.model.usages?.length > 0 ? <React.Fragment>
                        <Form.Group>
                            <div className="field">
                                <label>Бүтээгдэхүүний төрлүүд</label>
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <div className="category-types">
                                {this.state.model.types?.map((type: any, index: number) => {
                                    return <Label
                                        key={"category-type-" + index}
                                        className="cursor"
                                        color={type.active ? 'blue' : 'grey'}
                                        onClick={() => this.toggleUsageStatus(index, 'type')}>{type.name}&nbsp;&nbsp;</Label>
                                })}
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <div className="field">
                                <label>Бүтээгдэхүүний хэрэглээ</label>
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <div className="category-usages">
                                {this.state.model.usages?.map((usage: any, index: number) => {
                                    return <Label
                                        className="cursor"
                                        color={usage.active ? 'blue' : 'grey'}
                                        key={usage.name}
                                        onClick={() => this.toggleUsageStatus(index, 'usage')}
                                    >{usage.name}&nbsp;&nbsp;</Label>
                                })}
                            </div>
                        </Form.Group>
                        <hr />
                    </React.Fragment> : null}
                    <br />
                    <Form.Button disabled={this.state.loading} onClick={() => this.save()}>Хадгалах</Form.Button>
                </Form>
            </Container>
        );
    }
}

export default ProductForm;