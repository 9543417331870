import React, { Component } from 'react';
import './home.scss';
import Banner from '../banner/banner';
import Categories from '../categories/categories';
import TopProducts from '../top-products/top-products';
import Featured from '../featured-list/featured';
import { Container, Row } from 'react-grid-system';
import { connect } from 'react-redux';
import TopProductItem from '../top-product-item/top-product';

const mapStateToProps = (state: any) => {
  return {
    categories: state.categories.filter((c: any) => c.active),
    products: state?.products?.filter((p: any) => p.active && p.brandActive && p.categoryActive && p.warehouseCount > 0).slice(0, 12).map((i: any) => <TopProductItem product={i}/>),
  }
}

interface HomeProps {
  categories: any[],
  products: []
}

class Home extends Component<HomeProps> {

  componentDidMount() {
    window.scroll(0,0);
  }

  render() {
    return (
      <React.Fragment>
        <Banner></Banner>
        <div className="home-page page">
          <Container fluid={true}>
            <Row>
              <Categories categories={this.props.categories}/>
            </Row>
            <Row>
              <Featured/>
            </Row>
            <Row>
              <TopProducts title="Санал болгох бүтээгдэхүүн" products={this.props.products}/>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(Home)