import React from 'react';
import './product-item.scss';
import { Icon } from 'semantic-ui-react';
import store from '../../store';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';

interface ProductProps {
    product: any,
    basket: any
}

interface ProductState {
    showBasketLine: boolean,
    basketCount: number,
    basketMsg: string
}

const mapStateToProps = (state: any, props: any) => {
    let basketCount = 0;
    if (state.basket && state.basket.quantityById && props && props.product) {
        if (state.basket.quantityById[props.product.id]) {
            basketCount = state.basket.quantityById[props.product.id];
        }
    }
    return {
        basket: state.basket,
        basketCount: basketCount
    }
}

class ProductItem extends React.Component<ProductProps, ProductState> {

    constructor(props: any) {
        super(props);
        let basketCount = 0;

        if (this.props.basket && this.props.basket.quantityById) {
            if (this.props.basket.quantityById[this.props.product.id]) {
                basketCount = this.props.basket.quantityById[this.props.product.id];
            }
        }
        this.state = {
            showBasketLine: false,
            basketCount: basketCount,
            basketMsg: ""
        };
    }

    componentWillReceiveProps(nextProps: any) {
        if (nextProps.basketCount != null) {
            let state = { basketCount: nextProps.basketCount, showBasketLine: this.state.showBasketLine};
            if (nextProps.basketCount === 0) {
                state.showBasketLine = false;
            }
            this.setState(state);
        }
    }

    toggleTopLine() {
        if (!this.state.showBasketLine && this.state.basketCount === 0) {
            this.increaseBasketCount();
        }
        this.setState({ showBasketLine: !this.state.showBasketLine });
    }

    increaseBasketCount() {
        if (this.state.basketCount + 1 > this.props.product.warehouseCount) {
            this.setState({ basketMsg: "Боломжит үлдэгдэл: " + this.props.product.warehouseCount })
            return;
        }
        this.setState({ basketCount: this.state.basketCount + 1 });
        store.dispatch({
            type: 'addToBasket',
            product: this.props.product,
            count: 1
        });
    }

    decreaseBasketCount() {
        this.setState({ basketMsg: ""})
        if (this.state.basketCount === 0) {
            return;
        }
        if (this.state.basketCount === 1) {
            this.toggleTopLine();
        }
        this.setState({ basketCount: this.state.basketCount - 1 });
        store.dispatch({
            type: 'removeFromBasket',
            product: this.props.product
        });
    }

    render() {
        return (
            <div className={`product-detail-item ${this.state.basketCount > 0 ? "selected" : ""}`}>
                { this.props.product.warehouseCount === 0 ? null :
                    this.state.showBasketLine ?
                        <div className="basket-line">
                            <div className="background"></div>
                            <div className="flex-line">
                                <Icon className="flex-child" name="minus" onClick={() => this.decreaseBasketCount()}/>
                                <span className="flex-child count">{ this.state.basketCount }</span>
                                <Icon className="flex-child" name="plus" onClick={() => this.increaseBasketCount()}/>
                            </div>
                            <div className="close-line" onClick={() => this.toggleTopLine()}>
                                <div className="basket-msg">{ this.state.basketMsg }</div>
                                <span>Хаах</span>
                            </div>
                        </div> :
                        <div className="top-line" onClick={() => this.toggleTopLine()}>
                            {
                                (this.state.basketCount > 0) ? 
                                <span className="count-in-basket">{ this.state.basketCount }</span> : 
                                <Icon name="plus" size="small" className="plus-icon"/>
                            }
                        </div>
                }
                <Link to={"/product/" + this.props.product.slug} className="product-item">
                    <img className="product-image" src={this.props.product.imageSmUrl}/>
                </Link>
                <Link to={"/product/" + this.props.product.slug} className="text-center">
                    <div className="product-title">{this.props.product.name}</div>
                </Link>
                { this.props.product.warehouseCount === 0 ? <div className="warehouse-line">Үлдэгдэл байхгүй</div> : null }
                { this.props.product.saleActive ? <div className="sale-line">
                    <span className="sale">Sale</span>&nbsp;
                    <NumberFormat className="striked" displayType={'text'} value={ this.props.product.price } suffix="₮" thousandSeparator={true}/></div> : null }
                <div className="price-line">
                    <span className="price">
                        <NumberFormat displayType={'text'} value={ this.props.product.saleActive ? this.props.product.salePrice : this.props.product.price } suffix="₮" thousandSeparator={true}/>
                    </span>
                    <span className="price-per">{ (this.props.product.size > 0 && this.props.product.unit) ? `/ ${this.props.product.size} ${this.props.product.unit}` : "" }</span>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(ProductItem);