import React from 'react';
import { Button, Table, Icon } from 'semantic-ui-react';
import 'semantic-ui-css/components/table.min.css';
import { Link, Redirect } from 'react-router-dom';
import MyList from './list';

class BannerList extends MyList {

    entity = "content";

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        var token = localStorage.getItem('adminToken')
        if (token && token !== 'undefined') {
            fetch(process.env.REACT_APP_CHEF_API + "list/" + this.entity, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': token,
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                if (res.status === 401) {
                    this.setState({ redirect: this.loginUrl})
                } else {
                    res.json().then(res => {
                        console.log(res);
                        if (!res || !res.data) {
                            return;
                        }
                        let data: any[] = res.data;
                        data = data.filter(r => r.type == 1)
                        data.forEach(b => b.confirmingDelete = false);
                        this.setState({ data: data });
                    })
                }
            })
            .catch(err => console.error(err));
        }
        else {
            this.setState({ redirect: this.loginUrl });
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }
        return (
            <div className="full-width">
                <h2>Баннерийн жагсаалт</h2>
                <Link to="/mongol/banners/new">
                    <Button basic color='orange'>Шинээр үүсгэх</Button>
                </Link>

                {this.state.errorMsg ? <div className="error-msg">
                    <Icon name="warning circle"/>&nbsp;
                    {this.state.errorMsg}
                </div> : null}

                <Table striped width={16}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Баннерийн нэр</Table.HeaderCell>
                            <Table.HeaderCell>Төрөл</Table.HeaderCell>
                            <Table.HeaderCell>Идэвхитэй</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        { this.state?.data?.map((row: any, index: number) => {
                                return <Table.Row key={row.id}>
                                    <Table.Cell>
                                        <strong>
                                            <Link className="underline hover-blue" to={"/mongol/banner/" + row.id}>{ row.name }</Link>
                                        </strong>
                                    </Table.Cell>
                                    <Table.Cell> { row.type === 1 ? 'Баннер' : 'Онцлох' } </Table.Cell>
                                    <Table.Cell> { row.active ? <Icon name="checkmark"/> : null } </Table.Cell>
                                    <Table.Cell>
                                        { row.confirmingDelete ? 
                                            <React.Fragment>
                                                <Button primary size='mini' onClick={() => this.delete(index)}>Устга</Button>
                                                <Button size='mini' onClick={() => this.toggleDelete(index)}>Болих</Button>
                                            </React.Fragment> :
                                            <Icon name="trash" className="cursor chef-icon-hover padding-5" onClick={() => this.toggleDelete(index)}/>
                                        }
                                    </Table.Cell>
                                </Table.Row>
                            })
                        }
                    </Table.Body>
                </Table>
            </div>
        );
    }
}
            
export default BannerList;